import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { actionMonitorMiddleware } from "@topaasia/error-boundary";

import { configureReducers } from "./reducers";
import { sagas } from "./sagas";

export const history = createBrowserHistory();

export function configureStore() {
  const initialState = {};
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    routerMiddleware(history),
    actionMonitorMiddleware,
    sagaMiddleware,
  ];

  const composeEnhancers = (
    typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) || compose;

  const enhancers = composeEnhancers(applyMiddleware(...middlewares));
  const reducers = configureReducers(history);
  const store = createStore(reducers, initialState, enhancers);

  sagaMiddleware.run(sagas);

  return store;
}
