import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { actions } from ".";

const initialState = {
  status: {
    isVoting: false,
    voteError: false,
  },
};

const status = handleActions({
  [actions.vote.request]: () => ({ isVoting: true, voteError: false }),
  [actions.vote.success]: () => ({ isVoting: false, voteError: false }),
  [actions.vote.error]: () => ({ isVoting: false, voteError: true }),
}, initialState.status);

export const reducer = combineReducers({
  status,
});
