import React from "react";
import PropTypes from "prop-types";

import CardTitle from "./CardTitle";

import backFace from "../../assets/images/back.png";
import diamonds from "../../assets/images/diamonds.png";
import hearts from "../../assets/images/hearts.png";
import spades from "../../assets/images/spades.png";
import clubs from "../../assets/images/clubs.png";

import "./card.scss";

const w = () => (window.width <= 1920 ? 220 : 300);
const cardLayoutClass = () => (w() > 220 ? "big-cards" : "");

const suitConfig = {
  diamonds: {
    icon: diamonds,
    color: "#59aa3b",
  },
  hearts: {
    icon: hearts,
    color: "#e14970",
  },
  spades: {
    icon: spades,
    color: "#00587d",
  },
  clubs: {
    icon: clubs,
    color: "#fbba00",
  },
};

const Card = ({
  cardId,
  cardKey,
  title,
  forwardRef,
  description,
  isDiscarded = false,
  suit,
  style = {},
  onClick = () => {},
}) => {
  const { icon, color } = suitConfig[suit];

  const hasOverlyLongWords = description.split(" ").some((word) => word.length > 25);
  const descriptionStyle = hasOverlyLongWords ? { overflowWrap: "break-word" } : {};

  const suitIconStyle = {
    backgroundImage: `url(${icon})`,
  };

  return (
    <div
      ref={forwardRef}
      data-cardid={cardId || cardKey}
      className={`double-sided-card card-faces-container ${cardLayoutClass()} ${
        isDiscarded ? "discarded" : ""
      }`}
      onClick={() => {
        onClick(cardId, cardKey);
      }}
      style={style}>
      <div
        className="card"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}>
        <div className="card-content">
          <CardTitle title={title} color={color} />
          <div className="card-separator" style={{ backgroundColor: color }} />
          <p className="card-description" style={descriptionStyle}>
            {description}
          </p>
        </div>
        <div className="card-suit-img card-suit-img-1" style={suitIconStyle}></div>
        <div className="card-suit-img card-suit-img-2" style={suitIconStyle}></div>
      </div>
      <div className="card-back" style={{ background: `url(${backFace}) #555` }}></div>
    </div>
  );
};

Card.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardKey: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isDiscarded: PropTypes.bool,
  suit: PropTypes.string.isRequired,
  style: PropTypes.object,
  forwardRef: PropTypes.any,
  onClick: PropTypes.func,
};

export default Card;
