import { createAction } from "redux-actions";

const namespaces = {
  rounds: "ROUNDS",
};

export const rounds = {
  topaasiaRound: {
    get: {
      request: createAction(`${namespaces.rounds}/TOPAASIA-ROUND/GET/REQUEST`),
      success: createAction(`${namespaces.rounds}/TOPAASIA-ROUND/GET/SUCCESS`),
      error: createAction(`${namespaces.rounds}/TOPAASIA-ROUND/GET/ERROR`),
    },
  },
};
