import PropTypes from "prop-types";
import React from "react";

export const Button = ({
  children,
  className = "button",
  type = "button",
  style = "default",
  content,
  disabled = false,
  extendClassName,
  onClick,
}) => {
  const composeClassName = (baseClassName) => {
    const classNames = [baseClassName, style];

    if (disabled) {
      classNames.push(`${baseClassName}--disabled`);
    }

    if (extendClassName) {
      classNames.push(extendClassName);
    }

    return classNames.join(" ");
  };

  return (
    <button
      className={composeClassName(className)}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
      type={type}
    >
      {children || content}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.oneOf(["default", "subtle", "small"]),
  content: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  extendClassName: PropTypes.string,
  onClick: PropTypes.func,
};
