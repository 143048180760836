import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { debounce, uniqueId } from "lodash";
import TextareaAutosize from "react-autosize-textarea/lib";
import { useCancelled, useSize, usePrevious } from "../../modules/shared/utils";

const TextAreaInput = ({
  className = "",
  placeholder = "",
  type = "text",
  inputProps = {},
  theme = "light",
  resizeKey,
  minRows,
  maxRows,
  maxLength,
  value,
  inputRef,
  onChange,
}) => {
  const containerRef = useRef();
  const isCancelled = useCancelled();
  const size = useSize(containerRef);
  const previousSize = usePrevious(size);
  const [resizeCount, setResizeCount] = useState(0);
  const memoizedKey = useMemo(() => resizeKey || uniqueId(), [resizeKey]);
  const key = `${memoizedKey}-${resizeCount}`;

  const debouncedResize = useRef(debounce(() => {
    if (isCancelled.current) {
      return;
    }

    setResizeCount((count) => count + 1);
  }, 200));

  useLayoutEffect(() => {
    if (size && previousSize && (size.width !== previousSize.width)) {
      debouncedResize.current();
    }
  }, [size]);

  return (
    <div ref={containerRef} className={`text-area-input-wrapper ${className || "no-class"}-wrapper`}>
      <TextareaAutosize
        key={key}
        type={type}
        ref={inputRef}
        rows={minRows}
        maxRows={maxRows}
        maxLength={maxLength}
        className={`text-input ${className} ${theme}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        { ...inputProps }
      />
    </div>
  );
};

TextAreaInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  inputRef: PropTypes.any,
  maxLength: PropTypes.number,
  resizeKey: PropTypes.string,
  theme: PropTypes.oneOf(["light", "underlined"]),
  inputProps: PropTypes.object,
};

export default TextAreaInput;
