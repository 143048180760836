import React, { useEffect, useState } from "react";
import ThumbUp from "@mui/icons-material/ThumbUp";
import i18next from "i18next";
import { useViewportSize } from "../../../modules/shared/utils";

export const useCardCollectionTopMargin = (cardCollectionRef, boardInfoRef, actionBarRef) => {
  const [vw, vh] = useViewportSize();
  const [topMargin, setTopMargin] = useState(0);

  useEffect(() => {
    let top = 0;
    let availableTop = 0;
    let availableBottom = vh;

    if (boardInfoRef?.current) {
      availableTop = boardInfoRef.current.getBoundingClientRect().bottom;
    }
    if (actionBarRef?.current) {
      availableBottom = actionBarRef.current.getBoundingClientRect().top;
    }

    const availableHeight = availableBottom - availableTop;

    if (cardCollectionRef?.current) {
      top = Math.max(availableHeight / 2 - cardCollectionRef.current.clientHeight / 2, 0);
    }

    setTopMargin(top);
  }, [vw, vh, cardCollectionRef]);

  return topMargin;
};

export const useCardCollectionTopPadding = ({ additionalPadding = 20 } = {}) => {
  const [topPadding] = useState(0);

  return topPadding + additionalPadding;
};

export const useCardCollectionVoteIndicators = ({ votedCard = null }) => {
  const t = i18next.getFixedT(null, "components");

  return (card) => {
    if (votedCard?.id && card.id === votedCard.id) {
      return [
        {
          action: "change-card",
          icon: <ThumbUp fontSize="large" color="orange" />,
          label: t("components:card-actions.change-card"),
          onClick: () => {},
        },
      ];
    }
    return [];
  };
}