import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { sortBy, compact, isEqual } from "lodash";
import { diff } from "deep-diff";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SortIcon from "@mui/icons-material/Sort";
import { Translation } from "react-i18next";

import { getCurrentLanguage } from "../../../../modules/shared/utils";
import PlayerIdeaList from "./player-idea-list/PlayerIdeaList";
import { Button } from "../../../../components/button/Button";

import "./topic-player-ideas-view.scss";

const canUpvotePlayerIdea = (playerIdea, playerId, isUpvotingPlayerIdeas) => {
  const hasVoted = playerIdea.playerAttributes.some((attrs) => attrs.playerId === playerId);
  return !hasVoted && !isUpvotingPlayerIdeas.includes(playerIdea.id);
};

const sortedPlayerIdeas = (playerIdeas) => sortBy(playerIdeas, ["upvotes", "createdAt"]).reverse();
const playerIdeaMap = (playerIdeas) =>
  playerIdeas.reduce((memo, playerIdea) => ({ ...memo, [playerIdea.id]: playerIdea }), {});

const isAlreadySortedByUpvotes = (playerIdeas) => {
  const playerIdeaIds = playerIdeas.map((p) => p.id);
  const sortedIdeaIds = sortedPlayerIdeas(playerIdeas).map((p) => p.id);
  return isEqual(playerIdeaIds, sortedIdeaIds);
};

const composePlayerIdeaActions = ({
  playerIdea,
  playerId,
  isUpvotingPlayerIdeas,
  t,
  onUpvotePlayerIdea,
}) => {
  const isUpvoted = !canUpvotePlayerIdea(playerIdea, playerId, isUpvotingPlayerIdeas);

  return [
    {
      action: "upvote",
      disabled: isUpvoted,
      ariaLabel: t("crystallize-view.topic-player-ideas-view.aria.upvote-button", {
        text: playerIdea.text,
      }),
      icon: (
        <div
          id={`${playerIdea.id}-upvote-button`}
          className={`upvote-button ${isUpvoted ? "is-upvoted" : "not-upvoted"}`}
          onClick={(e) => {
            if (!canUpvotePlayerIdea(playerIdea, playerId, isUpvotingPlayerIdeas)) {
              return;
            }

            // Quite disgusting
            e.preventDefault();
            const target = document.getElementById(`${playerIdea.id}-upvote-button`);

            target.style.transform = "scale(1.3) rotateZ(-2deg)";
            setTimeout(() => {
              target.style.transform = "scale(1.0) rotateZ(0deg)";
            }, 200);
          }}
          style={{
            transition: "transform 100ms",
            overflow: "visible",
            transformOrigin: "50% 50%",
          }}>
          <div className="upvote-icon-content">
            <ThumbUpIcon />
          </div>
          <div className="upvote-count-label">+{playerIdea.upvotes}</div>
        </div>
      ),
      callback: () => {
        onUpvotePlayerIdea(playerIdea);
      },
    },
  ];
};

const TopicPlayerIdeasView = ({
  topic,
  playerIdeas,
  onClose,
  playerId,
  isUpvotingPlayerIdeas = [],
  onUpvotePlayerIdea = () => {},
}) => {
  const [sortedIdeas, setSortedIdeas] = useState(sortedPlayerIdeas(playerIdeas));
  const isCancelled = useRef(false);
  const contentRef = useRef();

  useEffect(() => {
    if (!isCancelled.current) {
      const oldIdeas = playerIdeaMap(sortedIdeas);
      const newIdeas = playerIdeaMap(playerIdeas);

      const ideaDiff = diff(oldIdeas, newIdeas) || [];

      if (ideaDiff.length > 0) {
        // Replace without sorting again.
        setSortedIdeas((ideas) => compact(ideas.map((playerIdea) => newIdeas[playerIdea.id])));
      }
    }
  }, [playerIdeas]);

  useEffect(() => {
    isCancelled.current = false;
    contentRef.current.focus();
    return () => {
      isCancelled.current = true;
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#222222" />
      </Helmet>
      <Translation ns="views">
        {(t) => (
          <div
            role="dialog"
            ref={contentRef}
            tabIndex={0}
            className="topic-player-ideas-view"
            onClick={(e) => e.stopPropagation()}>
            <button
              aria-label={t(`crystallize-view.topic-player-ideas-view.aria.close-dialog-button`)}
              className="close-button"
              onClick={onClose}>
              <CloseIcon />
            </button>
            <h1 className="topic-title">{topic.title[getCurrentLanguage()]}</h1>
            <div className="player-idea-list-actions">
              <Button
                extendClassName="sort-player-ideas-button"
                style="small"
                disabled={isAlreadySortedByUpvotes(sortedIdeas)}
                onClick={(e) => {
                  e.preventDefault();
                  setSortedIdeas((ideas) => sortedPlayerIdeas(ideas));
                }}>
                <SortIcon className="button-icon" />
                {t(`crystallize-view.topic-player-ideas-view.action.sort-by-upvotes`)}
              </Button>
            </div>
            <div className="player-ideas-container">
              <PlayerIdeaList
                playerIdeas={sortedIdeas}
                getPlayerIdeaActions={(playerIdea) =>
                  composePlayerIdeaActions({
                    playerIdea,
                    playerId,
                    isUpvotingPlayerIdeas,
                    t,
                    onUpvotePlayerIdea,
                  })
                }
              />
            </div>
          </div>
        )}
      </Translation>
    </>
  );
};

TopicPlayerIdeasView.propTypes = {
  topic: PropTypes.object.isRequired,
  playerId: PropTypes.string.isRequired,
  playerIdeas: PropTypes.array.isRequired,
  onUpvotePlayerIdea: PropTypes.func,
  isUpvotingPlayerIdeas: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

export default TopicPlayerIdeasView;
