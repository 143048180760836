import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import PlayerAvatar from "@topaasia/player-avatar";

import "./self-info-container.scss";

const SelfInfoContainer = ({ selfPlayer, onChangePlayerName }) => {
  const { t } = useTranslation("views");
  const nameInputRef = useRef();
  const latestValidName = useRef(selfPlayer.name);
  const [playerName, setPlayerName] = useState(selfPlayer.name);
  const isDirty = () => playerName.trim() !== selfPlayer.name.trim() && playerName.length > 0;

  const debouncedOnChangeName = useRef(
    debounce((newValue) => {
      onChangePlayerName(newValue);
    }, 500),
  );

  useEffect(() => {
    if (isDirty()) {
      latestValidName.current =
        playerName.trim().length > 0 ? playerName.trim() : latestValidName.current;
      debouncedOnChangeName.current(latestValidName.current);
    }
  }, [playerName]);

  return (
    <div className="self-info-container">
      <h2 className="you-are-header">{t("waitplayers-view.self-info.title")}</h2>
      <PlayerAvatar
        size={140}
        type={selfPlayer.avatar}
        playerColor={selfPlayer.color}
      />
      <div className="name-input">
        <input
          type={"text"}
          value={playerName}
          maxLength={40}
          ref={nameInputRef}
          onBlur={() => {
            const trimmedName = playerName.trim();
            setPlayerName(playerName.trim());

            if (trimmedName.length === 0) {
              setPlayerName(latestValidName.current);
            }
          }}
          onChange={(e) => setPlayerName(e.target.value)}
        />

        {isDirty() ? (
          <PulseLoader size={5} color="white" />
        ) : (
          <button
            className="edit-name-indicator"
            onClick={(e) => {
              e.preventDefault();
              nameInputRef.current.focus();
              nameInputRef.current.select();
            }}>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
      </div>
    </div>
  );
};

SelfInfoContainer.propTypes = {
  selfPlayer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  onChangePlayerName: PropTypes.func.isRequired,
};

export default SelfInfoContainer;
