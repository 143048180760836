import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as actions from "./actions";

const initialState = {
  status: {
    isPostingIdea: false,
    isDeletingIdeas: [],
    isUpvotingIdeas: [],
    ideaPostError: false,
    ideaDeleteError: false,
  },
};

const status = handleActions({
  [actions.idea.post.request]: (state) => ({ ...state, isPostingIdea: true, ideaPostError: false }),
  [actions.idea.post.success]: (state) => ({ ...state, isPostingIdea: false, ideaPostError: false }),
  [actions.idea.post.error]: (state) => ({ ...state, isPostingIdea: false, ideaPostError: true }),

  [actions.idea.delete.request]: (state, action) => ({
    ...state,
    isDeletingIdeas: [...state.isDeletingIdeas, action.payload.playerIdeaId],
    ideaDeleteError: false,
  }),
  [actions.idea.delete.success]: (state, action) => ({
    ...state,
    isDeletingIdeas: state.isDeletingIdeas.filter((i) => i.id !== action.payload.id),
    ideaDeleteError: false,
  }),
  [actions.idea.delete.error]: (state, action) => ({
    ...state,
    isDeletingIdeas: state.isDeletingIdeas.filter((i) => i.id !== action.payload.id),
    ideaDeleteError: true,
  }),

  [actions.idea.upvote.request]: (state, action) => ({
    ...state,
    isUpvotingIdeas: [...state.isUpvotingIdeas, action.payload.playerIdeaId],
    ideaUpvoteError: false,
  }),
  [actions.idea.upvote.success]: (state, action) => ({
    ...state,
    isUpvotingIdeas: state.isUpvotingIdeas.filter((i) => i.id !== action.payload.id),
    ideaUpvoteError: false,
  }),
  [actions.idea.upvote.error]: (state, action) => ({
    ...state,
    isUpvotingIdeas: state.isUpvotingIdeas.filter((i) => i.id !== action.payload.id),
    ideaUpvoteError: true,
  }),
}, initialState.status);

export const reducer = combineReducers({
  status,
});
