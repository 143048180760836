import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import PlayerAvatar from "@topaasia/player-avatar";

import "./player-idea.scss";

const PlayerIdeaAction = ({
  action,
  icon,
  ariaLabel,
  disabled = false,
  requireDoubleTap = false,
  callback,
}) => {
  const [buttonState, setButtonState] = useState("default");
  const cancelTimeout = useRef();

  const onButtonClick = () => {
    if (disabled) { return; }
    if (!requireDoubleTap) {
      callback();
      return;
    }

    if (buttonState === "default") {
      setButtonState("active");

      cancelTimeout.current = setTimeout(() => setButtonState("default"), 3000);
    } else if (buttonState === "active") {
      setButtonState("default");
      clearTimeout(cancelTimeout.current);
      callback();
    }
  };

  return (
    <div
      key={action}
      tabIndex={0}
      role="button"
      aria-label={ariaLabel}
      className={`idea-action idea-action-${buttonState}`}
      onClick={onButtonClick}
      onKeyPress={onButtonClick}>
      {icon}
    </div>
  );
};

PlayerIdeaAction.propTypes = {
  action: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  requireDoubleTap: PropTypes.bool,
  color: PropTypes.string,
};

const PlayerIdea = ({
  playerIdea,
  actions = [],
  showAvatar = true,
  showPlayer = true,
}) => {
  const { player } = playerIdea;

  const withAvatarClass = showAvatar ? "with-avatar" : "";
  const withPlayerClass = showPlayer ? "with-player" : "";
  const withActions = actions.length > 0 ? "with-actions" : "";
  const classNames = `player-idea ${withAvatarClass} ${withPlayerClass} ${withActions}`;

  return (
    <div
      className={classNames}
      style={{
        backgroundColor: player.color,
      }}>
      {showAvatar && (
        <div className="avatar-container">
          <PlayerAvatar size={50} playerColor={player.color} type={player.avatar} />
        </div>
      )}
      <div className="idea-body">
        <div className="idea-text">
          {playerIdea.text}
        </div>
        <div className="player-idea-player">
          {player.name}
        </div>
      </div>
      {actions.map((action) => (
        <PlayerIdeaAction
          key={action.action}
          playerIdea={playerIdea}
          {...action}
          color={player.color} />
      ))}
    </div>
  );
};

PlayerIdea.propTypes = {
  playerIdea: PropTypes.object.isRequired,
  showAvatar: PropTypes.bool,
  showPlayer: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    callback: PropTypes.func.isRequired,
  })),
};

export default PlayerIdea;
