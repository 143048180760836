import React from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";

const BoardErrorToast = ({
  errorLabel,
  isVisible = false,
}) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        className="board-error-toast"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.5 }}
        role="alert">
        {errorLabel}
      </motion.div>
    )}
  </AnimatePresence>
);

BoardErrorToast.propTypes = {
  errorLabel: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
};

export default BoardErrorToast;
