import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import CardGameNoteListItem from "./list-item/CardGameNoteListItem";

import "./card-game-note-list.scss";
import { usePrevious } from "../../../../../modules/shared/utils";

const CardGameNoteList = ({ gameNotes = [], autoScroll = true }) => {
  const previousGameNotes = usePrevious(gameNotes);
  const listRef = useRef();
  const [autoScrollBreak, setAutoscrollBreak] = useState(false);
  const canAutoScroll = autoScroll && !autoScrollBreak;

  useEffect(() => {
    const gameNotesChanged = !previousGameNotes || previousGameNotes.length !== gameNotes.length;

    if (gameNotesChanged && canAutoScroll) {
      listRef.current.scrollTo(0, listRef.current.scrollHeight);
    }
  }, [gameNotes]);

  return (
    <ul
      className="card-game-note-list"
      ref={listRef}
      onTouchMove={() => {
        setAutoscrollBreak(true);
      }}
      onWheel={() => {
        setAutoscrollBreak(true);
      }}
      onScroll={() => {
        const h = listRef.current.scrollHeight;
        const t = listRef.current.scrollTop + listRef.current.clientHeight;

        if (Math.abs(t - h) < 10) {
          setAutoscrollBreak(false);
        }
      }}>
      {gameNotes.map((gameNote) => (
        <li key={gameNote.id} className="game-note-item-wrapper">
          <CardGameNoteListItem gameNote={gameNote} />
        </li>
      ))}
    </ul>
  );
};

CardGameNoteList.propTypes = {
  gameNotes: PropTypes.array.isRequired,
  autoScroll: PropTypes.func,
};

export default CardGameNoteList;
