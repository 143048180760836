import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import Board from "../../../components/board/Board";

import PickTopaasiaTopaasiaModeContent from "./topaasia/PickTopaasiaTopaasiaModeContent";
import PickTopaasiaOneOnOneModeContent from "./one-on-one/PickTopaasiaOneOnOneModeContent";

class PickTopaasiaView extends Component {
  constructor() {
    super();

    this.state = {
      selectedCandidateId: null,
    };
  }

  static getDerivedStateFromProps(props) {
    const { activePoll } = props;

    if (activePoll && activePoll.poll && activePoll.poll.state === "results") {
      return { selectedCandidateId: null };
    }

    return {};
  }

  render() {
    const { gameSubject, game } = this.props;

    const { gameMode } = game;

    return (
      <motion.main
        className="board-transition-canvas"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75 }}>
        <Helmet>
          <meta name="theme-color" content="#222222" />
          <html className="card-background"></html>
        </Helmet>
        <Board className="padded pick-topaasia-view-board" isDisabled={true}>
          {gameMode === "topaasia" && <PickTopaasiaTopaasiaModeContent gameSubject={gameSubject} />}
          {gameMode === "one_on_one" && (
            <PickTopaasiaOneOnOneModeContent gameSubject={gameSubject} />
          )}
        </Board>
      </motion.main>
    );
  }
}

PickTopaasiaView.propTypes = {
  activePoll: PropTypes.object,
  votes: PropTypes.array,
  ownVote: PropTypes.object,
  cards: PropTypes.array,
  candidates: PropTypes.array,
  selection: PropTypes.array,
  game: PropTypes.object,
  gameState: PropTypes.object,
  perspectives: PropTypes.array,
  rounds: PropTypes.array,
  gameSubject: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  player: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isVoting: PropTypes.bool,
  voteError: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { activePoll } = state.game.gameState.stateData;
  const votes = (activePoll && activePoll.votes) || [];
  const { player } = state.player;
  const ownVote = votes.find((v) => v.playerId === player.id);
  const { topaasiaCandidates } = state.game.gameState.stateData;

  return {
    activePoll,
    votes,
    ownVote,
    cards: state.deck.deck.cards,
    candidates: topaasiaCandidates.candidates,
    perspectives: topaasiaCandidates.perspectives,
    rounds: topaasiaCandidates.rounds,
    game: state.game.game,
    gameState: state.game.gameState,
    player,
    isVoting: state.playerVote.status.isVoting,
    voteError: state.playerVote.status.voteError,
  };
};

export default compose(withTranslation("views"), connect(mapStateToProps))(PickTopaasiaView);
