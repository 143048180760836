import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class PollingErrorIndicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ticker: 0,
    };
  }

  componentDidUpdate() {
    if (this.props.stateFetchError) {
      clearInterval(this.threeDotsInterval);
      this.threeDotsInterval = setInterval(() => {
        this.setState({ ticker: this.state.ticker + 1 });
      }, 300);
    } else {
      clearInterval(this.threeDotsInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.threeDotsInterval);
  }

  render() {
    const { t, stateFetchError } = this.props;

    const dots = Array(this.state.ticker % 10).fill(".").join("");

    return (
      <div className="polling-error-indicator" aria-hidden={!stateFetchError} style={{
        display: stateFetchError ? "block" : "none",
      }}>
        { t("polling-error-indicator.message") }{ dots }
      </div>
    );
  }
}

PollingErrorIndicator.propTypes = {
  t: PropTypes.func.isRequired,
  stateFetchError: PropTypes.any,
};

const mapStateToProps = (state) => ({
  stateFetchError: state.game.error.stateFetchError,
});

export default compose(
  withTranslation("components"),
  connect(mapStateToProps),
)(PollingErrorIndicator);
