import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { Helmet } from "react-helmet";
import Board from "../../../../../components/board/Board";
import CardCollectionView from "../../../../../components/card/card-collection-view/CardCollectionView";
import { getCurrentLanguage, useViewportOrientation } from "../../../../../modules/shared/utils";
import BoardInfo from "../../../common/board-info/BoardInfo";
import {
  useCardCollectionTopMargin,
  useCardCollectionTopPadding,
} from "../../../common/card-collection-utils";

const PickChoiceOneOnOneModeContent = ({ gameSubject, isDisabled = false }) => {
  const cards = useSelector((state) => state.deck.deck.cards);
  const candidates = useSelector((state) => state.game.gameState.stateData.candidates);
  const gameState = useSelector((state) => state.game.gameState);
  const candidateCards = candidates.map((c) => cards.find((card) => card.id === c.cardId));
  const cardCollectionRef = useRef();
  const boardInfoRef = useRef();

  const orientation = useViewportOrientation();

  const cardCollectionTop = useCardCollectionTopMargin(cardCollectionRef, boardInfoRef, null);
  const cardCollectionTopPadding = useCardCollectionTopPadding();

  const perspective = gameState.currentRoundPerspective;

  return (
    <div className="pick-choice-topaasia-one-on-one-content">
      <Helmet>
        <meta name="theme-color" content="#222222" />
        <html className="card-background"></html>
      </Helmet>
      <Board className="padded pick-choice-view-board" isDisabled={isDisabled}>
        <BoardInfo
          gameSubject={gameSubject}
          containerRef={boardInfoRef}
          boardInfoTitle={""}
          boardInfoMessage={
            <Trans
              ns="views"
              i18nKey="pick-choice-view.pick-choice-info.message.one_on_one"
              values={{
                perspective: perspective.title[getCurrentLanguage()],
              }}
              components={{ strong: <strong className="accent-color" /> }}
            />
          }
        />
        {cards?.length > 0 && (
          <div
            className="card-collection-container"
            ref={cardCollectionRef}
            style={{
              marginTop: cardCollectionTop,
              marginBottom: 0,
              paddingTop: cardCollectionTopPadding,
            }}>
            <CardCollectionView
              cards={candidateCards}
              isDisabled={isDisabled}
              hideCardsWhenDisabled={false}
              layoutDirection={orientation === "portrait" ? "vertical" : "horizontal"}
              onCardSelected={() => {}}
              onCardDeselected={() => {}}
            />
          </div>
        )}
      </Board>
    </div>
  );
};

PickChoiceOneOnOneModeContent.propTypes = {
  gameSubject: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default PickChoiceOneOnOneModeContent;
