import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import { createPortal } from "react-dom";

import "./chosen-card-dialog.scss";

import { Card } from "../../../../../components";
import { Button } from "../../../../../components/button/Button";

const calculateInitialYDiff = (contentRef, chosenCardRef) => {
  if (!contentRef.current || !chosenCardRef.current) {
    return 0;
  }

  const contentRect = contentRef.current.getBoundingClientRect();
  const cardRect = chosenCardRef.current.getBoundingClientRect();

  return cardRect.y - contentRect.y;
};

const calculateInitialXDiff = (contentRef, chosenCardRef) => {
  if (!contentRef.current || !chosenCardRef.current) {
    return 0;
  }

  const contentRect = contentRef.current.getBoundingClientRect();
  const cardRect = chosenCardRef.current.getBoundingClientRect();

  return (cardRect.x - (contentRect.x + (contentRect.width / 2 - cardRect.width / 2))) * 0.9;
};

const ChosenCardDialog = ({
  card = null,
  actions = [],
  allowDismiss = true,
  onDismiss = () => {},
}) => {
  const chosenCardRef = useRef();
  const contentRef = useRef();
  const [hasCardRef, setHasCardRef] = useState(false);
  const isExiting = useRef(false); // useRef because useState is a useFootgun case

  useEffect(() => {
    if (card) {
      const cardId = card.id;
      const element = document.querySelector(`div[data-cardid="${cardId}"]`);
      chosenCardRef.current = element;
      element.classList.add("fade-out");
      setHasCardRef(true);
      isExiting.current = false;
    } else {
      if (chosenCardRef.current) {
        chosenCardRef.current.classList.remove("fade-out");
      }
      chosenCardRef.current = null;
      setHasCardRef(false);
      isExiting.current = true;
    }
  }, [card]);

  return createPortal(
    <>
      {card && (
        <Helmet>
          <body className="blur-application-foreground scroll-lock"></body>
        </Helmet>
      )}
      <AnimatePresence>
        {card && (
          <motion.div
            className="chosen-card-dialog"
            initial={{ opacity: 0, z: 200 }}
            exit={{ opacity: 0, z: 200 }}
            animate={{ opacity: 1, z: 200 }}
            transition={{ duration: 0.2 }}
            onClick={(e) => {
              e.preventDefault();
              if (allowDismiss) {
                onDismiss();
              }
            }}>
            <motion.div
              initial={{ y: 50, z: 250 }}
              animate={{ y: 0, z: 250 }}
              exit={{ y: 50, z: 250 }}
              ref={contentRef}
              className="chosen-card-dialog-content"
              style={{
                height: 379,
              }}>
              {hasCardRef && (
                <motion.div
                  initial={{
                    scale: 1,
                    x: calculateInitialXDiff(contentRef, chosenCardRef),
                    y: calculateInitialYDiff(contentRef, chosenCardRef),
                    z: 0,
                  }}
                  animate={{ scale: 1.1, x: 0, y: -20, z: 250 }}
                  exit={{
                    scale: 1,
                    x: calculateInitialXDiff(contentRef, chosenCardRef),
                    y: calculateInitialYDiff(contentRef, chosenCardRef),
                    z: 0,
                  }}>
                  <Card
                    cardId={card.id}
                    title={card.title}
                    description={card.description}
                    suit={card.suit.kind}
                  />
                </motion.div>
              )}
              <div className="actions">
                {actions.map((action) => (
                  <Button
                    key={action.action}
                    onClick={() => {
                      if (!isExiting.current) {
                        action.callback();
                      }
                    }}
                    style={action.buttonStyle}
                    disabled={isExiting.current}>
                      <span style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                      }}>
                        {action.icon}
                        <span>{action.label}</span>
                      </span>
                  </Button>
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>,
    document.getElementById("overlay-container"),
  );
};

ChosenCardDialog.propTypes = {
  card: PropTypes.object,
  allowDismiss: PropTypes.bool,
  onDismiss: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      label: PropTypes.any.isRequired,
      icon: PropTypes.any,
      buttonStyle: PropTypes.string,
      callback: PropTypes.func.isRequired,
    }),
  ),
};

export default ChosenCardDialog;
