import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { last } from "lodash";

function isOverflown(element) {
  return element.scrollWidth > element.clientWidth;
}

const maxCharactersPerTitleRow = 22;

function getTitlefontSize({ isTitleOverflown, maxWordLength }) {
  if (maxWordLength > maxCharactersPerTitleRow) {
    return 14;
  }
  if (isTitleOverflown) {
    return 16;
  }

  return 18;
}

// CardTitle.js
//
// A simple h1 element in a wrapper that renders the card's title.
// Will use the browser's hyphenation dictionary but for Firefox it is
// quite bad for some Finnish titles.
// As fallback will use overflow-wrap: break-word if the title would
// otherwise overflow. In other cases use the hyphenation function.
//
function CardTitle({ title, color }) {
  const [forceRenderTick, setForceRenderTick] = useState(0);
  const titleRef = useRef(null);

  const onResize = useRef(() => {
    setForceRenderTick(forceRenderTick + 1);
  });

  useEffect(() => {
    window.addEventListener("resize", onResize.current);
    return () => window.removeEventListener("resize", onResize.current);
  });

  const maxWordLength = last(title.split(" ").map((w) => w.length).sort());
  const isTitleOverflown = titleRef.current ? isOverflown(titleRef.current) : false;

  return (
    <div className="card-title-container">
      <h1 className="card-title measure-stick" aria-hidden={true} ref={titleRef} style={{
        opacity: 0,
        position: "absolute",
      }}>{ title }</h1>
      <h1 className="card-title visible-title" style={{
        color,
        letterSpacing: isTitleOverflown ? -1.0 : 0,
        fontSize: getTitlefontSize({ isTitleOverflown, maxWordLength }),
      }}>
        { title }
      </h1>
    </div>
  );
}

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default CardTitle;
