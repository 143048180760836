import { createAction } from "redux-actions";

const namespaces = {
  playerParticipation: "PLAYER-PARTICIPATION",
  idea: "IDEA",
};

export const idea = {
  post: {
    request: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/POST/REQUEST`),
    success: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/POST/SUCCESS`),
    error: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/POST/ERROR`),
  },
  delete: {
    request: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/DELETE/REQUEST`),
    success: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/DELETE/SUCCESS`),
    error: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/DELETE/ERROR`),
  },
  upvote: {
    request: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/UPVOTE/REQUEST`),
    success: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/UPVOTE/SUCCESS`),
    error: createAction(`${namespaces.playerParticipation}/${namespaces.idea}/UPVOTE/ERROR`),
  },
};
