import React from "react";
import PropTypes from "prop-types";

import "./mini-card-face.scss";

const MiniCardFace = ({ title, description, suit }) => (
  <div className={`mini-card-face suit-${suit.kind}`}>
    <h1 className={`card-title suit-${suit.kind} ${description ? "with-description" : ""}`}>
      <span className={`suit-icon suit-${suit.kind}`}></span>
      {title}
    </h1>
    {description && (
      <p className="card-description">{description}</p>
    )}
  </div>
);

MiniCardFace.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  suit: PropTypes.shape({
    kind: PropTypes.oneOf(["clubs", "hearts", "diamonds", "spades"]).isRequired,
  }).isRequired,
};

export default MiniCardFace;