import React from "react";
import PropTypes from "prop-types";
import PlayerAvatar from "@topaasia/player-avatar";
import { Translation } from "react-i18next";
import { get, sortBy } from "lodash";
import { getCurrentLanguage } from "../../../../modules/shared/utils";

import "./crystallize-notes-tab.scss";
import SuitIcon from "../../../../components/card/suit-icon/SuitIcon";

const CrystallizeNotesTab = ({ gameNotes, perspectives, rounds, topaasiaCard, topaasiaPerspective }) => {
  const topaasiaNotes = gameNotes.find((gameNote) => gameNote.kind === "topaasia_choice");
  const topaasiaNotesText = get(topaasiaNotes, "text", "");
  const topaasiaCardNote = gameNotes.find(
    (gameNote) => gameNote.kind === "card" &&
      gameNote.card.id === topaasiaCard.id &&
      gameNote.playerId === null,
  );
  
  const roundsWithNotes = sortBy(rounds, "playOrder")
    .reverse()
    .map((round) => {
      const perspective = perspectives.find((p) => p.id === round.perspectiveId);

      const sortedCandidates = sortBy(
        gameNotes.filter((n) => n.kind === "card"),
        "createdAt",
      );
      const filteredCandidateNotes = sortedCandidates.filter(
        (candidateNote) =>
          candidateNote.round.id === round.id && candidateNote.text !== "",
      );
      const roundNote = gameNotes.find(
        (gameNote) => gameNote.kind === "round" && gameNote.round && gameNote.round.id === round.id,
      );
      
      const gameMasterCandidateNotes = filteredCandidateNotes.filter(
        (candidateNote) => candidateNote.playerId === null,
      )
      const notesFromPlayers = filteredCandidateNotes.filter(
        (candidateNote) => candidateNote.playerId !== null,
      )
      
      const gameMasterCandidateNotesWithPlayerNotes = gameMasterCandidateNotes.map((gameMasterCandidateNote) => {
        const playerNotes = notesFromPlayers.filter(
          (note) => note.card.id === gameMasterCandidateNote.card.id,
        );
        return { ...gameMasterCandidateNote, playerNotes };
      });      
      
      return {
        ...round,
        perspective,
        roundNote,
        gameMasterCandidateNotes: gameMasterCandidateNotesWithPlayerNotes,
      };
    });
  return (
    <Translation ns="views">
      {(t) => (
        <div className="crystallize-notes-tab">
          <h1>{t("crystallize-view.notes-tab.title")}</h1>
          <div>
            <div className="game-note">
              <h2>{t("crystallize-view.notes-tab.topaasia-choice")}</h2>
              <p>
                {topaasiaNotesText.length > 0
                  ? topaasiaNotesText
                  : t("crystallize-view.notes-tab.no-notes")}
              </p>
              <div className="candidate-notes">
                <h3>
                  {topaasiaPerspective.title[getCurrentLanguage()]} 
                  <span className="arrow-separator">→</span>
                </h3> 
                <div className="candidate-note-item">
                  <h3 className={`suit-${topaasiaCard.suit.kind}`}>
                    <SuitIcon suit={topaasiaCard.suit.kind} size={24} />
                    <span>{topaasiaCard.title}</span>
                  </h3>
                  <p>{ get(topaasiaCardNote, "text", "").length > 0 ? 
                         topaasiaCardNote.text : 
                         t("crystallize-view.notes-tab.no-notes") }</p>
                </div>
              </div>  
            </div>
            {roundsWithNotes.map((round, i) => (
              <div className="game-note" key={round.id}>
                <h2>
                  {roundsWithNotes.length - i}.{" "}
                  {t("crystallize-view.notes-tab.perspective-notes")}{" "}
                  {round.perspective.title[getCurrentLanguage()]}
                </h2>
                <p>
                  {get(round, "roundNote.text", "").length > 0
                    ? round.roundNote.text
                    : t("crystallize-view.notes-tab.no-notes")}
                </p>
                <div className="candidate-notes">
                  {round.gameMasterCandidateNotes.map((candidate) => (
                    <div key={candidate.id} className="candidate-note-item">
                      <h3 className={`suit-${candidate.card.suitKind}`}>
                        <SuitIcon suit={candidate.card.suitKind} size={24} />
                        <span>{candidate.card.title}</span>
                      </h3>
                      <p>{candidate.text}</p>
                      { candidate.playerNotes.map((playerNote) => (
                        <li
                          className="player-game-notes-list-item"
                          key={playerNote.id}
                          style={{ display: "flex" }}>
                          <PlayerAvatar 
                            size={24} 
                            type={playerNote.player.avatar} 
                            playerColor={playerNote.player.color} 
                          />
                          <span className="game-note-text">{playerNote.text}</span>
                        </li>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Translation>
  );
};

CrystallizeNotesTab.propTypes = {
  gameNotes: PropTypes.array.isRequired,
  perspectives: PropTypes.array.isRequired,
  rounds: PropTypes.array.isRequired,
  topaasiaCard: PropTypes.object,
  topaasiaPerspective: PropTypes.object,
};

export default CrystallizeNotesTab;
