import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import TopaasiaCardDecoration from "../decorations/topaasia-card-decoration/TopaasiaCardDecoration";
import { Button } from "../button/Button";

const posedDecorVariants = {
  initial: {
    y: -50,
    z: 50,
    scale: 0.8,
    rotateY: -90,
  },
  normal: {
    y: 0,
    z: 50,
    rotateY: 0,
    transition: { type: "spring", damping: 12, mass: 1, delay: 0.6 },
  },
};

const posedModalVariants = {
  initial: {
    rotateY: 0,
    y: 100,
    opacity: 0,
  },
  normal: {
    rotateY: 0,
    y: 0,
    opacity: 1,
    transition: { delay: 0.3, duration: 0.3 },
  },
};

export const ModalMessage = ({
  title,
  message,
  allowDismiss = false,
  dismissButtonText = null,
  visible,
  focusOnMount = true,
  onDismiss = () => {},
}) => {
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current && focusOnMount) {
      contentRef.current.focus();
    }
  }, [visible]);

  return (
    <AnimatePresence>
      {visible
        && (
          <div className="modal-message-overlay" style={{
            display: "flex",
          }}>
            <motion.div
              key="modal-shade"
              className="modal-shade"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }} />
            <motion.div
              ref={contentRef}
              tabIndex={-1}
              variants={posedModalVariants}
              initial="initial"
              exit="initial"
              animate="normal"
              key="modal"
              className="modal-message-content">
              <motion.div
                className="card-decor-pose"
                variants={posedDecorVariants}
                aria-hidden={true}
                initial="initial"
                animate="normal"
                exit="initial">
                <TopaasiaCardDecoration className="card-decor" />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.75 }}>
                <h1>{title}</h1>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.90 }}>
                <p>{message}</p>
              </motion.div>

              {allowDismiss
                && (
                  <div className="modal-buttons-container">
                    <Button
                      extendClassName="dismiss-button"
                      content={dismissButtonText}
                      onClick={(e) => {
                        if (e) { e.preventDefault(); }
                        onDismiss();
                      }} />
                  </div>
                )}
            </motion.div>
          </div>
        )
      }
    </AnimatePresence>
  );
};

ModalMessage.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  visible: PropTypes.bool.isRequired,
  allowDismiss: PropTypes.bool,
  dismissButtonText: PropTypes.string,
  onDismiss: PropTypes.func,
  focusOnMount: PropTypes.bool,
};
