import axios from "axios";
import { get as safeGet } from "lodash";

import { tokenService } from ".";
import { getCurrentLanguage } from "../modules/shared/utils";

const mediaType = "application/vnd.api+json";

export const isAuthorized = () => {
  const loginToken = tokenService.get();
  const playerId = tokenService.playerId.get();

  return loginToken && playerId;
};

const setAcceptLanguageHeader = (locale = "fi") => (
  { "Accept-Language": locale }
);

const setAuthorizationHeader = () => {
  const loginToken = tokenService.get();
  return { Authorization: `login_token=${loginToken}` };
};

const setHeaders = ({ acceptLanguage = true, authorization = true } = {}) => {
  let headers = {};

  if (acceptLanguage) {
    headers = { ...headers, ...setAcceptLanguageHeader(getCurrentLanguage()) };
  }

  if (authorization) {
    headers = { ...headers, ...setAuthorizationHeader() };
  }

  return headers;
};

axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.timeout = 10000; // 10 seconds
axios.defaults.headers.common.Accept = mediaType;
axios.defaults.headers.common["Content-Type"] = mediaType;

const UNAUTHORIZED = 401;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = safeGet(error, "response", { status: 0 });

    if (status === UNAUTHORIZED && !window.location.href.includes("/join")) {
      window.location.replace("/join");
    }

    return Promise.reject(error);
  },
);

export const getDefaultInstance = () => axios;

export const get = async ({ url }) => axios.get(url, { headers: setHeaders() });
export const post = async ({ url, data }) => axios.post(url, data, { headers: setHeaders() });
export const patch = async ({ url, data }) => axios.patch(url, data, { headers: setHeaders() });
export const put = async ({ url, data }) => axios.put(url, data, { headers: setHeaders() });
export const destroy = async ({ url }) => axios.delete(url, { headers: setHeaders() });

export const serverTime = async () => {
  const url = "server/time";
  const clientTime = Date.now();
  const response = await axios.get(url, { headers: setHeaders(), params: { client_time: clientTime } });

  // Not super accurate (does not account for request time) but will serve its purpose
  return { serverTime: response.data.server_time, offset: response.data.time_offset };
};
