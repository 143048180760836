import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { get } from "lodash";
import PropTypes from "prop-types";
import { AnimatePresence } from "framer-motion";

import * as playerParticipationActions from "../../../modules/player-participation/actions";

import CrystallizeDefaultView from "./crystallize-default-view/CrystallizeDefaultView";
import ActiveParticipationView from "./active-participation-view/ActiveParticipationView";

import "./crystallize-view.scss";

class CrystallizeView extends Component {
  constructor(props) {
    super(props);

    this.selectTopic = () => {};
  }

  componentDidUpdate(prevProps) {
    const oldActiveParticipation = this.activeParticipation(prevProps);
    const newActiveParticipation = this.activeParticipation();

    const activeParticipationEnded = oldActiveParticipation && !newActiveParticipation;
    const activeParticipationStarted = !oldActiveParticipation && newActiveParticipation;

    if (activeParticipationEnded && oldActiveParticipation.playerIdeas.length > 0) {
      this.selectTopic(oldActiveParticipation.conclusion.topicId);
    }
    if (activeParticipationStarted) {
      this.selectTopic(null);
    }
  }

  activeParticipation(props = this.props) {
    return props.playerParticipations.find((pp) => pp.state === "active");
  }

  render() {
    const {
      game,
      crystallization,
      topaasiaPerspective,
      topaasiaCard,
      topics,
      gameNotes,
      playerParticipations,
      player,
      isLoaded,
    } = this.props;

    if (!isLoaded) {
      return <div></div>;
    }

    const activeParticipation = this.activeParticipation();

    return (
      <main className="crystallize-view">
        <CrystallizeDefaultView
          crystallization={crystallization}
          game={game}
          gameNotes={gameNotes}
          topaasiaCard={topaasiaCard}
          topaasiaPerspective={topaasiaPerspective}
          topics={topics}
          playerParticipations={playerParticipations}
          isUpvotingPlayerIdeas={this.props.isUpvotingIdeas}
          player={player}
          disabled={!!activeParticipation}
          ariaHidden={!!activeParticipation}
          setSelectTopicControl={(f) => {
            this.selectTopic = (topicId) => f(topicId);
          }}
          onUpvotePlayerIdea={(playerIdea) => {
            this.props.dispatch(
              playerParticipationActions.idea.upvote.request({
                playerIdeaId: playerIdea.id,
              }),
            );
          }}
        />
        <AnimatePresence>
          {activeParticipation && (
            <ActiveParticipationView
              key={activeParticipation.id}
              topic={topics.find((topic) => topic.id === activeParticipation.conclusion.topicId)}
              game={game}
              playerParticipation={activeParticipation}
              topaasiaCard={topaasiaCard}
              topaasiaPerspective={topaasiaPerspective}
              playerId={player.id}
              isPostingIdea={this.props.isPostingIdea}
              isDeletingIdeas={this.props.isDeletingIdeas}
              onSendIdea={(text) => this.onSendIdea(text)}
              onDeletePlayerIdea={(playerIdea) => this.onDeletePlayerIdea(playerIdea)}
            />
          )}
        </AnimatePresence>
      </main>
    );
  }

  onSendIdea(text) {
    this.props.dispatch(playerParticipationActions.idea.post.request({ text }));
  }

  onDeletePlayerIdea(playerIdea) {
    this.props.dispatch(
      playerParticipationActions.idea.delete.request({ playerIdeaId: playerIdea.id }),
    );
  }
}

CrystallizeView.propTypes = {
  game: PropTypes.object,
  cards: PropTypes.array,
  isLoaded: PropTypes.bool.isRequired,
  gameState: PropTypes.object,
  crystallization: PropTypes.shape({
    findings: PropTypes.array,
    actionPlans: PropTypes.array,
  }),
  gameNotes: PropTypes.array,
  topics: PropTypes.array,
  gameSubject: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  topaasiaRound: PropTypes.object,
  topaasiaCard: PropTypes.object,
  topaasiaPerspective: PropTypes.object,
  playerParticipations: PropTypes.array,
  player: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }),
  isPostingIdea: PropTypes.bool,
  isDeletingIdeas: PropTypes.array,
  isUpvotingIdeas: PropTypes.array,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { cards } = state.deck.deck;
  const topaasia = get(state, "game.gameState.stateData.topaasia", null);

  const isLoaded = topaasia !== null && cards !== null;
  let topaasiaCard = null;
  let topaasiaPerspective = null;
  const { perspectives } = state.game.game;

  if (isLoaded) {
    topaasiaCard = cards.find((c) => c.id === topaasia.chosenCandidate.cardId);
    topaasiaPerspective = perspectives.find((p) => p.id === topaasia.perspectiveId);
  }
  return {
    game: state.game.game,
    topics: state.game.game.topics,
    cards,
    topaasiaCard,
    topaasiaPerspective,
    gameState: state.game.gameState,
    crystallization: state.game.gameState.stateData.crystallization,
    playerParticipations: state.game.gameState.stateData.playerParticipations,
    gameNotes: state.game.gameState.stateData.gameNotes,
    gameCode: state.game.meta.code,
    player: state.player.player,
    isLoaded,
    isPostingIdea: state.playerParticipation.status.isPostingIdea,
    isDeletingIdeas: state.playerParticipation.status.isDeletingIdeas,
    isUpvotingIdeas: state.playerParticipation.status.isUpvotingIdeas,
  };
};

export default compose(withTranslation("views"), connect(mapStateToProps))(CrystallizeView);
