import { useRef, useEffect, useState, useLayoutEffect, useCallback } from "react";
import _, { get } from "lodash";
import i18next from "i18next";
import useResizeObserver from "@react-hook/resize-observer";
import { configuration } from "../../config";

export const fromSnakeToCamel = (data) => {
  if (_.isArray(data)) {
    return _.map(data, fromSnakeToCamel);
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.camelCase(k))
      .mapValues((v) => fromSnakeToCamel(v))
      .value();
  }

  return data;
};

export const fromCamelToSnake = (data) => {
  if (_.isArray(data)) {
    return _.map(data, fromCamelToSnake);
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.snakeCase(k))
      .mapValues((v) => fromCamelToSnake(v))
      .value();
  }

  return data;
};

export const getCurrentLanguage = () => {
  const supportedLanguages = Object.keys(configuration.languages.ui.resources);
  const fallbackLanguage = configuration.languages.ui.fallback;
  const language = get(i18next, "languages[0]", null) || fallbackLanguage;

  if (supportedLanguages.includes(language)) {
    return language;
  }

  i18next.changeLanguage(fallbackLanguage);
  return fallbackLanguage;
};

export function getAbsoluteHeight(el) {
  const styles = window.getComputedStyle(el);
  const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

  return el.offsetHeight + margin;
}

export const useCancelled = () => {
  const isCancelled = useRef(false);

  useEffect(() => {
    isCancelled.current = false;
    return () => {
      isCancelled.current = true;
    };
  }, []);

  return isCancelled;
};

export const useSize = (target) => {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

// A hook that allows access to a previous value of a state or prop
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useVisualViewportHeight() {
  const [viewportHeight, setViewportHeight] = useState(undefined);
  useEffect(() => {
    function handleResize() {
      setViewportHeight(window.visualViewport.height);
    }
    window.visualViewport.addEventListener("resize", handleResize);
    handleResize();
    return () => window.visualViewport.removeEventListener("resize", handleResize);
  }, []);
  return viewportHeight;
}

export const useViewportSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return [width, height];
};

export const useViewportOrientation = () => {
  const [w, h] = useViewportSize();
  return w <= h ? "portrait" : "landscape";
};
