import { createAction } from "redux-actions";

const namespaces = {
  game: "GAME",
  player: "PLAYER",
};

export const get = {
  request: createAction(`${namespaces.game}/GET/REQUEST`),
  success: createAction(`${namespaces.game}/GET/SUCCESS`),
  error: createAction(`${namespaces.game}/GET/ERROR`),
};

export const restore = {
  request: createAction(`${namespaces.game}/RESTORE/REQUEST`),
  success: createAction(`${namespaces.game}/RESTORE/SUCCESS`),
  error: createAction(`${namespaces.game}/RESTORE/ERROR`),
};

export const join = {
  clearErrors: createAction(`${namespaces.game}/JOIN/CLEAR`),
  submit: createAction(`${namespaces.game}/JOIN/SUBMIT`),
  request: createAction(`${namespaces.game}/JOIN/REQUEST`),
  success: createAction(`${namespaces.game}/JOIN/SUCCESS`),
  error: createAction(`${namespaces.game}/JOIN/ERROR`),
};

export const finish = {
  request: createAction(`${namespaces.game}/FINISH/REQUEST`),
  success: createAction(`${namespaces.game}/FINISH/SUCCESS`),
  error: createAction(`${namespaces.game}/FINISH/ERROR`),
};

export const meta = {
  persist: createAction(`${namespaces.game}/META/PERSIST`),
  restore: createAction(`${namespaces.game}/META/RESTORE`),
  clearAll: createAction(`${namespaces.game}/META/CLEAR_ALL`),
};

export const state = {
  get: {
    request: createAction(`${namespaces.game}/STATE/GET/REQUEST`),
    success: createAction(`${namespaces.game}/STATE/GET/SUCCESS`),
    error: createAction(`${namespaces.game}/STATE/GET/ERROR`),
  },
  refresh: {
    request: createAction(`${namespaces.game}/STATE/REFRESH/REQUEST`),
    stop: createAction(`${namespaces.game}/STATE/REFRESH/STOP`),
    cancelled: createAction(`${namespaces.game}/STATE/REFRESH/CANCELLED`),
  },
};
