/* eslint-disable class-methods-use-this */
import { put, call } from "redux-saga/effects";

import { gameNoteActions } from "./actions";
import * as api from "./api";

class GameNoteSagas {
  *post(action) {
    try {
      const { cardId, roundId, text } = action.payload;

      const response = yield call(api.post, { cardId, roundId, text });
      yield put(gameNoteActions.post.success(response.data));

      if (action.payload.onCompletion) {
        action.payload.onCompletion(true);
      }
    } catch (error) {
      yield put(gameNoteActions.post.error(error));

      if (action.payload.onCompletion) {
        action.payload.onCompletion(false);
      }

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  }
}

export const gameNoteSagas = new GameNoteSagas();
