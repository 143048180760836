import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ChatIcon from "@mui/icons-material/Chat";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Board from "../../../../../components/board/Board";
import BoardInfo from "../../../common/board-info/BoardInfo";
import ActionBar from "../../../../../components/action-bar/ActionBar";
import CardCollectionView from "../../../../../components/card/card-collection-view/CardCollectionView";
import { hand } from "../../../../../modules";
import { useViewportSize } from "../../../../../modules/shared/utils";
import {
  useCardCollectionTopMargin,
  useCardCollectionTopPadding,
} from "../../../common/card-collection-utils";

import { useDefaultCardCollectionButtons } from "../pick-candidate-utils";
import ChosenCardDialog from "../chosen-card-dialog/ChosenCardDialog";
import FullscreenPuffLoader from "../../../../../components/status/FullscreenPuffLoader";

import undoPlayIcon from "../../../../../assets/images/undo-play-card-icon.svg?url";
import CommentCardDialog from "../../common/comment-card-dialog/CommentCardDialog";
import { gameNoteActions } from "../../../../../modules/game-notes/actions";
import { usePlayerGameNotesEnabled } from "../../../../../modules/game/selectors";

const PickCandidateTopaasiaModeContent = ({ isDisabled = false, gameSubject }) => {
  const slotCount = 5;

  const { t } = useTranslation(["views", "components"]);

  const dispatch = useDispatch();
  const cardCollectionRef = useRef();
  const boardInfoRef = useRef();
  const actionBarRef = useRef();

  const cardCollectionTopPadding = useCardCollectionTopPadding();
  const cardCollectionTopMargin = useCardCollectionTopMargin(
    cardCollectionRef,
    boardInfoRef,
    actionBarRef,
  );
  const [changeMode, setChangeMode] = useState(false);
  const [focusedCardId, setFocusedCardId] = useState(null);
  const [commentedCardId, setCommentedCardId] = useState(null);

  const [vw, vh] = useViewportSize();
  const isPortrait = vw < vh;

  const game = useSelector((state) => state.game.game);
  const cards = useSelector((state) => state.hand.cards.dealt) || [];
  const playedCardId = useSelector((state) => get(state, "hand.played.cards[0]", null));
  const selection = useSelector((state) => state.hand.selection.cards);
  const gameState = useSelector((state) => state.game.gameState);
  const player = useSelector((state) => state.player.player);
  const isPlaying = useSelector((state) => state.hand.status.isPlaying);
  const isUndoing = useSelector((state) => state.hand.status.undoingPlay);
  const playingCardId = useSelector((state) => state.hand.status.playingCardId);
  const perspective = gameState.currentRoundPerspective;
  const hasPlayedCard = !!playedCardId;
  const enableComments = usePlayerGameNotesEnabled();

  const { currentRoundId } = gameState;

  let cardButtons = useDefaultCardCollectionButtons({
    isDone: hasPlayedCard,
    onToggleChangeMode: (toggled) => {
      setChangeMode(toggled);
    },
  });

  if (playedCardId) {
    cardButtons = [
      {
        key: "undo-play",
        title: t("components:card-actions.undo-play"),
        disabled: isUndoing,
        icon: undoPlayIcon,
        onClick: () => {
          dispatch(hand.actions.play.undo.request({ cardId: playedCardId }));
        },
      },
    ];
  }

  return (
    <div className="pick-candidate-topaasia-mode-content">
      <Board className="padded deck-view-board" isDisabled={isDisabled}>
        <BoardInfo
          gameSubject={gameSubject}
          containerRef={boardInfoRef}
          player={player}
          boardInfoTitle={
            <Trans
              ns="views"
              i18nKey={"pick-candidate-view.play-card-info.title"}
              components={{ strong: <strong className="accent-color" /> }}
            />
          }
          boardInfoMessage={
            <Trans
              ns="views"
              i18nKey={"pick-candidate-view.play-card-info.message"}
              values={{ perspective: perspective.title[game.deckLanguage] }}
              components={{ strong: <strong className="accent-color" /> }}
            />
          }
        />
        {isPlaying && <FullscreenPuffLoader />}
        {cards && (
          <div
            className="card-collection-container"
            tabIndex={0}
            ref={cardCollectionRef}
            style={{
              marginTop: cardCollectionTopMargin,
              paddingTop: cardCollectionTopPadding,
            }}>
            <CardCollectionView
              slotCount={slotCount}
              cards={cards}
              isDisabled={isDisabled}
              selection={selection}
              layoutDirection={isPortrait ? "vertical" : "horizontal"}
              hideCardsWhenDisabled={false}
              hideWhenNotPlayed={true}
              playedCardId={playedCardId}
              processingCardId={playingCardId}
              actionsForCard={(card) => {
                if (changeMode) {
                  return [
                    {
                      action: "change-card",
                      icon: <ChangeCircleIcon fontSize="large" color="orange" />,
                      label: t("components:card-actions.change-card"),
                      onClick: () => {
                        dispatch(hand.actions.change.request([card.id]));
                      },
                    },
                  ];
                }
                if (hasPlayedCard && enableComments) {
                  return [
                    {
                      action: "comment-card",
                      icon: <ChatIcon fontSize="large" color="orange" />,
                      label: t("components:card-actions.comment-card"),
                      onClick: () => {
                        setCommentedCardId(card.id);
                      },
                    },
                  ];
                }
                return [];
              }}
              onCardSelected={(cardId) => {
                if (playedCardId || isPlaying) {
                  return;
                }

                setFocusedCardId(cardId);
              }}
              onCardDeselected={() => {}}
            />
          </div>
        )}
      </Board>

      <ActionBar contentRef={actionBarRef} buttons={cardButtons} isHidden={!!focusedCardId} />

      <ChosenCardDialog
        card={cards.find((c) => c.id === focusedCardId)}
        onDismiss={() => {
          setFocusedCardId(null);
        }}
        actions={[
          {
            action: "confirm",
            icon: <PlayCircleIcon />,
            label: t("pick-candidate-one-on-one-mode-content.chosen-card-dialog.play-this"),
            callback: () => {
              if (!isPlaying) {
                dispatch(hand.actions.play.request(focusedCardId));
              }

              setFocusedCardId(null);
              setChangeMode(false);
            },
          },
          {
            action: "cancel",
            label: t("pick-candidate-one-on-one-mode-content.chosen-card-dialog.choose-another"),
            buttonStyle: "subtle",
            callback: () => {
              setFocusedCardId(null);
            },
          },
        ]}
      />

      <CommentCardDialog
        card={cards.find((c) => c.id === commentedCardId)}
        onSubmit={(comment, card, onCompletion) => {
          dispatch(
            gameNoteActions.post.request({
              text: comment,
              cardId: card.id,
              roundId: currentRoundId,
              onCompletion: (success) => {
                onCompletion(success);
              },
            }),
          );
        }}
        onDismiss={() => {
          setCommentedCardId(null);
        }}
      />
    </div>
  );
};

PickCandidateTopaasiaModeContent.propTypes = {
  isDisabled: PropTypes.bool,
  gameSubject: PropTypes.string.isRequired,
};

export default PickCandidateTopaasiaModeContent;
