import React from "react";
import PropTypes from "prop-types";

import "./perspective-card.scss";

function PerspectiveCard({ description, isTurned, style = {}, suit }) {
  const className = `perspective-card suit-${suit}`;

  return (
    <div className={className} style={style}>
      <p
        className="perspective-card-description"
        style={{
          opacity: isTurned ? 0 : 1,
        }}>
        {description}
      </p>
    </div>
  );
}

PerspectiveCard.propTypes = {
  description: PropTypes.string.isRequired,
  style: PropTypes.object,
  isTurned: PropTypes.bool,
  suit: PropTypes.oneOf(["diamonds", "clubs", "hearts", "spades"]).isRequired,
};

export default PerspectiveCard;
