import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as actions from "./actions";

const initialState = {
  playerFeedback: null,
  status: {
    loading: false,
    posting: false,
  },
};

const playerFeedback = handleActions({
  [actions.get.success]: (_, action) => action.payload.playerFeedback,
  [actions.post.success]: (_, action) => action.payload.playerFeedback,
}, initialState.playerFeedback);

const status = handleActions({
  [actions.get.request]: (state) => ({ ...state, loading: true }),
  [actions.get.success]: (state) => ({ ...state, loading: false }),
  [actions.get.error]: (state) => ({ ...state, loading: false }),

  [actions.post.request]: (state) => ({ ...state, posting: true }),
  [actions.post.success]: (state) => ({ ...state, posting: false }),
  [actions.post.error]: (state) => ({ ...state, posting: false }),
}, initialState.status);

export const reducer = combineReducers({
  playerFeedback,
  status,
});
