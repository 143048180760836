import React from "react";
import PropTypes from "prop-types";
import { range } from "lodash";
import { PulseLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStar as faOpenStar } from "@fortawesome/free-regular-svg-icons/faStar";

const FeedbackStar = ({
  isFilled = false,
  onClick = () => { },
}) => {
  const icon = isFilled ? faStar : faOpenStar;

  return (
    <button
      className={
        `star-button ${isFilled ? "filled" : "open"}`
      }
      onClick={() => onClick()}>
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};

FeedbackStar.propTypes = {
  isFilled: PropTypes.bool,
  isHover: PropTypes.bool,
  isFading: PropTypes.bool,
  onHover: PropTypes.func,
  onClick: PropTypes.func,
};

const FeedbackRatingInput = ({
  score = 0,
  maxScore = 5,
  isDirty = false,
  onChange = () => { },
}) => (
  <div className="feedback-rating-input">
    <div className="feedback-stars">
      {range(maxScore).map((i) => (
        <FeedbackStar
          key={i}
          isFilled={i < score}
          onClick={() => onChange(i + 1)} />
      ))}
    </div>
    {isDirty && (
      <div className="dirty-indicator">
        <PulseLoader size={6} color="white" />
      </div>
    )}
  </div>
);

FeedbackRatingInput.propTypes = {
  score: PropTypes.number,
  maxScore: PropTypes.number,
  isDirty: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FeedbackRatingInput;
