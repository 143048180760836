import * as actions from "./actions";
import { reducer } from "./reducers";
import * as sagas from "./sagas";
import * as api from "./api";

export default {
  actions,
  reducer,
  sagas,
  api,
};
