import { get, orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useCurrentPlayer } from "../player/selectors";

export const useGame = () => useSelector((state) => state.game.game);
export const useGameMode = () => useSelector((state) => get(state, "game.game.gameMode", null));
export const useGameSubject = () => useSelector((state) => state.game.game.subject);
export const useGameNotes = () => useSelector((state) => state.game.gameState.stateData.gameNotes) || [];
export const usePlayerGameNotesEnabled = () => useSelector((state) => state.game.gameState.enablePlayerGameNotes);

export const useGameNotesList = () => {
  const gameNotesById = useGameNotes();
  return orderBy(Object.values(gameNotesById), "createdAt");
};

export const useCurrentRoundId = () => useSelector((state) => state.game.gameState.currentRoundId);

export const useRoundGameNotes = ({ roundId, onlyPlayerNotes = true }) => {
  const gameNotes = useGameNotesList();

  return gameNotes.filter((gameNote) => {
    const matchRound = gameNote.roundId === roundId || get(gameNote, "round.id", null) === roundId;
    const passPlayerNoteFilter = !onlyPlayerNotes || gameNote.playerId != null;
    return matchRound && passPlayerNoteFilter;
  });
}

export const useCardGameNotes = ({ roundId, cardId }) =>
  useRoundGameNotes({ roundId }).filter((gameNote) => gameNote.cardId === cardId);

export const usePlayerGameNotes = ({ roundId, cardId, playerId }) =>
  useCardGameNotes({ roundId, cardId }).filter((gameNote) => gameNote.playerId === playerId);

export const useSelfGameNotes = ({ roundId, cardId }) => {
  const player = useCurrentPlayer();
  return usePlayerGameNotes({ roundId, cardId, playerId: player.id });
};
