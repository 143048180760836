import React from "react";
import PropTypes from "prop-types";
import { PuffLoader } from "react-spinners";

import "./light-content-container.scss";

function LightContentContainer({
  className = "",
  children,
  isLoading = false,
  elevated = true,
  htmlAttrs = {},
  contentRef = null,
  style = {},
}) {
  return (
    <div
      className={`light-content-container ${elevated ? "elevated" : ""} ${className}`}
      ref={contentRef}
      style={style}
      {...htmlAttrs}>
      {!isLoading ? (
        <>{children}</>
      ) : (
        <div className="light-content-container-loading">
          <PuffLoader />
        </div>
      )}
    </div>
  );
}

LightContentContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  elevated: PropTypes.bool,
  htmlAttrs: PropTypes.object,
  contentRef: PropTypes.any,
  style: PropTypes.object,
};

export default LightContentContainer;
