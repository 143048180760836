import React from "react";
import PropTypes from "prop-types";
import PlayerAvatar from "@topaasia/player-avatar";

import "./player-badge.scss";

const getAvatarSize = () => (window.innerWidth < 500 ? 18 : 24);

const getAvatarFontSize = () => (window.innerWidth < 500 ? 12 : 14);

const PlayerBadge = ({ player }) => (
  <div className="player-badge">
    <div className="player-avatar-container">
      <span>
        <PlayerAvatar size={getAvatarSize()} type={player.avatar} playerColor={player.color} />
      </span>
      <span
        style={{
          fontSize: getAvatarFontSize(),
        }}>
        {player.name}
      </span>
    </div>
  </div>
);

PlayerBadge.propTypes = {
  player: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PlayerBadge;
