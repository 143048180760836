import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { get } from "lodash";
import { Translation } from "react-i18next";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import IconButton from "../../../../components/button/icon-button/IconButton";

import { getCurrentLanguage } from "../../../../modules/shared/utils";
import LightContentContainer from "../../../../components/layout/LightContentContainer";

import "./action-plan.scss";

const dueAtToLabel = (actionItem, t) => {
  const dueAtValue = moment(actionItem.dueAt);
  const duration = moment.duration(dueAtValue.diff(moment(actionItem.createdAt)));

  if (duration.days() < 6) {
    return t("action-plan.due-at-options.within-a-day");
  }
  if (duration.days() < 28) {
    return t("action-plan.due-at-options.within-a-week");
  }

  return t("action-plan.due-at-options.within-a-month");
};

const ActionPlan = ({
  topic,
  index,
  actionPlan,
  showActions = true,
  onShowPlayerIdeas,
  disabled = false,
}) => {
  const actionItems = get(actionPlan, "actionItems", []);

  return (
    <Translation ns="components">
      {(t) => (
        <div className="action-plan">
          <h3 className="action-plan-title">
            {index + 1}.&nbsp;
            {topic.title[getCurrentLanguage()]}
          </h3>
          {showActions && (
            <div className="finding-actions">
              <IconButton
                label={t("action-plan.player-ideas")}
                icon={<LightbulbIcon />}
                type="subtle"
                size="small"
                disabled={disabled}
                onClick={() => {
                  onShowPlayerIdeas(topic.id);
                }}
              />
            </div>
          )}
          {actionItems.length === 0 && (
            <div className="no-action-items-label">{t("action-plan.no-actions")}</div>
          )}
          <ul className="action-items">
            {actionItems.map((actionItem) => (
              <li key={actionItem.id} className="action-item">
                <LightContentContainer>
                  <div className="action-item-field action-item-name grid-x">
                    <div className="cell small-12">
                      <div className="action-item-field-value name-value">
                        {actionItem.name || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="action-item-field action-item-assignee grid-x">
                    <div className="cell small-12 large-6">
                      <div className="action-item-field-value assignee-value">
                        {t("action-plan.assignee")}: {actionItem.assignee || "-"}
                      </div>
                    </div>
                    <div className="cell small-12 large-6">
                      <div className="action-item-field-value due-at-value">
                        {t("action-plan.due-at")}: {dueAtToLabel(actionItem, t)}
                      </div>
                    </div>
                  </div>
                </LightContentContainer>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Translation>
  );
};

ActionPlan.propTypes = {
  topic: PropTypes.object.isRequired,
  actionPlan: PropTypes.shape({
    actionItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        assignee: PropTypes.string,
        dueAt: PropTypes.string,
      }),
    ),
  }),
  index: PropTypes.number.isRequired,
  showActions: PropTypes.bool,
  onShowPlayerIdeas: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ActionPlan;
