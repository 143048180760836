import { createAction } from "redux-actions";

const namespaces = {
  vote: "PLAYER-VOTE",
};

export const vote = {
  request: createAction(`${namespaces.vote}/VOTE/REQUEST`),
  success: createAction(`${namespaces.vote}/VOTE/SUCCESS`),
  error: createAction(`${namespaces.vote}/VOTE/ERROR`),
};
