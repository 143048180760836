import { useSelector } from "react-redux";

export const useCardsInSlots = () => {
  const cards = useSelector((state) => state.deck.deck.cards);
  const activePoll = useSelector((state) => state.game.gameState.stateData.activePoll);
  const votes = activePoll && activePoll.votes ? activePoll.votes : [];

  const topaasiaCandidates = useSelector(
    (state) => state.game.gameState.stateData.topaasiaCandidates,
  );

  const { candidates, perspectives, rounds } = topaasiaCandidates;

  const pollState = activePoll && activePoll.poll && activePoll.poll.state;

  const cardsInSlots = candidates.map((candidate) => {
    const card = cards.find((c) => c.id === candidate.cardId);
    const round = rounds.find((r) => r.id === candidate.roundId);
    const perspective = perspectives.find((p) => p.id === round.perspectiveId);

    return {
      ...card,
      key: candidate.id,
      perspective,
      votes:
        votes && pollState === "results" ? votes.filter((v) => v.candidateId === candidate.id) : [],
    };
  });

  return cardsInSlots;
};
