import React from "react";
import PropTypes from "prop-types";
import hexLogo from "../../../assets/images/topaasia-hex-logo.png";
import hexLogoWhite from "../../../assets/images/topaasia-hex-logo-white.png";

const TopaasiaHexDecoration = ({
  size = 200,
  variant = "light",
}) => (
  <div className="topaasia-hex-decoration" style={{
    backgroundImage: `url(${variant === "light" ? hexLogoWhite : hexLogo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: size,
    height: size,
    filter: "drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.3))",
  }}></div>
);

TopaasiaHexDecoration.propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(["light", "dark"]),
}

export default TopaasiaHexDecoration;
