import React from "react";
import PropTypes from "prop-types";
import Board from "../../../components/board/Board";
import { ModalMessage } from "../../../components/message/ModalMessage";

const StateStartModal = ({
  title,
  message,
  dismissButton,
  visible = true,
  onDismiss = () => {},
  boardClass = "",
}) => (
  <main>
    <Board className={`padded ${boardClass}`} isDisabled={true}>
    </Board>
    <ModalMessage
      title={title}
      message={message}
      dismissButtonText={dismissButton}
      visible={visible}
      allowDismiss={!!dismissButton}
      onDismiss={onDismiss} />
  </main>
);

StateStartModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dismissButton: PropTypes.string,
  visible: PropTypes.bool,
  onDismiss: PropTypes.func,
  boardClass: PropTypes.string,
};

export default StateStartModal;
