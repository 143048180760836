import React, { useRef } from "react";
import { Trans } from "react-i18next";

import "./pick-topaasia-one-on-one-mode-content.scss";
import CardCollectionView from "../../../../components/card/card-collection-view/CardCollectionView";
import { useCardsInSlots } from "../pick-topaasia-utils";
import { useViewportOrientation } from "../../../../modules/shared/utils";
import {
  useCardCollectionTopMargin,
  useCardCollectionTopPadding,
} from "../../common/card-collection-utils";
import BoardInfo from "../../common/board-info/BoardInfo";
import { useGameSubject } from "../../../../modules/game/selectors";

const PickTopaasiaOneOnOneModeContent = () => {
  const cardsInSlots = useCardsInSlots();
  const orientation = useViewportOrientation();
  const gameSubject = useGameSubject();

  const cardCollectionRef = useRef();
  const boardInfoRef = useRef();
  const cardCollectionTopMargin = useCardCollectionTopMargin(cardCollectionRef, boardInfoRef);
  const cardCollectionTopPadding = useCardCollectionTopPadding();

  return (
    <div className="pick-topaasia-one-on-one-mode-content">
      <BoardInfo
        gameSubject={gameSubject}
        containerRef={boardInfoRef}
        boardInfoTitle={
          <Trans
            ns="views"
            i18nKey={"pick-topaasia-view.pick-topaasia-info.title.one_on_one"}
            components={{ strong: <strong className="accent-color" /> }}
          />
        }
        boardInfoMessage={
          <Trans
            ns="views"
            i18nKey={"pick-topaasia-view.pick-topaasia-info.message.one_on_one"}
            components={{ strong: <strong className="accent-color" /> }}
          />
        }
      />
      <div
        className="card-collection-container"
        ref={cardCollectionRef}
        style={{
          marginTop: cardCollectionTopMargin,
          paddingTop: cardCollectionTopPadding,
        }}>
        <CardCollectionView
          slotCount={cardsInSlots.length}
          cards={cardsInSlots}
          layoutDirection={orientation === "portrait" ? "vertical" : "horizontal"}
          isDisabled={true}
          hideCardsWhenDisabled={false}
          onCardSelected={() => {}}
          onCardDeselected={() => {}}
        />
      </div>
    </div>
  );
};

PickTopaasiaOneOnOneModeContent.propTypes = {};

export default PickTopaasiaOneOnOneModeContent;
