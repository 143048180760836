import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { times } from "lodash";
import { motion, AnimatePresence } from "framer-motion";
import PlayedCardBarItem from "./played-card-bar-item/PlayedCardBarItem";

import "./played-cards-bar.scss";

const getItemKey = (cards, i) => {
  if (cards[i]) {
    return `${cards[i].id}-${i}`;
  }
  return i;
};

const PlayedCardsBar = ({ slotCount = 3, cards = [], onChangeCard }) => (
  <div className="played-cards-bar">
    {times(slotCount, (i) => (
      <div key={getItemKey(cards, i)} className={`played-card-slot ${cards[i] ? "with-card" : ""}`}>
        <AnimatePresence>
          {cards[i] ? (
            <PlayedCardBarItem card={cards[i]} onChangeCard={() => onChangeCard(cards[i])} />
          ) : (
            <motion.div
              className="card-placeholder"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <Trans ns="views" i18nKey="played-cards-bar.card" values={{ index: i + 1 }} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    ))}
  </div>
);

PlayedCardsBar.propTypes = {
  slotCount: PropTypes.number,
  cards: PropTypes.array,
  onChangeCard: PropTypes.func.isRequired,
};

export default PlayedCardsBar;
