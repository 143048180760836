import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import { flatten } from "lodash";

import CrystallizeTabView from "../crystallize-tab-view/CrystallizeTabView";
import TopicPlayerIdeasView from "../topic-player-ideas-view/TopicPlayerIdeasView";

import "./crystallize-default-view.scss";

const CrystallizeDefaultView = ({
  crystallization,
  game,
  gameNotes,
  topaasiaCard,
  topaasiaPerspective,
  topics,
  playerParticipations,
  player,
  disabled = false,
  ariaHidden = false,
  isUpvotingPlayerIdeas = [],
  setSelectTopicControl = () => {},
  onUpvotePlayerIdea = () => {},
}) => {
  const [selectedTopic, setSelectedTopic] = useState(null);

  useEffect(() => {
    setSelectTopicControl((topicId) => {
      setSelectedTopic(topicId);
    });
  }, []);

  const playerIdeasByTopic = (topicId) => (
    flatten(playerParticipations
      .filter((pp) => pp.conclusion.topicId === topicId)
      .map((pp) => pp.playerIdeas))
  );

  const viewWidth = () => Math.min(window.innerWidth, 1024);

  const shouldBeDisabled = disabled || !!selectedTopic;

  return (
    <div aria-hidden={ariaHidden}>
      <CrystallizeTabView
        topaasiaCard={topaasiaCard}
        topaasiaPerspective={topaasiaPerspective}
        crystallization={crystallization}
        topics={topics}
        playerParticipations={playerParticipations}
        gameNotes={gameNotes}
        game={game}
        player={player}
        disabled={shouldBeDisabled}
        ariaHidden={!!selectedTopic}
        setSelectedTopic={(topicId) => {
          setSelectedTopic(topicId);
        }} />
      <AnimatePresence>
        { selectedTopic && (
          <>
            <motion.div
              className="topic-player-ideas-view-background"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        { selectedTopic && (
          <motion.div
            className="topic-player-ideas-view-holder"
            initial={{ x: viewWidth() }}
            animate={{ x: 0 }}
            exit={{ x: viewWidth() }}
            transition={{ duration: 0.3 }}
            onClick={() => {
              setSelectedTopic(null);
            }}>
            <TopicPlayerIdeasView
              topic={topics.find((t) => t.id === selectedTopic)}
              playerIdeas={playerIdeasByTopic(selectedTopic)}
              playerId={player.id}
              onUpvotePlayerIdea={onUpvotePlayerIdea}
              isUpvotingPlayerIdeas={isUpvotingPlayerIdeas}
              onClose={() => {
                setSelectedTopic(null);
              }} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

CrystallizeDefaultView.propTypes = {
  crystallization: PropTypes.object,
  game: PropTypes.object,
  gameNotes: PropTypes.array,
  topaasiaCard: PropTypes.object,
  topaasiaPerspective: PropTypes.object,
  topics: PropTypes.array,
  playerParticipations: PropTypes.array,
  setSelectTopicControl: PropTypes.func,
  player: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  ariaHidden: PropTypes.bool,
  onUpvotePlayerIdea: PropTypes.func,
  isUpvotingPlayerIdeas: PropTypes.array,
};

export default CrystallizeDefaultView;
