import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import changeIcon from "../../../../assets/images/change-cards-icon.svg?url";
import changeAllIcon from "../../../../assets/images/change-all-cards-icon.svg?url";
import doneChangingCardsIcon from "../../../../assets/images/done-changing-cards-icon.svg?url";

import { hand } from "../../../../modules";

export const useDefaultCardCollectionButtons = ({ isDone = false, onToggleChangeMode = () => {} } = {}) => {
  const { t } = useTranslation("components");
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.hand.cards.dealt);

  const [uiMode, setUiMode] = useState("play");

  useEffect(() => {
    onToggleChangeMode(uiMode === "change-cards");
  }, [uiMode])
  
  if (isDone) {
    return [];
  }

  if (uiMode === "change-cards") {
    return [
      {
        key: "cancel",
        title: t("components:card-actions.change-done"),
        disabled: false,
        icon: doneChangingCardsIcon,
        onClick: () => {
          setUiMode("play");
        },
      },
      {
        key: "change-all-cards",
        title: t("components:card-actions.change-all"),
        disabled: false,
        icon: changeAllIcon,
        onClick: () => {
          dispatch(hand.actions.change.request(cards.map((c) => c.id)));
          setUiMode("play");
        },
      },
    ];
  }

  return [
    {
      key: "change-cards",
      title: t("components:card-actions.change-selected"),
      disabled: false,
      icon: changeIcon,
      onClick: () => {
        setUiMode("change-cards");
      },
    },
  ];
};
