import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const get = async () => {
  const response = await backendService.get({ url: `/player-api/deck` });

  const { data } = response;
  const { deck, cards, suits } = data;

  const transformed = {
    data: {
      deck: {
        ...deck,
        suits: suits.map((suit) => (
          { ...suit,
            cards: cards.filter((card) => card.suit_id === suit.id).map((filteredCard) => (
              { ...filteredCard, suit }
            )) }
        )),
      },
    },
  };

  return fromSnakeToCamel(transformed);
};
