import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import ThumbUp from "@mui/icons-material/ThumbUp";
import { useDispatch, useSelector } from "react-redux";
import CardCollectionView from "../../../../components/card/card-collection-view/CardCollectionView";
import BoardInfo from "../../common/board-info/BoardInfo";
import { ModalMessage, ViewSubject } from "../../../../components";

import { playerVote } from "../../../../modules";
import { useCardsInSlots } from "../pick-topaasia-utils";
import { useViewportOrientation } from "../../../../modules/shared/utils";
import {
  useCardCollectionTopMargin,
  useCardCollectionVoteIndicators,
} from "../../common/card-collection-utils";
import ChosenCardDialog from "../../rounds/pick-candidate/chosen-card-dialog/ChosenCardDialog";

const PickTopaasiaTopaasiaModeContent = ({ gameSubject }) => {
  const { t } = useTranslation("views");
  const dispatch = useDispatch();

  const [selectedCandidateKey, setSelectedCandidateKey] = useState(null);
  const orientation = useViewportOrientation();

  const [hasSeenPoll, setHasSeenPoll] = useState(false);

  const cardCollectionRef = useRef();
  const boardInfoRef = useRef();
  const cardCollectionMarginTop = useCardCollectionTopMargin(cardCollectionRef, boardInfoRef);

  const activePoll = useSelector((state) => state.game.gameState.stateData.activePoll);
  const votes = activePoll && activePoll.votes ? activePoll.votes : [];
  const player = useSelector((state) => state.player.player);
  const ownVote = votes.find((v) => v.playerId === player.id);

  const cards = useSelector((state) => state.deck.deck.cards);
  const game = useSelector((state) => state.game.game);
  const isVoting = useSelector((state) => state.playerVote.status.isVoting);
  const voteError = useSelector((state) => state.playerVote.status.voteError);

  const pollState = activePoll && activePoll.poll && activePoll.poll.state;

  const cardsInSlots = useCardsInSlots();

  const shouldDisableCards = pollState !== "active" || shouldShowVoteModal();

  const chosenCardModel = cardsInSlots.find((c) => c.key === selectedCandidateKey);

  function shouldShowVoteModal() {
    const shouldShow =
      activePoll &&
      !ownVote &&
      activePoll.poll.state === "active" &&
      activePoll.poll.id !== hasSeenPoll;

    return !!shouldShow;
  }

  function onVoteModalDismiss() {
    setHasSeenPoll(activePoll ? activePoll.poll.id : null);
  }

  const votedCard =
    (pollState === "active" &&
      ownVote &&
      cardsInSlots.find((c) => c.key === ownVote.candidateId)) ||
    null;

  const voteIndicatorProvider = useCardCollectionVoteIndicators({ votedCard });

  const composeBoardInfoTitle = () => {
    if (pollState === "active") {
      return (
        <Trans
          ns="views"
          i18nKey={"pick-topaasia-view.pick-topaasia-info.title.topaasia-poll"}
          components={{ strong: <strong className="accent-color" /> }}
        />
      );
    }

    return (
      <Trans
        ns="views"
        i18nKey={"pick-topaasia-view.pick-topaasia-info.title.topaasia"}
        components={{ strong: <strong className="accent-color" /> }}
      />
    );
  };

  const composeBoardInfoMessage = () => {
    if (pollState === "active") {
      return (
        <Trans
          ns="views"
          i18nKey={"pick-topaasia-view.pick-topaasia-info.message.topaasia-poll"}
          components={{ strong: <strong className="accent-color" /> }}
        />
      );
    }

    return (
      <Trans
        ns="views"
        i18nKey={"pick-topaasia-view.pick-topaasia-info.message.topaasia"}
        components={{ strong: <strong className="accent-color" /> }}
      />
    );
  };

  return (
    <div className="pick-topaasia-topaasia-mode-content">
      <BoardInfo
        containerRef={boardInfoRef}
        gameSubject={gameSubject}
        boardInfoTitle={composeBoardInfoTitle()}
        boardInfoMessage={composeBoardInfoMessage()}
      />
      {cards && cards.length > 0 && (
        <div
          ref={cardCollectionRef}
          className={`card-collection-container with-empty-actions`}
          style={{
            marginTop: cardCollectionMarginTop,
          }}>
          <CardCollectionView
            slots={cardsInSlots.length}
            sortByVotes={false}
            groupByCardId={false}
            cards={cardsInSlots}
            language={game.deckLanguage}
            layoutDirection={orientation === "portrait" ? "vertical" : "horizontal"}
            isDisabled={shouldDisableCards}
            hideCardsWhenDisabled={false}
            hideWhenNotPlayed={ownVote && pollState === "active"}
            playedCardId={votedCard && votedCard.key}
            actionsForCard={voteIndicatorProvider}
            onCardSelected={(_, key) => {
              if (!activePoll) {
                return;
              }
              if (ownVote != null) {
                return;
              }
              if (activePoll.poll.state === "results") {
                return;
              }

              setSelectedCandidateKey(key);
            }}
            onCardDeselected={() => {
              setSelectedCandidateKey(null);
            }}
          />
        </div>
      )}

      <ViewSubject
        title={t("pick-topaasia-view.titles.pick-topaasia-title")}
        className={"with-empty-actions"}
        subtitle={pollState ? t("pick-topaasia-view.titles.pick-topaasia-vote-sub") : null}
      />

      <ModalMessage
        title={t("vote-dialog.title")}
        message={t("vote-dialog.vote-help.pick-topaasia")}
        dismissButtonText={t("vote-dialog.confirm-button")}
        visible={shouldShowVoteModal()}
        allowDismiss={true}
        onDismiss={() => {
          onVoteModalDismiss();
        }}
      />

      <ChosenCardDialog
        card={chosenCardModel}
        onDismiss={() => { setSelectedCandidateKey(null) }}
        actions={[
          {
            action: "confirm",
            icon: <ThumbUp />,
            label: t("pick-choice-topaasia-mode-content.chosen-card-dialog.vote-this"),
            callback: () => {
              setSelectedCandidateKey(null);

              if (!isVoting) {
                dispatch(playerVote.actions.vote.request({ candidateId: selectedCandidateKey }));
              }
            },
          },
          {
            action: "cancel",
            label: t("pick-choice-topaasia-mode-content.chosen-card-dialog.vote-another"),
            buttonStyle: "subtle",
            callback: () => {
              setSelectedCandidateKey(null);
            },
          },
        ]}
      />

      <AnimatePresence>
        {voteError && (
          <motion.div
            className="board-error-toast"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 0.5 }}>
            {t("player-vote.errors.vote-error")}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

PickTopaasiaTopaasiaModeContent.propTypes = {
  gameSubject: PropTypes.string,
};

export default PickTopaasiaTopaasiaModeContent;
