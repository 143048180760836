import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import { motion } from "framer-motion";
import SendIcon from "@mui/icons-material/Send";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Translation } from "react-i18next";
import { Helmet } from "react-helmet";

import { getCurrentLanguage } from "../../../../modules/shared/utils";

import PlayerIdeaList from "../topic-player-ideas-view/player-idea-list/PlayerIdeaList";
import IconButton from "../../../../components/button/icon-button/IconButton";
import { serverTime } from "../../../../services/backendService";
import TextAreaInput from "../../../../components/form/TextAreaInput";

import "./active-participation-view.scss";
import LightContentContainer from "../../../../components/layout/LightContentContainer";

class ActiveParticipationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ideaDraft: "",
      serverTimeOffsetMs: 0,
      secondsLeft: 0,
      offsetTop: 0,
    };

    this.timerLoop = null;
    this.onViewportSizeChanged = this.onViewportSizeChanged.bind(this);
    this.onGlobalFocusOut = this.onGlobalFocusOut.bind(this);
    this.scrollableViewRef = React.createRef();
    this.textAreaRef = React.createRef();
    this.activeParticipationRef = React.createRef();

    this.canceled = false;
  }

  componentDidMount() {
    const { playerParticipation } = this.props;
    serverTime().then(({ offset }) => this.setState({ serverTimeOffsetMs: offset }));
    this.calculateSecondsLeft(playerParticipation);

    this.timerLoop = setInterval(() => {
      if (this.canceled) {
        return;
      }

      // Avoid using captured "playerParticipation". This causes a timer bug when the
      // component don't have time to unmount between participations.
      this.calculateSecondsLeft(this.props.playerParticipation);
    }, 500);

    window.visualViewport.addEventListener("resize", this.onViewportSizeChanged);

    document.addEventListener("focusout", this.onGlobalFocusOut);

    this.scrollableViewRef.current.focus();
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.playerParticipation.id !== this.props.playerParticipation.id) {
      // Participation changed without unmount - reset state
      this.calculateSecondsLeft(this.props.playerParticipation);
      this.setState({ ideaDraft: "" });
    }

    if (prevProps.isPostingIdea && !this.props.isPostingIdea) {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    this.canceled = true;
    window.visualViewport.removeEventListener("resize", this.onViewportSizeChanged);
    clearInterval(this.timerLoop);
  }

  onViewportSizeChanged() {
    if (this.canceled) {
      return;
    }

    this.setState({ offsetTop: window.visualViewport.offsetTop });
    // this.activeParticipationRef.current.style.top = `${window.visualViewport.offsetTop}px`;
    this.scrollToBottom();
  }

  onGlobalFocusOut() {
    if (this.canceled) {
      return;
    }

    this.setState({ offsetTop: 0 });
  }

  scrollToBottom({ behavior = "smooth" } = {}) {
    const view = this.scrollableViewRef.current;

    setTimeout(() => {
      if (this.canceled) {
        return;
      }

      view.scrollTo({ top: view.scrollHeight, behavior });
    }, 100);
  }

  filterOwnIdeas(props = this.props) {
    const { playerParticipation, playerId } = props;

    const ownIdeas = playerParticipation.playerIdeas.filter((idea) => idea.player.id === playerId);

    return sortBy(ownIdeas, "createdAt");
  }

  calculateSecondsLeft(playerParticipation) {
    const { serverTimeOffsetMs } = this.state;

    const currentTimeMs = Date.now();
    const startedAtMs = moment(playerParticipation.startedAt).valueOf();
    const durationMs = playerParticipation.durationSec * 1000;
    const timeLeftMs = Math.max(startedAtMs + durationMs - (currentTimeMs + serverTimeOffsetMs), 0);

    this.setState({ secondsLeft: timeLeftMs / 1000 });
  }

  canPostIdea() {
    const { isPostingIdea } = this.props;
    const hasTextContent = this.state.ideaDraft.trim().length;

    return !isPostingIdea && hasTextContent;
  }

  render() {
    const {
      game,
      topic,
      onSendIdea,
      isDeletingIdeas,
      onDeletePlayerIdea,
      topaasiaCard,
      topaasiaPerspective,
    } = this.props;

    const ownIdeas = this.filterOwnIdeas();

    const getSecondsString = (seconds) => String(seconds).padStart(2, "0");
    const durationLeft = moment.duration({ seconds: this.state.secondsLeft });

    const { gameMode } = game;

    return (
      <Translation ns="views">
        {(t) => (
          <>
            <Helmet>
              <meta name="theme-color" content="#222222" />
              <html style="overflow: hidden;"></html>
            </Helmet>
            <motion.div
              aria-hidden={true}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="active-participation-view-background"
              className="active-participation-view-background"></motion.div>
            <motion.div
              role="dialog"
              aria-labelledby="active-participation-topic-title"
              aria-describedby="active-participation-main-help-paragraph"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0, top: this.state.offsetTop }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ delay: 0, duration: 0.3 }}
              key="active-participation-view"
              className="active-participation-view"
              ref={this.activeParticipationRef}>
              <div className="indented-content active-participation-header-content">
                <div>
                  <h1 id="active-participation-topic-title">{topic.title[getCurrentLanguage()]}</h1>
                  <div className="topaasia-label">
                    {topaasiaPerspective.title[getCurrentLanguage()]}&nbsp;•&nbsp;
                    {topaasiaCard.title}
                  </div>
                </div>
                <div className="timer-container">
                  {durationLeft.minutes()}:{getSecondsString(durationLeft.seconds())}
                </div>
              </div>
              <div
                className="active-participation-view-scrollable-portion"
                ref={this.scrollableViewRef}>
                <LightContentContainer className="help-content">
                  <p id="active-participation-main-help-paragraph" className="help-paragraph">
                    {t(`crystallize-view.active-participation-view.help-part-1.${gameMode}`)}
                  </p>
                  <p className="help-paragraph">
                    {t("crystallize-view.active-participation-view.help-part-2")}
                  </p>
                </LightContentContainer>
                <PlayerIdeaList
                  mountAnimation={false}
                  playerIdeas={ownIdeas}
                  isDeletingIdeas={isDeletingIdeas}
                  getPlayerIdeaActions={(playerIdea) => [
                    {
                      action: "delete",
                      ariaLabel: t(
                        "crystallize-view.active-participation-view.aria.remove-idea-button",
                        {
                          text: playerIdea.text,
                        },
                      ),
                      disabled: isDeletingIdeas.includes(playerIdea.id),
                      requireDoubleTap: true,
                      icon: <DeleteOutlineIcon />,
                      callback: () => {
                        onDeletePlayerIdea(playerIdea);
                      },
                    },
                  ]}
                />
              </div>
              <div className="indented-content active-participation-footer-content">
                <div className="input-container">
                  <div className="textarea-container">
                    <TextAreaInput
                      value={this.state.ideaDraft}
                      onChange={(e) => this.setState({ ideaDraft: e.target.value })}
                      maxRows={10}
                      inputProps={{ maxLength: 300, autoFocus: true }}
                      inputRef={this.textAreaRef}
                      placeholder={t(
                        "crystallize-view.active-participation-view.textarea-placeholder",
                      )}
                    />
                  </div>

                  <div className="actions-container">
                    <IconButton
                      extraClassName="send-button"
                      icon={<SendIcon />}
                      label={`${
                        this.props.isPostingIdea
                          ? t("crystallize-view.active-participation-view.sending-action")
                          : t("crystallize-view.active-participation-view.send-action")
                      }`}
                      type="secondary"
                      disabled={!this.canPostIdea()}
                      onClick={() => {
                        onSendIdea(this.state.ideaDraft);
                        this.textAreaRef.current.focus();
                        this.setState({ ideaDraft: "" });
                      }}
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </Translation>
    );
  }
}

ActiveParticipationView.propTypes = {
  topic: PropTypes.object.isRequired,
  playerParticipation: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  playerId: PropTypes.string.isRequired,
  onSendIdea: PropTypes.func.isRequired,
  isPostingIdea: PropTypes.bool.isRequired,
  isDeletingIdeas: PropTypes.array.isRequired,
  onDeletePlayerIdea: PropTypes.func.isRequired,
  topaasiaPerspective: PropTypes.object.isRequired,
  topaasiaCard: PropTypes.object.isRequired,
};

export default ActiveParticipationView;
