import React from "react";
import PropTypes from "prop-types";

import "./icon-button.scss";

const IconButton = ({
  extraClassName = "",
  label = "",
  icon = "",
  type = "primary",
  size = "large",
  disabled = false,
  onClick = () => {},
}) => (
  <button
    className={
      `button icon-button
      ${extraClassName}
      ${type}
      ${size}
      ${disabled ? "disabled" : ""}
      ${!label.length ? "no-label" : ""}`
    }
    disabled={disabled}
    onClick={() => {
      if (!disabled) { onClick(); }
    }}>
    <span className="button-icon">{ icon }</span>
    <span className="button-label">{ label }</span>
  </button>
);

IconButton.propTypes = {
  extraClassName: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  type: PropTypes.oneOf(["primary", "secondary", "no-background", "subtle"]),
  size: PropTypes.oneOf(["minimal", "small", "large"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IconButton;
