import "focus-visible";
import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ApplicationErrorBoundary from "./components/error/ApplicationErrorBoundary";

import { i18n } from "./i18n";
import { renderRoutes } from "./routes";
import { getCurrentLanguage } from "./modules/shared/utils";

export function Application({ history, store }) {
  return (
    <>
      <Helmet>
        <html lang={getCurrentLanguage()}></html>
      </Helmet>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ApplicationErrorBoundary store={store}>
            <ConnectedRouter history={history}>
              <div id="application-container">{renderRoutes()}</div>
              <div id="overlay-container"></div>
            </ConnectedRouter>
          </ApplicationErrorBoundary>
        </I18nextProvider>
      </Provider>
    </>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};
