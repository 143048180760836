import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { orderBy } from "lodash";
import ThumbUp from "@mui/icons-material/ThumbUp";
import { Helmet } from "react-helmet";
import ChatIcon from "@mui/icons-material/Chat";
import Board from "../../../../../components/board/Board";
import BoardInfo from "../../../common/board-info/BoardInfo";
import { ViewSubject } from "../../../../../components";
import CardCollectionView from "../../../../../components/card/card-collection-view/CardCollectionView";

// Images

import { playerVote } from "../../../../../modules";
import { useViewportOrientation } from "../../../../../modules/shared/utils";
import { useCardCollectionTopMargin } from "../../../common/card-collection-utils";
import ChosenCardDialog from "../../pick-candidate/chosen-card-dialog/ChosenCardDialog";
import CommentCardDialog from "../../common/comment-card-dialog/CommentCardDialog";
import { gameNoteActions } from "../../../../../modules/game-notes/actions";
import { usePlayerGameNotesEnabled } from "../../../../../modules/game/selectors";

const PickChoiceTopaasiaModeContent = ({ gameSubject, isDisabled = false }) => {
  const activePoll = useSelector((state) => state.game.gameState.stateData.activePoll);
  const player = useSelector((state) => state.player.player);
  const cards = useSelector((state) => state.deck.deck.cards);
  const candidates = useSelector((state) => state.game.gameState.stateData.candidates);
  const game = useSelector((state) => state.game.game);
  const gameState = useSelector((state) => state.game.gameState);
  const isVoting = useSelector((state) => state.playerVote.status.isVoting);
  // Should these be supported again??
  // const voteError = useSelector((state) => state.playerVote.status.voteError);
  // const [hasSeenPoll, setHasSeenPoll] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [commentedCardId, setCommentedCardId] = useState(null);

  const votes = (activePoll && activePoll.votes) || [];
  const ownVote = votes.find((v) => v.playerId === player.id);
  const orientation = useViewportOrientation();

  const { t } = useTranslation("views");
  const dispatch = useDispatch();
  const cardCollectionRef = useRef();
  const boardInfoRef = useRef();

  const cardCollectionMarginTop = useCardCollectionTopMargin(cardCollectionRef, boardInfoRef);

  const { currentRoundId } = gameState;
  const perspective = gameState.currentRoundPerspective;

  const pollState = (activePoll && activePoll.poll && activePoll.poll.state) || null;
  const enableComments = usePlayerGameNotesEnabled();

  const shuffledCandidates = orderBy(candidates, "id");

  const cardsInSlots = shuffledCandidates.map((candidate) => {
    const card = cards.find((c) => c.id === candidate.cardId);

    return {
      ...card,
      key: candidate.id,
      votes: votes && pollState === "results" ? votes.filter((v) => v.cardId === card.id) : [],
    };
  });

  const votedCard =
    (pollState === "active" && ownVote && cardsInSlots.find((c) => c.id === ownVote.cardId)) ||
    null;

  const shouldDisableCards = pollState !== "active" || isDisabled;

  const composeBoardTitleMessage = () => {
    if (pollState === "active") {
      return (
        <Trans
          ns="views"
          i18nKey={"pick-choice-view.pick-choice-info.title.topaasia-poll"}
          components={{ strong: <strong className="accent-color" /> }}
        />
      );
    }

    return (
      <Trans
        ns="views"
        i18nKey={"pick-choice-view.pick-choice-info.title.topaasia"}
        components={{ strong: <strong className="accent-color" /> }}
      />
    );
  };

  const composeBoardInfoMessage = () => {
    if (pollState === "active") {
      return (
        <Trans
          ns="views"
          i18nKey={"pick-choice-view.pick-choice-info.message.topaasia-poll"}
          values={{ perspective: perspective.title[game.deckLanguage] }}
          components={{ strong: <strong className="accent-color" /> }}
        />
      );
    }

    return (
      <Trans
        ns="views"
        i18nKey={"pick-choice-view.pick-choice-info.message.topaasia"}
        values={{ perspective: perspective.title[game.deckLanguage] }}
        components={{ strong: <strong className="accent-color" /> }}
      />
    );
  };

  return (
    <div className="pick-choice-topaasia-mode-content">
      <Helmet>
        <meta name="theme-color" content="#222222" />
        <html className="card-background"></html>
      </Helmet>
      <Board className="padded pick-choice-view-board" isDisabled={isDisabled}>
        <BoardInfo
          containerRef={boardInfoRef}
          gameSubject={gameSubject}
          player={player}
          boardInfoTitle={composeBoardTitleMessage()}
          boardInfoMessage={composeBoardInfoMessage()}
        />
        {cards && cards.length > 0 && (
          <div
            ref={cardCollectionRef}
            className={`card-collection-container with-empty-actions
                  ${pollState === "active" ? "with-poll" : ""}`}
            style={{
              marginTop: cardCollectionMarginTop,
            }}>
            <CardCollectionView
              slots={cardsInSlots.length}
              sortByVotes={false}
              cards={cardsInSlots}
              layoutDirection={orientation === "portrait" ? "vertical" : "horizontal"}
              language={game.deckLanguage}
              selection={[]}
              playedCard={votedCard && votedCard.id}
              isDisabled={shouldDisableCards}
              hideCardsWhenDisabled={false}
              bottomTextForCard={(card) => {
                const candidate = candidates.find((c) => c.cardId === card.id && c.playerId === player.id)
                return candidate ? t("pick-choice-view.card-label.my-card") : null
              }}
              actionsForCard={(card) => {
                if (votedCard?.id && card.id === votedCard.id) {
                  return [
                    {
                      action: "vote-card",
                      icon: <ThumbUp fontSize="large" color="orange" />,
                      label: t("components:card-actions.change-card"),
                      onClick: () => {},
                    },
                  ];
                }
                if (pollState !== "active" && enableComments) {
                  return [
                    {
                      action: "comment-card",
                      icon: <ChatIcon />,
                      label: t("components:card-actions.comment-card"),
                      onClick: () => {
                        setCommentedCardId(card.id);
                      },
                    },
                  ];
                }

                return [];
              }}
              onCardSelected={(cardId) => {
                if (!activePoll) {
                  return;
                }
                if (ownVote != null) {
                  return;
                }

                setSelectedCardId(cardId);
              }}
              onCardDeselected={() => {
                setSelectedCardId(null);
              }}
            />
          </div>
        )}
      </Board>

      {/* works around a visual Chrome bug that causes fixed positioned elements
      to become static when using filter: blur */}
      {!selectedCardId && (
        <ViewSubject
          title={perspective.title[game.deckLanguage]}
          className={"with-empty-actions"}
          subtitle={
            pollState
              ? t("pick-choice-view.titles.vote-choice-sub")
              : t("pick-choice-view.titles.pick-choice-sub")
          }
          hidden={!perspective}
        />
      )}

      <ChosenCardDialog
        card={cards.find((c) => c.id === selectedCardId)}
        onDismiss={() => {
          setSelectedCardId(null);
        }}
        actions={[
          {
            action: "confirm",
            icon: <ThumbUp />,
            label: t("pick-choice-topaasia-mode-content.chosen-card-dialog.vote-this"),
            callback: () => {
              setSelectedCardId(null);

              if (!isVoting) {
                dispatch(playerVote.actions.vote.request({ cardId: selectedCardId }));
              }
            },
          },
          {
            action: "cancel",
            label: t("pick-choice-topaasia-mode-content.chosen-card-dialog.vote-another"),
            buttonStyle: "subtle",
            callback: () => {
              setSelectedCardId(null);
            },
          },
        ]}
      />

      <CommentCardDialog
        card={cards.find((c) => c.id === commentedCardId)}
        onDismiss={() => {
          setCommentedCardId(null);
        }}
        displayMode="all"
        onSubmit={(comment, card, onCompletion) => {
          dispatch(
            gameNoteActions.post.request({
              text: comment,
              cardId: card.id,
              roundId: currentRoundId,
              onCompletion,
            }),
          );
        }}
      />
    </div>
  );
};

PickChoiceTopaasiaModeContent.propTypes = {
  gameSubject: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default PickChoiceTopaasiaModeContent;
