/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { Translation } from "react-i18next";

import CrystallizePlanTab from "../crystallize-plan-tab/CrystallizePlanTab";
import CrystallizeNotesTab from "../crystallize-notes-tab/CrystallizeNotesTab";
import { releaseScrollLock, setScrollLock } from "../../../../modules/shared/scroll-locker";

import "./crystallize-tab-view.scss";
import LightContentContainer from "../../../../components/layout/LightContentContainer";

const scrollLockId = "crystallize-tab-view";

const CrystallizeTabView = ({
  topaasiaCard,
  topaasiaPerspective,
  crystallization,
  topics,
  gameNotes,
  game,
  player,
  playerParticipations = [],
  setSelectedTopic,
  disabled = false,
  ariaHidden = false,
}) => {
  const [selectedTab, setSelectedTab] = useState("plan");
  const [layoutType, setLayoutType] = useState("mobile");

  const onResize = () => {
    if (window.innerWidth <= 1024) {
      setLayoutType("mobile");
    } else {
      setLayoutType("desktop");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
      releaseScrollLock(scrollLockId);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab]);

  useEffect(() => {
    if (disabled) {
      setScrollLock(scrollLockId);
    } else {
      releaseScrollLock(scrollLockId);
    }
  }, [disabled]);

  return (
    <Translation ns="views">
      {(t) => (
        <div className="crystallize-tab-view" aria-hidden={ariaHidden}>
          { layoutType === "mobile" ? renderMobileView(t) : renderDesktopView(t) }
        </div>
      )}
    </Translation>
  );

  function renderCrystallizePlanTab() {
    return (
      <CrystallizePlanTab
        game={game}
        player={player}
        topaasiaCard={topaasiaCard}
        topaasiaPerspective={topaasiaPerspective}
        crystallization={crystallization}
        topics={topics}
        playerParticipations={playerParticipations}
        disabled={disabled}
        onShowPlayerIdeas={(topicId) => {
          setSelectedTopic(topicId);
        }}
      />
    );
  }

  function renderNotesTab() {
    return (
      <CrystallizeNotesTab
        gameNotes={gameNotes}
        perspectives={game.perspectives}
        rounds={game.rounds}
        topaasiaCard={topaasiaCard}
        topaasiaPerspective={topaasiaPerspective}
        disabled={disabled} />
    );
  }

  function renderMobileView(t) {
    return (
      <>
        {selectedTab === "plan" && (
          <div
            key="plan"
            tabIndex={disabled ? -1 : 0}
            disabled={disabled}
            className="crystallize-view-tab crystallize-plan-tab"
          >
            { renderCrystallizePlanTab() }
          </div>
        )}
        {selectedTab === "notes" && (
          <div
            key="notes"
            tabIndex={disabled ? -1 : 0}
            disabled={disabled}
            className="crystallize-view-tab crystallize-notes-tab"
          >
            { renderNotesTab() }
          </div>
        )}
        <nav className="crystallize-nav grid-x" role="tablist">
          <div
            role="tab"
            tabIndex={disabled ? -1 : 0}
            disabled={disabled}
            onClick={() => setSelectedTab("plan")}
            onKeyPress={() => setSelectedTab("plan")}
            className={`nav-item cell small-6 ${
              selectedTab === "plan" ? "selected" : ""
            }`}
          >
            <div className="nav-item-inner">
              <AssignmentIcon />
              <span>{ t("crystallize-view.crystallize-tab-view.plan-tab") }</span>
            </div>
          </div>
          <div
            role="tab"
            tabIndex={disabled ? -1 : 0}
            disabled={disabled}
            onClick={() => setSelectedTab("notes")}
            onKeyPress={() => setSelectedTab("notes")}
            className={`nav-item cell small-6 ${
              selectedTab === "notes" ? "selected" : ""
            }`}
          >
            <div className="nav-item-inner">
              <NoteAltIcon />
              <span>{ t("crystallize-view.crystallize-tab-view.notes-tab") }</span>
            </div>
          </div>
        </nav>
      </>
    );
  }

  function renderDesktopView() {
    return (
      <div className="crystallize-tab-view-desktop-content">
        <LightContentContainer className="column-left">
          <div tabIndex={disabled ? -1 : 0} className="crystallize-view-column crystallize-plan-column">
            <div className="crystallize-plan-column-content-wrapper">
              { renderCrystallizePlanTab() }
            </div>
          </div>
        </LightContentContainer>
        <LightContentContainer className="column-right">
          <div tabIndex={disabled ? -1 : 0} className="crystallize-view-column crystallize-notes-column">
            { renderNotesTab() }
          </div>
        </LightContentContainer>
      </div>
    );
  }
};

CrystallizeTabView.propTypes = {
  topaasiaCard: PropTypes.object.isRequired,
  topaasiaPerspective: PropTypes.object.isRequired,
  crystallization: PropTypes.object.isRequired,
  topics: PropTypes.array.isRequired,
  gameNotes: PropTypes.array.isRequired,
  game: PropTypes.object.isRequired,
  player: PropTypes.object.isRequired,
  playerParticipations: PropTypes.array,
  setSelectedTopic: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  ariaHidden: PropTypes.bool,
};

export default CrystallizeTabView;
