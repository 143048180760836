import React from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-autosize-textarea/lib";
import { PulseLoader } from "react-spinners";

const FeedbackTextInput = ({
  text,
  placeholder = "",
  isDirty = false,
  onChange = () => { },
}) => (
  <div className="feedback-text-input-container">
    <TextareaAutosize
      className="feedback-text-input"
      value={text}
      rows={1}
      placeholder={placeholder}
      onChange={(e) => { onChange(e.target.value) }} />
    {isDirty && (
      <div className="dirty-indicator">
        <PulseLoader size={6} color="#555" />
      </div>
    )}
  </div>
);

FeedbackTextInput.propTypes = {
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isDirty: PropTypes.bool,
};

export default FeedbackTextInput;
