import { createAction } from "redux-actions";

const namespaces = {
  playerFeedback: "PLAYER-FEEDBACK",
};

export const get = {
  request: createAction(`${namespaces.playerFeedback}/GET/REQUEST`),
  success: createAction(`${namespaces.playerFeedback}/GET/SUCCESS`),
  error: createAction(`${namespaces.playerFeedback}/GET/ERROR`),
};

export const post = {
  request: createAction(`${namespaces.playerFeedback}/POST/REQUEST`),
  success: createAction(`${namespaces.playerFeedback}/POST/SUCCESS`),
  error: createAction(`${namespaces.playerFeedback}/POST/ERROR`),
};
