import { put, call } from "redux-saga/effects";

import { actions, api } from ".";
import { game as gameModule } from "..";

let votesExport = {};

votesExport = {
  * post(action) {
    try {
      const { cardId, candidateId } = action.payload;

      yield call(api.vote, { cardId, candidateId });
      yield call(gameModule.sagas.game.state.get);
      yield put(actions.vote.success());
    } catch (error) {
      yield put(actions.vote.error(error));

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  },
};

export const votes = votesExport;
