import React, { Component } from "react";
import PropTypes from "prop-types";

export class ViewSubject extends Component {

  render() {
    const {
      title,
      subtitle = null,
      hidden = false,
      className = "",
    } = this.props;

    return (
      <div className={`view-subject ${hidden ? "hidden" : ""} ${className}`}>
      <div className="view-subject-label">
        <span className="view-subject-title">
          { title }
        </span>
        <span className="view-subject-subtitle">
          { subtitle ? ` - ${subtitle}` : "" }
        </span>
      </div>
    </div>
    );
  }

}

ViewSubject.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hidden: PropTypes.bool,
  className: PropTypes.string,
};
