import { get as safeGet } from "lodash";
import { put, call } from "redux-saga/effects";

import * as actions from "./actions";
import * as api from "./api";

export const idea = {
  * post(action) {
    try {
      const { text } = action.payload;
      const response = yield call(api.idea.post, { text });
      yield put(actions.idea.post.success(response.data));
    } catch (error) {
      let errorPayload = { error: "unknown error" };
      let unhandled = true;

      if (safeGet(error, "response.data.error", null) === "no_active_participation") {
        errorPayload = { error: "no_active_participation" };
        unhandled = false;
      }

      yield put(actions.idea.post.error(errorPayload));

      if (process.env.NODE_ENV === "development" && unhandled) {
        throw error;
      }
    }
  },
  * delete(action) {
    try {
      const { playerIdeaId } = action.payload;
      const response = yield call(api.idea.delete, { playerIdeaId });
      yield put(actions.idea.delete.success(response.data));
    } catch (error) {
      yield put(actions.idea.delete.error(error));

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  },
  * upvote(action) {
    try {
      const { playerIdeaId } = action.payload;
      const response = yield call(api.idea.upvote, { playerIdeaId });
      yield put(actions.idea.upvote.success(response.data));
    } catch (error) {
      yield put(actions.idea.upvote.error(error));

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  },
};
