import { backendService } from "../../services";
import { fromSnakeToCamel, fromCamelToSnake } from "../shared/utils";

export const post = async ({ cardId, roundId, text }) => {
  const response = await backendService.post({
    url: `player-api/game-notes`,
    data: fromCamelToSnake({ cardId, roundId, text }),
  });

  return { data: fromSnakeToCamel(response.data) };
};
