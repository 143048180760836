import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const get = async () => {
  const response = await backendService.get({
    url: `player-api/player-feedbacks`,
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const post = async ({ score, text }) => {
  const response = await backendService.post({
    url: `player-api/player-feedbacks`,
    data: { score, text },
  });

  return { data: fromSnakeToCamel(response.data) };
};
