import { createAction } from "redux-actions";

const namespaces = {
  deck: "DECK",
};

export const get = {
  request: createAction(`${namespaces.deck}/GET/REQUEST`),
  success: createAction(`${namespaces.deck}/GET/SUCCESS`),
  error: createAction(`${namespaces.deck}/GET/ERROR`),
};
