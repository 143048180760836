/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary, { setIgnoredActions } from "@topaasia/error-boundary";
import { Translation } from "react-i18next";
import { get, pick } from "lodash";
import { backendService } from "../../services";

const ignoreActions = [
  "GAME/STATE/GET",
  "DECK/GET",
  "GAME/GET",
  "LOCATION_CHANGE",
];

setIgnoredActions(ignoreActions);

const networkErrorFilter = (error) => {
  const responseURL = get(error, "request.responseURL", "");
  const response = get(error, "request.response", {});

  try {
    if (responseURL.includes("/game/join") && JSON.parse(response).code === "invalid_game_code") {
      return false;
    }
  } catch {
    return true;
  }

  return true;
};

const ApplicationErrorBoundary = ({ store, children }) => (
  <Translation ns="components">
    {(t) => (
      <ErrorBoundary
        title={t("error-boundary.title")}
        message={t("error-boundary.message")}
        project="frontend_player"
        backendService={backendService}
        networkErrorFilter={networkErrorFilter}
        axios={backendService.getDefaultInstance()}
        getApplicationState={() => {
          const state = store.getState();
    
          return {
            game: pick(state.game.game, "id", "deckId", "deckLanguage"),
            gameState: state.game.gameState,
            meta: pick(state.game.meta, "playerId", "joinError"),
            error: state.game.error,
            status: state.game.status,
          };
        }}>
          { children }
        </ErrorBoundary>
    )}
  </Translation>
);

ApplicationErrorBoundary.propTypes = {
  store: PropTypes.any.isRequired,
  children: PropTypes.any,
};

export default ApplicationErrorBoundary;
