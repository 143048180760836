import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as actions from "./actions";

const initialState = {
  player: null,
  status: {
    patching: false,
  },
};

const player = handleActions({
  [actions.get.success]: (_, action) => ({ ...action.payload.player }),
  [actions.patch.success]: (_, action) => ({ ...action.payload.player }),
}, initialState.player);

const status = handleActions({
  [actions.patch.request]: () => ({ patching: true }),
  [actions.patch.success]: () => ({ patching: false }),
  [actions.patch.error]: () => ({ patching: false }),
}, initialState.status);

export const reducer = combineReducers({
  player,
  status,
});
