import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import PlayerIdea from "./player-idea/PlayerIdea";

import "./player-idea-list.scss";

const composeExitAnimation = (playerIdea, isDeletingIdeas) => {
  if (isDeletingIdeas.includes(playerIdea.id)) {
    return { height: 0, opacity: 0 };
  }

  return null;
};

const PlayerIdeaList = ({
  playerIdeas,
  isDeletingIdeas = [],
  mountAnimation = true,
  getPlayerIdeaActions = () => [],
}) => {
  // Caching these to state will prevent mid-animation re-renders and thus noticeable glitches.
  // This also seems to stabilize iOS Safari since there are less renders.
  const [ideas, setIdeas] = useState([]);

  useEffect(() => {
    const oldIds = ideas.map((i) => i.id);
    const newIds = playerIdeas.map((i) => i.id);

    const oldUpvotes = ideas.map((i) => i.upvotes);
    const newUpvotes = playerIdeas.map((i) => i.upvotes);

    if (!isEqual(oldIds, newIds) || !isEqual(oldUpvotes, newUpvotes)) {
      setIdeas(playerIdeas);
    }
  }, [playerIdeas]);

  return (
    <motion.ul className="player-idea-list">
      <AnimatePresence>
        {ideas.map((playerIdea, i) => (
          <motion.li
            layout
            key={playerIdea.id}
            initial={mountAnimation ? { opacity: 0, x: -100 } : false}
            animate={{ opacity: isDeletingIdeas.includes(playerIdea.id) ? 0.5 : 1, x: 0 }}
            exit={composeExitAnimation(playerIdea, isDeletingIdeas)}
            transition={{ delay: Math.sqrt(i) * 0.2 }}
            style={{ overflow: "hidden" }}>
            <PlayerIdea
              playerIdea={playerIdea}
              actions={getPlayerIdeaActions(playerIdea)} />
          </motion.li>
        ))}
      </AnimatePresence>
    </motion.ul>
  );
};

PlayerIdeaList.propTypes = {
  playerIdeas: PropTypes.array.isRequired,
  getPlayerIdeaActions: PropTypes.func,
  isDeletingIdeas: PropTypes.array,
  isUpvotingIdeas: PropTypes.array,
  mountAnimation: PropTypes.bool,
};

export default PlayerIdeaList;
