import { combineReducers } from "redux";
import { handleAction, handleActions } from "redux-actions";
import { uniqBy } from "lodash";

import { actions } from ".";
import * as playerParticipationActions from "../player-participation/actions";
import { tokenService } from "../../services";
import { gameNoteActions } from "../game-notes/actions";

const initialState = {
  game: null,
  gameState: null,
  meta: {
    code: null,
    playerId: null,
    joinError: null,
  },
  status: {
    loading: false,
    joining: false,
    joined: false,
    restoring: false,
    finishing: false,
  },
  restoredGame: {
    status: "not_checked",
    game: null,
  },
  error: {
    stateFetchError: null,
  },
};

const injectingPlayerIdeaToPlayerParticipations = (playerParticipations, playerIdea) => {
  const playerParticipation = playerParticipations.find((pp) => playerIdea.playerParticipationId === pp.id);
  const index = playerParticipation.playerIdeas.findIndex((pi) => pi.id === playerIdea.id);

  if (index === -1) {
    // Add
    playerParticipation.playerIdeas = [...playerParticipation.playerIdeas, playerIdea];
  } else {
    // Replace
    playerParticipation.playerIdeas = playerParticipation.playerIdeas.map((pi) => (
      pi.id === playerIdea.id ? playerIdea : pi
    ));
  }

  return Array.from(playerParticipations);
};

const game = handleAction(actions.get.success, (_, action) => {
  if (!action.payload) { return null; }

  return {
    ...action.payload,
  };
}, initialState.game);

const restoredGame = handleAction(actions.restore.success, (_, action) => ({
  ...action.payload,
}), initialState.restoredGame);

const gameState = handleActions({
  [actions.state.get.success]: (_, action) => {
    const { state, compareHash } = action.payload.data;

    return {
      compareHash,
      state: state.state,
      stateData: state.stateData,
      currentRoundId: state.currentRound.id,
      currentRoundPerspective: state.currentRound ? state.currentRound.perspective : null,
      topaasiaRoundId: state.topaasiaRoundId,
      enablePlayerGameNotes: state.enablePlayerGameNotes,
    };
  },
  [playerParticipationActions.idea.post.success]: (state, action) => {
    const playerIdea = action.payload;

    return {
      ...state,
      stateData: {
        ...state.stateData,
        playerParticipations: injectingPlayerIdeaToPlayerParticipations(
          state.stateData.playerParticipations, playerIdea,
        ),
      },
    };
  },
  [playerParticipationActions.idea.upvote.success]: (state, action) => {
    const playerIdea = action.payload;

    return {
      ...state,
      stateData: {
        ...state.stateData,
        playerParticipations: injectingPlayerIdeaToPlayerParticipations(
          state.stateData.playerParticipations, playerIdea,
        ),
      },
    };
  },
  [gameNoteActions.post.success]: (state, action) => {
    const { gameNote } = action.payload;

    return {
      ...state,
      stateData: {
        ...state.stateData,
        gameNotes: uniqBy([
          ...state.stateData.gameNotes,
          gameNote,
        ], "id"),
      },
    }
  },
}, initialState.gameState);

const meta = handleActions({
  [actions.meta.persist]: (_, action) => {
    const { loginToken, playerId } = action.payload;
    tokenService.set(loginToken);
    tokenService.playerId.set(playerId);
    return { loginToken, playerId, joinError: null };
  },
  [actions.meta.restore]: () => {
    const loginToken = tokenService.get();
    const playerId = tokenService.playerId.get();
    return { loginToken, playerId, joinError: null };
  },
  [actions.meta.clearAll]: () => {
    tokenService.clearAllKeys();
    return { code: null, playerId: null, joinError: null };
  },
  [actions.join.clearErrors]: (state) => ({ ...state, joinError: null }),
  [actions.join.success]: (state) => ({ ...state, joinError: null }),
  [actions.join.error]: (state, action) => ({ ...state, joinError: action.payload }),
}, initialState.meta);

const error = handleActions({
  [actions.state.get.error]: (state, action) => ({
    ...state.error,
    stateFetchError: action.payload,
  }),
  [actions.state.get.success]: (state) => ({
    ...state.error,
    stateFetchError: null,
  }),
}, initialState.error);

const status = handleActions({
  [actions.get.request]: (state) => ({ ...state, loading: true }),
  [actions.get.success]: (state) => ({ ...state, loading: false }),
  [actions.get.error]: (state) => ({ ...state, loading: false }),

  [actions.restore.request]: (state) => ({ ...state, restoring: true }),
  [actions.restore.success]: (state) => ({ ...state, restoring: false }),
  [actions.restore.error]: (state) => ({ ...state, restoring: false }),

  [actions.join.request]: (state) => ({ ...state, joining: true, joined: false }),
  [actions.join.error]: (state) => ({ ...state, joining: false, joined: false }),
  [actions.join.success]: (state) => ({ ...state, joining: false, joined: true }),

  [actions.finish.request]: (state) => ({ ...state, finishing: true }),
  [actions.finish.error]: (state) => ({ ...state, finishing: false }),
  [actions.finish.success]: (state) => ({ ...state, finishing: false }),
}, initialState.status);

export const reducer = combineReducers({
  game,
  restoredGame,
  gameState,
  meta,
  error,
  status,
});
