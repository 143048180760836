import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { shuffle } from "lodash";

import { actions } from ".";

const initialState = {
  cards: {
    all: null,
    dealt: null,
    discarded: null,
  },
  selection: {
    cards: [],
  },
  played: {
    cards: [],
  },
  status: {
    isDealing: false,
    isDealt: false,
    isPlaying: false,
    playingCardId: null,
    undoingPlay: false,
    playError: null,
  },
};

const cards = handleActions(
  {
    [actions.deal.begin]: () => ({ all: null, dealt: null, discarded: null }),
    [actions.deal.end]: (_, action) => ({
      all: action.payload.all,
      dealt: action.payload.dealt,
      discarded: [],
    }),

    [actions.change.request]: (state, action) => {
      const changeCards = action.payload;
      const { all, dealt, discarded } = state;
      const changeCardsObjects = changeCards.map((cc) => all.find((c) => c.id === cc));

      // Only deal from the pool that does not contain the same (changed) cards.
      const notDealable = dealt.concat(discarded);
      let pool = shuffle(
        state.all.filter((card) => !notDealable.map((d) => d.id).includes(card.id)),
      );
      let reshuffled = false;

      if (pool.length < changeCards.length) {
        // Shuffle and insert the discard pile back in
        pool = pool.concat(shuffle(discarded));

        if (pool.length < changeCards.length) {
          // If the discarded pile is not enough, shuffle in the current hand as well
          pool = pool.concat(shuffle(changeCardsObjects));
        }
        reshuffled = true;
      }

      const changedIndices = [];
      const newDealt = dealt.map((card, i) => {
        if (changeCards.includes(card.id)) {
          changedIndices.push(i);
          return pool.shift();
        }

        return card;
      });

      // If the hand has same cards in same positions, shuffle them around
      // to change their positions.
      changedIndices.forEach((index, i) => {
        if (newDealt[index].id === dealt[index].id) {
          const nextIndex = changedIndices[(i + 1) % changedIndices.length];

          const currentItem = newDealt[index];
          newDealt[index] = newDealt[nextIndex];
          newDealt[nextIndex] = currentItem;
        }
      });

      return {
        all: state.all,
        dealt: newDealt,
        discarded: reshuffled ? [] : discarded.concat(changeCardsObjects),
      };
    },
  },
  initialState.cards,
);

const selection = handleActions(
  {
    [actions.selection.select]: (state, action) => {
      const cardId = action.payload;
      const newSelection = [cardId, ...state.cards];
      return { cards: newSelection };
    },
    [actions.selection.selectMultiple]: (state, action) => {
      const cardIds = action.payload;
      return { cards: cardIds };
    },
    [actions.selection.deselect]: (state, action) => {
      const cardId = action.payload;
      const newSelection = state.cards.filter((id) => id !== cardId);
      return { cards: newSelection };
    },
    [actions.selection.clear]: () => ({ cards: [] }),
  },
  initialState.selection,
);

const played = handleActions(
  {
    [actions.play.success]: (state, action) => ({
      ...state,
      cards: [...state.cards, action.payload.cardId],
    }),
    [actions.played.success]: (state, action) => ({
      ...state,
      cards: [...state.cards, ...action.payload.candidates.map((c) => c.cardId)],
    }),
    [actions.played.clear]: (state) => ({ ...state, cards: [] }),
    [actions.play.undo.success]: (state, action) => ({
      ...state,
      cards: state.cards.filter((cId) => cId !== action.payload.cardId),
    }),
  },
  initialState.played,
);

const status = handleActions(
  {
    [actions.deal.begin]: (state) => ({
      ...state,
      isDealing: true,
      isDealt: false,
      playError: null,
    }),
    [actions.deal.end]: (state) => ({
      ...state,
      isDealing: false,
      isDealt: true,
    }),
    [actions.play.request]: (state, action) => ({
      ...state,
      isPlaying: true,
      playingCardId: action.payload,
    }),
    [actions.play.success]: (state) => ({
      ...state,
      isPlaying: false,
      playingCardId: null,
      playError: null,
    }),
    [actions.play.error]: (state, action) => ({
      ...state,
      isPlaying: false,
      playingCardId: null,
      playError: action.payload,
    }),
    [actions.play.undo.request]: (state) => ({
      ...state,
      undoingPlay: true,
    }),
    [actions.play.undo.success]: (state) => ({
      ...state,
      undoingPlay: false,
    }),
    [actions.play.undo.error]: (state) => ({
      ...state,
      undoingPlay: false,
    }),
  },
  initialState.status,
);

export const reducer = combineReducers({
  cards,
  selection,
  played,
  status,
});
