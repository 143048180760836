import React from "react";
import PropTypes from "prop-types";

// Icons
import diamonds from "../../../assets/images/diamonds.png";
import hearts from "../../../assets/images/hearts.png";
import spades from "../../../assets/images/spades.png";
import clubs from "../../../assets/images/clubs.png";

const suitConfig = {
  diamonds: {
    icon: diamonds,
  },
  hearts: {
    icon: hearts,
  },
  spades: {
    icon: spades,
  },
  clubs: {
    icon: clubs,
  },
};

const SuitIcon = ({ suit, size }) => {
  const { icon } = suitConfig[suit];

  const iconStyle = {
    width: size,
    height: size,
    backgroundImage: `url(${icon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    display: "inline-block",
  };
  return <span className="suit-icon" style={iconStyle} />;
};

SuitIcon.propTypes = {
  suit: PropTypes.oneOf(["diamonds", "hearts", "spades", "clubs"]).isRequired,
  size: PropTypes.number.isRequired,
};

export default SuitIcon;
