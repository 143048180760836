import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

import { ModalMessage } from "../../../../components";
import StateStartModal from "../../common/StateStartModal";
import PickCandidateTopaasiaModeContent from "./topaasia/PickCandidateTopaasiaModeContent";
import PickCandidateOneOnOneModeContent from "./one-on-one/PickCandidateOneOnOneModeContent";

const stateToMessages = ({ state, t }) => ({
  title: t(`pick-candidate-view.state-messages.${state}.title`),
  message: t(`pick-candidate-view.state-messages.${state}.message`),
});

class PickCandidateView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stateStartModalDismissed: false,
      stateStartModalGone: false,
    };
  }

  render() {
    if (!this.props.gameState) {
      return <div />;
    }

    const { gameState, gameSubject, t, isPlaying, playError, game } = this.props;
    const { gameMode } = game;

    const { state } = gameState;
    const shouldShowModal = state !== "rounds" || !this.state.stateStartModalDismissed;
    const isBoardDisabled = isPlaying || shouldShowModal;
    const message = stateToMessages({ state, t });

    if (!this.state.stateStartModalGone) {
      return this.renderStateStartModal();
    }

    return (
      <motion.main
        className="pick-candidate-view board-transition-canvas"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75 }}>
        {gameMode === "topaasia" && (
          <PickCandidateTopaasiaModeContent
            isDisabled={isBoardDisabled}
            gameSubject={gameSubject}
          />
        )}
        {gameMode === "one_on_one" && (
          <PickCandidateOneOnOneModeContent
            isDisabled={isBoardDisabled}
            gameSubject={gameSubject}
          />
        )}

        <ModalMessage title={message.title} message={message.message} visible={shouldShowModal} />

        <AnimatePresence>
          {playError && (
            <motion.div
              className="board-error-toast"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration: 0.5 }}>
              {t("pick-candidate-view.errors.play-error")}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.main>
    );
  }

  shouldRenderStateStartModal() {
    return !this.state.stateStartModalGone && !this.props.playedCard;
  }

  renderStateStartModal() {
    const { t, game, gameState } = this.props;
    const perspective = gameState.currentRoundPerspective;
    const perspectiveTitle = perspective.title[game.deckLanguage];

    const gameModePrefix = game.gameMode.replaceAll("_", "-");
    const dialogPrefix = `pick-candidate-view.new-round-dialog.${gameModePrefix}`;

    return (
      <StateStartModal
        title={
          <Trans
            ns="views"
            i18nKey={`${dialogPrefix}.title`}
            values={{ perspective: perspectiveTitle }}
            components={{ strong: <strong className="accent-color" /> }}
          />
        }
        message={
          <Trans
            ns="views"
            i18nKey={`${dialogPrefix}.message`}
            values={{ perspective: perspectiveTitle }}
            components={{ strong: <strong className="accent-color" /> }}
          />
        }
        dismissButton={t(`${dialogPrefix}.button`)}
        visible={!this.state.stateStartModalDismissed}
        boardClass="pick-choice-view-board"
        onDismiss={() => {
          this.onStateStartModalDismiss();
        }}
      />
    );
  }

  onStateStartModalDismiss() {
    this.setState({ stateStartModalDismissed: true });

    setTimeout(() => {
      this.setState({ stateStartModalGone: true });
    }, 750);
  }
}

PickCandidateView.propTypes = {
  isDealing: PropTypes.bool,
  cards: PropTypes.array,
  selection: PropTypes.array,
  playedCard: PropTypes.string,
  playError: PropTypes.object,
  isPlaying: PropTypes.bool,
  game: PropTypes.object,
  gameState: PropTypes.object,
  player: PropTypes.object,
  gameSubject: PropTypes.string,
  location: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isDealing: state.hand.status.isDealing,
  cards: state.hand.cards.dealt,
  selection: state.hand.selection.cards,
  playedCard: state.hand.playedCard,
  isPlaying: state.hand.status.isPlaying,
  playError: state.hand.status.playError,
  game: state.game.game,
  gameState: state.game.gameState,
  gameCode: state.game.meta.code,
  player: state.player.player,
});

export default compose(withTranslation("views"), connect(mapStateToProps))(PickCandidateView);
