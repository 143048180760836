import React from "react";
import PropTypes from "prop-types";
import AnimatedErrorLabel from "../../components/form/AnimatedErrorLabel";

const JoinViewInputContainer = ({
  label,
  labelHelp = "",
  inputId = "",
  inputType = "text",
  inputPlaceholder = "",
  inputClassName = "",
  maxLength,
  value = "",
  isValid = true,
  isTouched = true,
  errorLabel = "",
  onChange = () => {},
  onBlur = () => {},
  inputProps = {},
}) => {
  const visibleIsValid = !isTouched || isValid;
  let inputValidityClass = "";

  if (isTouched) {
    inputValidityClass = isValid ? "input-valid" : "input-invalid";
  }

  return (
    <div className="join-input-container grid-x grid-margin-x">
      <div className="cell small-12 join-input-label-container">
        <label htmlFor={inputId}>
          { label }
        </label>
        { labelHelp.length > 0 && (
          <p className="label-help">
            {labelHelp}
          </p>
        )}
      </div>
      <div className="cell small-12 input-field">
        <input id={inputId} type={inputType}
          className={`${inputClassName} ${inputValidityClass}`}
          placeholder={inputPlaceholder}
          aria-required="true"
          aria-invalid={!visibleIsValid}
          value={value}
          maxLength={maxLength}
          onChange={(e) => { onChange(e); }}
          onBlur={(e) => { onBlur(e); }}
          { ...inputProps } />
        <AnimatedErrorLabel
          isValid={visibleIsValid}
          label={errorLabel} />
      </div>
    </div>
  );
};

JoinViewInputContainer.propTypes = {
  label: PropTypes.string.isRequired,
  labelHelp: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  inputClassName: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  isValid: PropTypes.bool,
  isTouched: PropTypes.bool,
  errorLabel: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default JoinViewInputContainer;
