import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import {
  deck,
  hand,
  game,
  playerVote,
} from "./modules";

import { reducer as playerReducer } from "./modules/player";
import { reducer as roundsReducer } from "./modules/rounds/reducers";
import { reducer as playerParticipationReducer } from "./modules/player-participation/reducers";
import { reducer as playerFeedbackReducer } from "./modules/player-feedback";
import { reducer as gameNoteReducer } from "./modules/game-notes/reducers";

export const configureReducers = (history) => combineReducers({
  deck: deck.reducer,
  hand: hand.reducer,
  game: game.reducer,
  rounds: roundsReducer,
  player: playerReducer,
  playerVote: playerVote.reducer,
  playerParticipation: playerParticipationReducer,
  playerFeedback: playerFeedbackReducer,
  gameNotes: gameNoteReducer,
  router: connectRouter(history),
});
