import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { Button } from "../../components/button/Button";

import JoinViewInputContainer from "./JoinViewInputContainer";

const JoinViewForm = ({
  name = "",
  inputs,
  submitButton = {},
}) => (
  <form className="join-form" aria-label={name} name={name}>
    <div className="join-form-inputs-container">
      {inputs.map(({
        id,
        type,
        label,
        labelHelp,
        errorLabel,
        placeholder,
        value,
        isValid,
        isTouched,
        inputProps,
        maxLength,
        onChange,
        onBlur,
      }) => (
        <JoinViewInputContainer
          key={id}
          inputId={id}
          inputType={type}
          label={label}
          labelHelp={labelHelp}
          errorLabel={errorLabel}
          inputPlaceholder={placeholder}
          maxLength={maxLength}
          value={value}
          isValid={isValid}
          isTouched={isTouched}
          onChange={(e) => { onChange(id, e.target.value); }}
          onBlur={() => { onBlur(id); }}
          inputProps={inputProps} />
      ))}
    </div>

    <motion.div
      variants={{
        disabled: { opacity: 0, translateY: 50 },
        enabled: { opacity: 1, translateY: 0 },
      }}
      initial="disabled"
      exit="disabled"
      animate={"enabled"}>
      <div className="join-game-action">
        <Button
          type="submit"
          disabled={submitButton.disabled}
          content={submitButton.content}
          onClick={submitButton.onClick} />
      </div>
    </motion.div>
  </form>
);

JoinViewForm.propTypes = {
  name: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelHelp: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    isValid: PropTypes.bool,
    isTouched: PropTypes.bool,
    inputProps: PropTypes.object,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  })).isRequired,
  submitButton: PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default JoinViewForm;
