import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import CrystallizeView from "./crystallize/CrystallizeView";
import PickCandidateView from "./rounds/pick-candidate/PickCandidateView";
import PickChoiceView from "./rounds/pick-choice/PickChoiceView";
import PickTopaasiaView from "./pick-topaasia/PickTopaasiaView";
import PreroundView from "./PreroundView";
import WaitPlayersView from "./waitplayers/WaitPlayersView";
import CompleteView from "./complete/CompleteView";

import PollingErrorIndicator from "../../components/decorations/PollingErrorIndicator";

import { deck, game as gameModule } from "../../modules";
import FullscreenPuffLoader from "../../components/status/FullscreenPuffLoader";

const wrappedView = (element) => (
  <div>
    {element}
    <aside>
      <PollingErrorIndicator />
    </aside>
  </div>
);

class PlayView extends Component {
  componentDidMount() {
    this.props.dispatch(deck.actions.get.request());
    this.props.dispatch(gameModule.actions.get.request());

    // Utilize the visibility API for starting and stopping unnecessary polling.
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.props.dispatch(gameModule.actions.state.refresh.stop());
      } else {
        this.props.dispatch(gameModule.actions.state.refresh.request());
      }
    });
  }

  render() {
    const { game, gameState, player } = this.props;
    if (!gameState || !player) {
      return <FullscreenPuffLoader />;
    }

    const gameSubject = game.subject;

    const { substate } = gameState.stateData;

    if (gameState.state === "waitplayers") {
      return wrappedView(<WaitPlayersView gameSubject={gameSubject} />);
    }
    if (gameState.state === "preround") {
      return wrappedView(<PreroundView gameSubject={gameSubject} />);
    }
    if (gameState.state === "rounds" && substate === "pick-candidates") {
      return wrappedView(<PickCandidateView gameSubject={gameSubject} />);
    }
    if (gameState.state === "rounds" && substate === "pick-choice") {
      return wrappedView(<PickChoiceView gameSubject={gameSubject} />);
    }
    if (gameState.state === "pick-topaasia") {
      return wrappedView(<PickTopaasiaView gameSubject={gameSubject} />);
    }
    if (gameState.state === "crystallize") {
      return wrappedView(<CrystallizeView gameSubject={gameSubject} />);
    }
    if (gameState.state === "complete") {
      return wrappedView(<CompleteView />);
    }

    throw new Error(`Unknown state: '${gameState.state}', substate: '${substate}'`);
  }
}

PlayView.propTypes = {
  isDealing: PropTypes.bool,
  cards: PropTypes.array,
  selection: PropTypes.array,
  playedCard: PropTypes.string,
  playError: PropTypes.object,
  player: PropTypes.object,
  game: PropTypes.object,
  gameState: PropTypes.object,
  location: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  game: state.game.game,
  player: state.player.player,
  gameState: state.game.gameState,
});

export default compose(withTranslation("views"), connect(mapStateToProps))(PlayView);
