import { put, call } from "redux-saga/effects";

import * as actions from "./actions";
import * as api from "./api";

export const playerFeedback = {
  * get() {
    try {
      const response = yield call(api.get);
      yield put(actions.get.success(response.data));
    } catch (error) {
      yield put(actions.get.error(error));

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  },
  * post(action) {
    try {
      const { score, text } = action.payload;

      const response = yield call(api.post, { score, text });
      yield put(actions.post.success(response.data));
    } catch (error) {
      yield put(actions.post.error(error));

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  },
};
