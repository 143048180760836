import React from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";

const AnimatedErrorLabel = ({ label, isValid }) => (
  <AnimatePresence>
    {!isValid && (
      <motion.span
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
        className="error-label"
        role="alert">
        {label}
      </motion.span>
    )}
  </AnimatePresence>
);

AnimatedErrorLabel.propTypes = {
  isValid: PropTypes.bool,
  label: PropTypes.string,
};

export default AnimatedErrorLabel;
