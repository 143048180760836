import { all, takeLatest } from "redux-saga/effects";
import { deck, hand, game, playerVote } from "./modules";
import * as playerModule from "./modules/player";
import * as playerFeedbackModule from "./modules/player-feedback";

import playerParticipation from "./modules/player-participation";
import { gameNoteActions } from "./modules/game-notes/actions";
import { gameNoteSagas } from "./modules/game-notes/sagas";

export function* sagas() {
  yield all([
    takeLatest(hand.actions.deal.request, hand.sagas.deck.deal),
    takeLatest(hand.actions.play.request, hand.sagas.play.post),
    takeLatest(hand.actions.play.undo.request, hand.sagas.play.undo),
    takeLatest(hand.actions.played.request, hand.sagas.played.get),
    takeLatest(deck.actions.get.request, deck.sagas.deck.get),
    takeLatest(game.actions.get.request, game.sagas.game.get),
    takeLatest(game.actions.restore.request, game.sagas.game.restore),
    takeLatest(game.actions.join.submit, game.sagas.game.join),
    takeLatest(game.actions.finish.request, game.sagas.game.finish),
    takeLatest(playerModule.actions.get.request, playerModule.sagas.player.get),
    takeLatest(playerModule.actions.patch.request, playerModule.sagas.player.patch),
    takeLatest(playerVote.actions.vote.request, playerVote.sagas.votes.post),
    takeLatest(playerFeedbackModule.actions.get.request, playerFeedbackModule.sagas.playerFeedback.get),
    takeLatest(playerFeedbackModule.actions.post.request, playerFeedbackModule.sagas.playerFeedback.post),

    takeLatest(gameNoteActions.post.request, gameNoteSagas.post),

    takeLatest(
      playerParticipation.actions.idea.post.request,
      playerParticipation.sagas.idea.post,
    ),

    takeLatest(
      playerParticipation.actions.idea.delete.request,
      playerParticipation.sagas.idea.delete,
    ),

    takeLatest(
      playerParticipation.actions.idea.upvote.request,
      playerParticipation.sagas.idea.upvote,
    ),

    game.sagas.pollDriver(),
  ]);
}
