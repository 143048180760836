import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const idea = {
  post: async ({ text }) => {
    const url = `player-api/player-ideas`;

    const response = await backendService.post({
      url,
      data: { text } });

    return { data: fromSnakeToCamel(response.data) };
  },
  delete: async ({ playerIdeaId }) => {
    const url = `player-api/player-ideas/${playerIdeaId}`;

    const response = await backendService.destroy({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
  upvote: async ({ playerIdeaId }) => {
    const url = `player-api/player-ideas/${playerIdeaId}/upvote`;

    const response = await backendService.post({
      url,
      data: {
        player_idea_id: playerIdeaId,
      },
    });

    return { data: fromSnakeToCamel(response.data) };
  },
};
