import { createAction } from "redux-actions";

const namespaces = {
  player: "PLAYER",
};

export const get = {
  request: createAction(`${namespaces.player}/GET/REQUEST`),
  success: createAction(`${namespaces.player}/GET/SUCCESS`),
  error: createAction(`${namespaces.player}/GET/ERROR`),
};

export const patch = {
  request: createAction(`${namespaces.player}/PATCH/REQUEST`),
  success: createAction(`${namespaces.player}/PATCH/SUCCESS`),
  error: createAction(`${namespaces.player}/PATCH/ERROR`),
};
