import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import i18next from "i18next";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import { PuffLoader } from "react-spinners";

import { game } from "../../modules";

import JoinNewGameContent from "./join-new-game-content/JoinNewGameContent";
import JoinExistingGameContent from "./join-existing-game-content/JoinExistingGameContent";

import "./join-view.scss";
import TopaasiaHexDecoration from "../../components/decorations/topaasia-hex-decoration/TopaasiaHexDecoration";

class JoinView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gameCode: "",
      gameCodeTouched: false,
      paramError: null,
      isJoiningNewGame: false,
    };
  }

  componentDidMount() {
    i18next.changeLanguage(navigator.language.split("-")[0] || "en");
    this.props.dispatch(game.actions.state.refresh.stop());
    this.props.dispatch(game.actions.restore.request());

    this.setQueryPropsIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const prevRestoreStatus = prevProps.restoredGame.status;
    const newRestoreStatus = this.props.restoredGame.status;
    if (prevRestoreStatus === "not_checked" && newRestoreStatus === "joined_game_available") {
      if (this.state.gameCode === this.props.restoredGame.game.gameCode) {
        this.setState({ isJoiningNewGame: false });
      }
    }
  }

  render() {
    const {
      t,
      isLoaded,
      joinError,
      isJoining,
      restoredGame,
    } = this.props;

    if (!isLoaded) {
      return (
        <motion.div
          transition={{ delay: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{
            position: "fixed",
            width: "100%", height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}>
          <PuffLoader color="white" />
        </motion.div>
      );
    }

    const { isJoiningNewGame } = this.state;

    return (
      <div role="main" className="join-view">
        <Helmet>
          <meta name="theme-color" content="#222222" />
          <html className="join-view-html"></html>
        </Helmet>
        <div className="join-dialog">
          {this.state.paramError && (
            <div className="param-error-container">
              {t("join-view.game-code-redirect-error")}
            </div>
          )}

          <motion.div
            className="topaasia-decoration-container"
            initial={{ opacity: 0, rotateY: -80, y: -50, delay: 0.5 }}
            aria-hidden={true}
            animate={{
              opacity: 1,
              rotateY: 0.01,
              y: 0,
              transition: { type: "spring", damping: 30, mass: 1, delay: 0.2 },
            }}
            style={{ display: "inline-block" }} >
            <TopaasiaHexDecoration />
          </motion.div>

          <JoinExistingGameContent
            restoredGame={restoredGame}
            isJoiningNewGame={isJoiningNewGame}
            onJoinNewGame={() => {
              this.setState({ isJoiningNewGame: true });
            }}
            onCancelJoinNewGame={() => {
              this.setState({ isJoiningNewGame: false });
            }}
            onJoinExistingGame={() => {
              this.onJoinExistingGame();
            }} />

          {this.state.isJoiningNewGame && (
            <JoinNewGameContent
              gameCode={this.state.gameCode}
              onGameCodeChanged={(gameCode) => this.setState({ gameCode })}
              gameCodeTouched={this.state.gameCodeTouched}
              onGameCodeTouched={() => this.setState({ gameCodeTouched: true })}
              onJoinGame={(gameCode) => this.onJoinNewGame(gameCode)}
              onClearErrors={() => this.props.dispatch(game.actions.join.clearErrors())}
              isJoining={isJoining}
              joinError={joinError} />
          )}
        </div>
      </div>
    );
  }

  onJoinNewGame(gameCode) {
    this.props.dispatch(game.actions.join.submit({
      gameCode,
    }));
  }

  onJoinExistingGame() {
    this.props.history.push("/play");
  }

  setQueryPropsIfNeeded() {
    const { search, state } = this.props.location;

    if (search && search !== "") {
      const params = search.replace("?", "").split("&");

      params.forEach((p) => {
        const entry = p.split("=");
        if (entry[0] === "lang") {
          i18next.changeLanguage(entry[1]);
        } else if (entry[0] === "game-code") {
          const gameCode = entry[1].toUpperCase();
          this.setState({
            gameCode,
            gameCodeTouched: true,
            isJoiningNewGame: true,
          });
        }
      });

      this.props.history.replace("/join");
    }

    if (state && state.redirectReason === "game_code_expired") {
      this.setState({ paramError: "game_code_invalid" });
      window.history.replaceState(null, "");
    }
  }
}

JoinView.propTypes = {
  dispatch: PropTypes.func,
  isJoining: PropTypes.bool.isRequired,
  isJoined: PropTypes.bool.isRequired,
  joinError: PropTypes.string,
  isLoaded: PropTypes.bool,
  restoredGame: PropTypes.shape({
    status: PropTypes.oneOf([
      "not_checked",
      "not_authorized",
      "joined_game_available",
      "network_error",
    ]),
    game: PropTypes.object,
  }).isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object,
  history: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { restoredGame } = state.game;
  const isLoaded = restoredGame.status !== "not_checked";

  return {
    isJoining: state.game.status.joining,
    isJoined: state.game.status.joined,
    joinError: state.game.meta.joinError,
    restoredGame,
    isLoaded,
  };
};

export default compose(
  withTranslation("views"),
  connect(mapStateToProps),
  withRouter,
)(JoinView);
