import React from "react";
import PropTypes from "prop-types";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import { get } from "lodash";
import { Translation } from "react-i18next";

import IconButton from "../../../../components/button/icon-button/IconButton";
import { getCurrentLanguage } from "../../../../modules/shared/utils";

import "./finding.scss";

const Finding = ({
  finding,
  topic,
  index,
  showActions = true,
  onShowPlayerIdeas,
  disabled = false,
}) => {
  const report = get(finding, "report", "");

  return (
    <Translation ns="components">
      {(t) => (
        <div className="finding">
          <h3 className="finding-title">
            { index + 1 }.&nbsp;
            { topic.title[getCurrentLanguage()] }
          </h3>
          { showActions && (
            <div className="finding-actions">
              <IconButton
                label={t("finding.player-ideas")}
                icon={<LightbulbIcon />}
                type="subtle"
                size="small"
                disabled={disabled}
                onClick={() => {
                  onShowPlayerIdeas(topic.id);
                }} />
            </div>
          )}
          <p className={`finding-report ${report.length === 0 ? "empty-report" : ""}`}>
            { report.length > 0 ? report : t("finding.empty-plan") }
          </p>
        </div>
      )}
    </Translation>
  );
};

Finding.propTypes = {
  finding: PropTypes.object,
  topic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.object,
    customTitle: PropTypes.string,
  }).isRequired,
  showActions: PropTypes.bool,
  onShowPlayerIdeas: PropTypes.func,
  index: PropTypes.number,
  disabled: PropTypes.bool,
};

export default Finding;
