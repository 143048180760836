import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const get = async () => {
  const response = await backendService.get({ url: `/player-api/game` });

  return { data: fromSnakeToCamel(response.data.game) };
};

export const join = async ({ gameCode }) => {
  const requestData = {
    game_code: gameCode,
  };

  const response = await backendService.post({ url: `player-api/game/join`, data: requestData });

  return { data: fromSnakeToCamel(response.data) };
};

export const finish = async ({ emailAddress }) => {
  const response = await backendService.post({
    url: `player-api/game/finish`,
    data: {
      email: emailAddress,
    },
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const state = {
  get: async ({ compareHash = null }) => {
    let url = `/player-api/game/state`;

    if (compareHash !== null) {
      url = `${url}?compare_hash=${compareHash}`;
    }

    const response = await backendService.get({ url });

    return {
      data: fromSnakeToCamel(response.data),
    };
  },
};
