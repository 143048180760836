import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import PlayerAvatar from "@topaasia/player-avatar";
import { Helmet } from "react-helmet";

import * as playerModule from "../../../modules/player";

import { getCurrentLanguage, useViewportSize } from "../../../modules/shared/utils";
import SelfInfoContainer from "./self-info-container/SelfInfoContainer";

import "./wait-players-view.scss";
import LightContentContainer from "../../../components/layout/LightContentContainer";
import TopaasiaHexDecoration from "../../../components/decorations/topaasia-hex-decoration/TopaasiaHexDecoration";

const WaitPlayersView = ({ gameSubject }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("views");
  const [vw, vh] = useViewportSize();

  const gameInfoRef = useRef();
  const [gameInfoMinHeight, setGameInfoMinHeight] = useState(0);

  const game = useSelector((state) => state.game.game);
  const { gameMode } = game;
  const selfPlayer = useSelector((state) => state.player.player);
  const playerId = useSelector((state) => state.game.meta.playerId);
  const players = useSelector((state) => state.game.gameState.stateData.players);

  const otherPlayers = sortBy(
    players.filter((p) => p.id !== playerId),
    "createdAt",
  ).reverse();

  const onPatchPlayerName = (newValue) => {
    if (newValue.trim().length === 0) {
      return;
    }

    dispatch(playerModule.actions.patch.request({ name: newValue.trim() }));
  };

  const title = t(`pick-candidate-view.state-messages.waitplayers.title`);
  const message = t(`pick-candidate-view.state-messages.waitplayers.message`);

  const gameSubjectClass = gameSubject.length > 100 ? "long-subject" : "";

  useEffect(() => {
    if (gameInfoRef.current) {
      if (vw <= 500) {
        const bbox = gameInfoRef.current.getBoundingClientRect();
        const minHeight = vh - bbox.top;
        setGameInfoMinHeight(minHeight);
      } else {
        setGameInfoMinHeight(0);
      }
    }
  }, [vw, vh]);

  return (
    <div className="waitplayers-view">
      <Helmet>
        <meta name="theme-color" content="#222222" />
        <html className="waitplayers-view-html"></html>
      </Helmet>
      <div className="waitplayers-board">
        <div className="waitplayers-state-container">
          <div className="waitplayers-state-content">
            <main
              className="waitplayers-main-content"
              aria-labelledby="waitplayers-title">
              <div
                className="topaasia-decoration-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 20,
                }}>
                <TopaasiaHexDecoration />
              </div>
              <LightContentContainer
                contentRef={gameInfoRef}
                style={{
                  padding: 20,
                  minHeight: gameInfoMinHeight - 40,
                }}>
                <h1
                  id="waitplayers-title"
                  className="waitplayers-title"
                  style={{ display: "none" }}>
                  {title}
                </h1>
                {gameMode === "topaasia" && <h2 className="waitplayers-subtitle">{message}</h2>}
                <div className={`game-subject ${gameSubjectClass}`}>
                  <div
                    className="game-subject-label"
                    aria-label={t("waitplayers-view.aria.game-subject")}>
                    {game.subject}
                  </div>
                  <div className="deck-info" aria-label={t("waitplayers-view.aria.deck-info")}>
                    {game.deckTitle}
                  </div>
                  <div
                    className="perspectives-info"
                    aria-label={t("waitplayers-view.aria.selected-perspectives")}>
                    {game.perspectives.map((p) => p.title[getCurrentLanguage()]).join(", ")}
                  </div>
                </div>

                {selfPlayer && (
                  <SelfInfoContainer
                    selfPlayer={selfPlayer}
                    onChangePlayerName={(newValue) => onPatchPlayerName(newValue)}
                  />
                )}

                {gameMode === "topaasia" && (
                  <>
                    <h2 id="joined-players-title" className="joined-players-title">
                      {t("waitplayers-view.joined-players")}
                    </h2>
                    {otherPlayers.length === 0 && (
                      <p className="no-other-players">{t("waitplayers-view.you-are-first")}</p>
                    )}
                    <ul className="waitplayers-player-ul" aria-labelledby="joined-players-title">
                      {otherPlayers.map((player) => (
                        <li className="waitplayers-player-li" key={player.id}>
                          <PlayerAvatar size={60} type={player.avatar} playerColor={player.color} />
                          <span className="player-email-address">{player.name}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </LightContentContainer>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

WaitPlayersView.propTypes = {
  game: PropTypes.object,
  gameState: PropTypes.object,
  gameMeta: PropTypes.shape({
    playerId: PropTypes.string.isRequired,
  }),
  isPatchingName: PropTypes.bool,
  gameSubject: PropTypes.string,
};

export default WaitPlayersView;
