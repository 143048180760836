import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Board from "../../components/board/Board";

import {
  ModalMessage,
} from "../../components";

class PreroundView extends Component {

  render() {
    const { gameSubject, t } = this.props;

    const title = t(`pick-candidate-view.state-messages.preround.title`);
    const message = t(`pick-candidate-view.state-messages.preround.message`);

    return (
      <div>
        <Board isDisabled={true}>
          <h1 className="game-subject">
            {gameSubject}
          </h1>

          <ModalMessage
            title={title}
            message={message}
            visible={true} />
        </Board>
      </div>
    );
  }

}

PreroundView.propTypes = {
  game: PropTypes.object,
  gameState: PropTypes.object,
  gameSubject: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  game: state.game.game,
  gameState: state.game.gameState,
  gameCode: state.game.meta.code,
});

export default compose(
  withTranslation("views"),
  connect(mapStateToProps),
)(PreroundView);
