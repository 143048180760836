import React, { useRef } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import "./action-bar.scss";

const ActionBar = ({ buttons = [], children, contentRef = null, isHidden = false }) => {
  const mainRef = useRef();

  const buttonElements = buttons.map((button, i) => (
    <motion.div
      key={button.key || i}
      className="button-motion"
      initial={{ opacity: 0, x: i < buttons.length / 2 ? 100 : -100 }}
      animate={{ opacity: button.disabled ? 0.5 : 1, x: 0 }}
      exit={{ opacity: 0 }}>
      <button
        tabIndex={0}
        disabled={button.disabled}
        onClick={(e) => button.onClick && !button.disabled && button.onClick(e)}>
        <div
          className="button-icon"
          style={{
            backgroundImage: `url(${button.icon})`,
          }}></div>
        <span>{button.title}</span>
      </button>
    </motion.div>
  ));

  return (
    <>
      <div className="notch-padding"></div>
      <div
        className={`card-actions-container ${buttonElements.length === 0 ? "empty" : ""}`}
        ref={(el) => {
          if (contentRef) {
            // eslint-disable-next-line no-param-reassign
            contentRef.current = el;
          }
          mainRef.current = el;
        }}
        style={{
          opacity: isHidden || !buttonElements.length ? 0 : 1,
        }}>
        {buttonElements}
        {children}
      </div>
    </>
  );
};

ActionBar.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      icon: PropTypes.any,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  children: PropTypes.any,
  isHidden: PropTypes.bool,
  contentRef: PropTypes.any,
};

export default ActionBar;
