import { render } from "react-dom";
import React from "react";
import { setIgnoredActions } from "@topaasia/error-boundary";

import { Application } from "./Application";
import { configureStore, history } from "./store";

const container = document.getElementById("application");
const store = configureStore();

setIgnoredActions([
  "GAME/STATE/GET",
  "DECK/GET",
  "GAME/GET",
  "LOCATION_CHANGE",
]);

render(<Application history={history} store={store} />, container);
