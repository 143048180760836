import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { PuffLoader } from "react-spinners";

const FullscreenPuffLoader = ({ delay = 0.5 }) => (
  <motion.div
    transition={{ delay }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    style={{
      position: "fixed",
      width: "100%", height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
    }}>
    <PuffLoader color="white" />
  </motion.div>
);

FullscreenPuffLoader.propTypes = {
  delay: PropTypes.number,
}

export default FullscreenPuffLoader;
