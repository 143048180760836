import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { gameNoteActions } from "./actions";

const initialState = {
  collections: {
    byId: {},
  },
  status: {
    loading: false,
    posting: false,
  },
};

const collections = handleActions(
  {
    [gameNoteActions.post.success]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.gameNote.id]: action.payload.gameNote,
      },
    }),
  },
  initialState.collections,
);

const status = handleActions(
  {
    [gameNoteActions.post.request]: (state) => ({ ...state, posting: true }),
    [gameNoteActions.post.success]: (state) => ({ ...state, posting: false }),
    [gameNoteActions.post.error]: (state) => ({ ...state, posting: false }),
  },
  initialState.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
