import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const get = async () => {
  const url = `/player-api/player`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const patch = async ({ name }) => {
  const url = `/player-api/player`;

  const response = await backendService.patch({
    url,
    data: { name },
  });

  return { data: fromSnakeToCamel(response.data) };
};
