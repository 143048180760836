import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import { Translation } from "react-i18next";
import PlayerAvatar from "@topaasia/player-avatar";

import Finding from "../finding/Finding";
import ActionPlan from "../action-plan/ActionPlan";

import "./crystallize-plan-tab.scss";
import LightContentContainer from "../../../../components/layout/LightContentContainer";

import MiniCardFace from "../../../../components/card/mini-card-face/MiniCardFace";
import { getCurrentLanguage } from "../../../../modules/shared/utils";

const CrystallizePlanTab = ({
  game,
  player,
  topaasiaCard,
  topaasiaPerspective,
  crystallization,
  topics,
  playerParticipations = [],
  onShowPlayerIdeas,
  disabled = false,
}) => {
  const { findings, actionPlans } = crystallization;

  const sortedTopics = sortBy(topics, (t) => t.topicIndex);

  const showPlayerIdeasActionForTopic = (topicId) =>
    !!playerParticipations.find(
      (pp) => pp.conclusion.topicId === topicId && pp.playerIdeas.length > 0,
    );

  return (
    <Translation ns="views">
      {(t) => (
        <LightContentContainer className="crystallize-plan-tab">
          <div className="player-avatar-container">
            <span>
              <PlayerAvatar type={player.avatar} playerColor={player.color} size={30} />
            </span>
            <span>{player.name}</span>
          </div>
          <div className="crystallize-meta-content">
            <h1 className="crystallize-header">
              {t("crystallize-view.crystallize-plan-tab.title")}
            </h1>
            <h2 className="crystallize-plan-game-subject">{game.subject}</h2>
            <div className="crystallize-topaasia-card grid-x grid-margin-x">
              <div className="chosen-topaasia-label cell small-12 medium-6">
                {topaasiaPerspective.title[getCurrentLanguage()]}
              </div>
              <div className="card-face-container cell small-12 medium-6">
                <MiniCardFace
                  title={topaasiaCard.title}
                  description={topaasiaCard.description}
                  suit={topaasiaCard.suit}
                />
              </div>
            </div>
          </div>
          <div className="crystallize-plan">
            {sortedTopics.map((topic, i) => (
              <div key={topic.id} className="conclusion-row">
                {topic.kind === "finding" ? (
                  <Finding
                    index={i}
                    finding={findings.find((f) => f.topicId === topic.id)}
                    showActions={showPlayerIdeasActionForTopic(topic.id)}
                    onShowPlayerIdeas={onShowPlayerIdeas}
                    topic={topic}
                    disabled={disabled}
                  />
                ) : (
                  <ActionPlan
                    index={i}
                    actionPlan={actionPlans.find((ap) => ap.topicId === topic.id)}
                    showActions={showPlayerIdeasActionForTopic(topic.id)}
                    onShowPlayerIdeas={onShowPlayerIdeas}
                    topic={topic}
                    disabled={disabled}
                  />
                )}
              </div>
            ))}
          </div>
        </LightContentContainer>
      )}
    </Translation>
  );
};

CrystallizePlanTab.propTypes = {
  game: PropTypes.object.isRequired,
  player: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  topaasiaCard: PropTypes.object.isRequired,
  topaasiaPerspective: PropTypes.object.isRequired,
  crystallization: PropTypes.object.isRequired,
  topics: PropTypes.array.isRequired,
  playerParticipations: PropTypes.array,
  onShowPlayerIdeas: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CrystallizePlanTab;
