import React, { Component } from "react";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { Translation } from "react-i18next";
import { PuffLoader } from "react-spinners";

import { bind, isEqual, get } from "lodash";
import { Card } from "..";
import PerspectiveCard from "../PerspectiveCard";

import voteThumb from "../../../assets/images/vote-thumb-icon.png";
import selectedCardIcon from "../../../assets/images/selected-card-icon.svg?url";
import { getCurrentLanguage } from "../../../modules/shared/utils";
import { posedSelectionIconVariants, posedSlotVariants } from "../card-poses";

import "./card-slot.scss";
import { Button } from "../../button/Button";

const getAriaLabelForVariant = (t, card, variant) => {
  const hasVotes = card.votes && card.votes.length > 0;

  if (card.perspective) {
    if (hasVotes) {
      return t("card-slot.aria.with-perspective-with-votes-label", {
        title: card.title,
        perspective: get(card, `perspective.title.${getCurrentLanguage()}`, ""),
        description: card.description,
        count: card.votes.length,
      });
    }

    return t("card-slot.aria.with-perspective-label", {
      title: card.title,
      perspective: get(card, `perspective.title.${getCurrentLanguage()}`, ""),
      description: card.description,
    });
  }

  if (hasVotes) {
    return t("card-slot.aria.with-votes-label", {
      title: card.title,
      description: card.description,
      count: card.votes.length,
    });
  }

  if (variant === "selected") {
    return t("card-slot.aria.selected-label", { title: card.title, description: card.description });
  }
  if (variant === "played") {
    return t("card-slot.aria.played-label", { title: card.title, description: card.description });
  }

  return t("card-slot.aria.label", { title: card.title, description: card.description });
};

export class CardSlot extends Component {
  constructor(props) {
    super(props);

    this.cardRef = React.createRef();

    this.state = {
      changingFromCards: null,
      changing: false,
    };

    this.actions = {
      onClick: bind(this.onCardClick, this),
    };
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    const nextCards = nextProps.cards;
    const currentCards = prevProps.cards;

    const cardsEqual = isEqual(
      nextCards.map((c) => c.id).sort(),
      currentCards.map((c) => c.id).sort(),
    );

    if (!cardsEqual) {
      this.setState({
        changing: true,
        changingFromCards: currentCards,
      });

      setTimeout(() => {
        currentCards.forEach((c) => this.props.onCardDeselected(c));
        this.setState({ changing: false, changingFromCards: [] });
      }, 750);
    }
  }

  render() {
    const {
      isSelected,
      isPlayed,
      hasPlayedCard,
      isDisabled = false,
      isProcessing = false,
      hideWhenDisabled = true,
      actions = [],
    } = this.props;
    const minNoOfCardsForStack = 5;
    function calculateRenderedCardsNumber(numberOfCards) {
      if (numberOfCards < minNoOfCardsForStack) {
        return numberOfCards;
      }
      if (numberOfCards < 8) return 4;
      if (numberOfCards < 20) return 5;
      return 7;
    }

    const cards = this.state.changing ? this.state.changingFromCards : this.props.cards;

    let variant = isSelected ? "selected" : "normal";

    if (this.state.changing) {
      variant = "turned";
    } else if (hasPlayedCard && isPlayed) {
      variant = this.getPlayedMotionVariant();
    } else if (hasPlayedCard && !isPlayed) {
      variant = this.getDiscardedMotionVariant();
    }
    if (isDisabled && hideWhenDisabled) {
      variant = "discarded";
    }

    const isDiscarded = variant === "discarded";
    const isPlayedCard = variant === "played";

    const { onClick } = this.actions;

    const containsPerspective = () => cards.some((c) => c.perspective);

    const renderedCards = cards.slice(0, calculateRenderedCardsNumber(cards.length));

    return (
      <Translation ns="components">
        {(t) => (
          <div
            className={`card-slot ${containsPerspective() ? "with-perspective" : ""}`}
            lang={getCurrentLanguage()}>
            {renderedCards.map((card, i) => {
              const { title } = card;
              const description = card.description || "";

              return (
                <motion.div
                  variants={posedSlotVariants}
                  key={i}
                  className={`card-slot-pose
                    ${card.perspective ? "with-perspective" : ""}
                    ${isDisabled ? "disabled" : ""}
                    ${isDiscarded ? "discarded" : ""}`}
                  initial="normal"
                  animate={variant}
                  custom={{ index: i, totalCards: cards.length }}
                  onClick={() => onClick(card.id, card.key)}
                  onKeyPress={() => onClick(card.id, card.key)}
                  tabIndex={isDiscarded ? -1 : 0}
                  aria-label={getAriaLabelForVariant(t, card, variant)}
                  role="checkbox"
                  aria-checked={isSelected}
                  aria-hidden={isDiscarded}
                  aria-disabled={isDiscarded || isPlayedCard || isDisabled}
                  style={this.styleForPosedSlot(i, renderedCards.length, cards.length >= minNoOfCardsForStack)}>
                  {card.perspective && (
                    <PerspectiveCard
                      description={get(card, `perspective.description.${getCurrentLanguage()}`, "")}
                      suit={card.suit.kind}
                      isTurned={variant === "discarded"}
                    />
                  )}
                  <Card
                    forwardRef={this.cardRef}
                    cardId={card.id}
                    cardKey={card.key}
                    title={title}
                    description={description}
                    isDiscarded={isDiscarded}
                    suit={card.suit.kind}
                    style={CardSlot.styleForCard(card)}
                  />
                  {i === 0 && (
                    <motion.div
                      variants={posedSelectionIconVariants}
                      className="card-selected-icon"
                      initial="hidden"
                      animate={isSelected ? "visible" : "hidden"}
                      style={{ backgroundImage: `url(${selectedCardIcon})` }}
                    />
                  )}
                  <AnimatePresence>
                    {card.votes && card.votes.length > 0 && i === renderedCards.length - 1 && (
                      <motion.span
                        key={"vote-count-label"}
                        className={`card-votes-count-icon ${
                          get(actions, "length", 0) > 0 ? "with-actions" : ""} ${
                            cards.length >= minNoOfCardsForStack ? "stacked" : ""
                          }`}
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0 }}>
                        <span
                          aria-hidden={true}
                          className="icon"
                          style={{
                            backgroundImage: `url(${voteThumb})`,
                            display: "inline-block",
                            backgroundSize: "cover",
                          }}
                        />
                        <span>{card.votes.length}</span>
                      </motion.span>
                    )}
                    {actions && actions.length > 0 && i === renderedCards.length - 1  && (
                      <motion.span
                        key={"icon-actions"}
                        className="card-slot-icon-actions"
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1.33 }}
                        exit={{ opacity: 0, scale: 0 }}>
                        {actions.map((action) => (
                          <Button
                            className="card-action-button"
                            key={action.action}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              action.onClick();
                            }}>
                            {action.icon}
                          </Button>
                        ))}
                      </motion.span>
                    )}
                  </AnimatePresence>
                  { i === renderedCards.length - 1 && this.props.bottomText && (
                    <div className="bottom-text-container">
                      <div className="bottom-text-label">
                        { this.props.bottomText }
                      </div>
                    </div>
                  )}
                  { isProcessing && (
                    <div className="processing-overlay">
                      <PuffLoader />
                    </div>
                  )}
                  {i === renderedCards.length - 1 && cards.length >= minNoOfCardsForStack && (
                    <span className="card-count-indicator">{`${cards.length}x`}</span>
                  )}
                </motion.div>
              );
            })}
          </div>
        )}
      </Translation>
    );
  }

  styleForPosedSlot(index, count, isStack) {
    const { hasPlayedCard } = this.props;
    const reverseIndex = count - 1 - index;
    const contrastDelta = isStack ? 0.12 : 0.05;
    const contrast = 1 - reverseIndex * contrastDelta;

    return {
      filter: reverseIndex > 0 ? `contrast(${contrast})` : "none",
    };
  }

  getPlayedMotionVariant() {
    let pose = "played";

    if (this.props.animation === "emphasize") {
      pose = "emphasized";
    }

    return pose;
  }

  getDiscardedMotionVariant() {
    let pose = "discarded";

    const { hideWhenNotPlayed = false, hideWhenDisabled, animation } = this.props;

    if (animation === "emphasize") {
      pose = "lowered";
    } else if (!hideWhenDisabled) {
      pose = "normal";
    }

    if (hideWhenNotPlayed) {
      pose = "discarded";
    }

    return pose;
  }

  static styleForCard(card) {
    const { perspective } = card;

    return {
      top: perspective ? 30 : 0,
    };
  }

  onCardClick(cardId, cardKey) {
    if (this.state.changing) {
      return;
    }
    if (this.props.isDisabled) {
      return;
    }

    const newState = !this.props.isSelected;

    if (newState === true) {
      this.props.onCardSelected(cardId, cardKey);
    } else {
      this.props.onCardDeselected(cardId, cardKey);
    }
  }
}

CardSlot.propTypes = {
  cards: PropTypes.array.isRequired,
  slotIndex: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPlayed: PropTypes.bool.isRequired,
  hasPlayedCard: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  hideWhenDisabled: PropTypes.bool,
  hideWhenNotPlayed: PropTypes.bool,
  animation: PropTypes.string,
  onCardSelected: PropTypes.func,
  onCardDeselected: PropTypes.func,
  isProcessing: PropTypes.bool,
  actions: PropTypes.array,
  bottomText: PropTypes.string,
};
