import { createAction } from "redux-actions";

const namespaces = {
  gameNotes: "GAME-NOTES",
};

export const gameNoteActions = {
  post: {
    request: createAction(`${namespaces.gameNotes}/POST/REQUEST`),
    success: createAction(`${namespaces.gameNotes}/POST/SUCCESS`),
    error: createAction(`${namespaces.gameNotes}/POST/ERROR`),
  },
}
