import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const get = async () => {
  const response = await backendService.get({
    url: `player-api/player-votes/player/votes`,
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const vote = async ({ cardId, candidateId, kind = "topaasia", roundId = null }) => {
  const requestData = {
    card_id: cardId,
    candidate_id: candidateId,
    kind,
    roundId,
  };

  const response = await backendService.post({
    url: `player-api/player-votes/player/vote`,
    data: requestData,
  });

  return { data: fromSnakeToCamel(response.data) };
};
