import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";

const Board = ({ className = "", style = {}, children }) => (
  <div className={`board-scene ${className}`} style={style}>
    {children}
  </div>
);

Board.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  player: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  player: get(state, "player.player", null),
});

export default compose(
  connect(mapStateToProps),
)(Board);
