import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import JoinViewForm from "../JoinViewForm";
import LightContentContainer from "../../../components/layout/LightContentContainer";

const getGameCodeError = ({ t, joinError }) => {
  if (joinError === "invalid_game_code") {
    return t("join-view.form.gamecode-error");
  }
  if (joinError === "rate_limited") {
    return t("join-view.form.rate-limit-error");
  }

  return "";
};

const minimumGameCodeLength = 4;

const JoinNewGameContent = ({
  joinError = null,
  isJoining = false,
  onJoinGame,
  gameCode = "",
  gameCodeTouched = false,
  onGameCodeTouched,
  onGameCodeChanged,
  onClearErrors,
}) => {
  const { t } = useTranslation("views");

  const hasJoinError = joinError !== null;
  const gameCodeValid = gameCode.length >= minimumGameCodeLength;
  const canJoinGame = gameCode.length > 0 && gameCodeValid;

  return (
    <motion.div
      className="join-new-game-content"
      initial={{ opacity: 0, x: -50 }}
      animate={{
        opacity: 1,
        x: 0,
        transition: { type: "spring", damping: 30, mass: 1 },
      }}>
      <LightContentContainer>
        <h1>{t("join-view.title")}</h1>

        <JoinViewForm
          name={t("join-view.aria.join-form-label")}
          inputs={[
            {
              id: "gamecode-input",
              label: t("join-view.form.gamecode-label"),
              placeholder: t("join-view.form.gamecode-placeholder"),
              errorLabel: getGameCodeError({ t, joinError }),
              isValid: gameCodeValid && !hasJoinError,
              isTouched: gameCodeTouched,
              value: gameCode,
              maxLength: 20,
              onChange: (_, value) => {
                onGameCodeChanged(value.toUpperCase());
                onClearErrors();
              },
              onBlur: () => {
                onGameCodeTouched(true);
              },
            },
          ]}
          submitButton={{
            content: isJoining ? t("join-view.form.submitting") : t("join-view.form.submit"),
            disabled: !canJoinGame || isJoining,
            onClick: (e) => {
              e.preventDefault();
              onJoinGame(gameCode);
            },
          }}
        />
      </LightContentContainer>
    </motion.div>
  );
};

JoinNewGameContent.propTypes = {
  joinError: PropTypes.string,
  isJoining: PropTypes.bool,
  onJoinGame: PropTypes.func.isRequired,
  onClearErrors: PropTypes.func.isRequired,
  gameCode: PropTypes.string,
  gameCodeTouched: PropTypes.bool,
  onGameCodeTouched: PropTypes.func.isRequired,
  onGameCodeChanged: PropTypes.func.isRequired,
};

export default JoinNewGameContent;
