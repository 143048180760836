import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import { motion } from "framer-motion";

import { ModalMessage } from "../../../../components";

import StateStartModal from "../../common/StateStartModal";

import { game as gameModule } from "../../../../modules";

import BoardErrorToast from "../../common/BoardErrorToast";
import PickChoiceTopaasiaModeContent from "./topaasia/PickChoiceTopaasiaModeContent";
import PickChoiceOneOnOneModeContent from "./one-on-one/PickChoiceOneOnOneModeContent";

class PickChoiceView extends Component {
  constructor() {
    super();

    this.state = {
      hasSeenPoll: null,
      selectedCardId: null,
      shuffledCandidates: null,
      stateStartModalDismissed: false,
      stateStartModalGone: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(gameModule.actions.get.request());

    if (this.state.shuffledCandidates === null) {
      this.setState({
        shuffledCandidates: sortBy(this.props.candidates, "id"),
      });
    }

    this.skipStateStartModalIfNeeded();
  }

  componentDidUpdate() {
    this.skipStateStartModalIfNeeded();
  }

  skipStateStartModalIfNeeded() {
    if (!this.state.stateStartModalDismissed && this.shouldShowVoteModal()) {
      // Jump directly to voting instead of showing the state
      // message
      this.setState({
        stateStartModalDismissed: true,
        stateStartModalGone: true,
      });
    }
  }

  static getDerivedStateFromProps(props) {
    const { activePoll } = props;

    if (activePoll && activePoll.poll && activePoll.poll.state === "results") {
      return { selectedCandidateId: null };
    }

    return {};
  }

  render() {
    const { gameSubject, cards, voteError, t, game } = this.props;

    if (!cards || !this.state.shuffledCandidates) {
      return <div />;
    }

    if (!this.state.stateStartModalGone && !this.shouldShowVoteModal()) {
      return this.renderStateStartModal();
    }

    const showVoteModal = this.shouldShowVoteModal();

    return (
      <motion.main
        className="board-transition-canvas"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75 }}>
        <ModalMessage
          title={t("vote-dialog.title")}
          message={t("vote-dialog.vote-help.pick-choice")}
          dismissButtonText={t("vote-dialog.confirm-button")}
          visible={showVoteModal}
          allowDismiss={true}
          onDismiss={() => {
            this.onVoteModalDismiss();
          }}
        />

        {game.gameMode === "topaasia" && (
          <PickChoiceTopaasiaModeContent gameSubject={gameSubject} isDisabled={showVoteModal} />
        )}
        {game.gameMode === "one_on_one" && (
          <PickChoiceOneOnOneModeContent gameSubject={gameSubject} isDisabled={showVoteModal} />
        )}

        <BoardErrorToast isVisible={voteError} errorLabel={t("player-vote.errors.vote-error")} />
      </motion.main>
    );
  }

  renderStateStartModal() {
    const { t, game, gameState } = this.props;
    const perspective = gameState.currentRoundPerspective;
    const perspectiveTitle = perspective.title[game.deckLanguage];

    return (
      <StateStartModal
        boardClass="pick-choice-view-board"
        title={
          <Trans
            ns="views"
            i18nKey={"pick-choice-view.pick-choice-dialog.title"}
            values={{ perspective: perspectiveTitle }}
            components={{ strong: <strong className="accent-color" /> }}
          />
        }
        message={
          <Trans
            ns="views"
            i18nKey={`pick-choice-view.pick-choice-dialog.message.${game.gameMode}`}
            values={{ perspective: perspectiveTitle }}
            components={{ strong: <strong className="accent-color" /> }}
          />
        }
        dismissButton={t("pick-choice-view.pick-choice-dialog.button")}
        visible={!this.state.stateStartModalDismissed}
        onDismiss={() => {
          this.onStateStartModalDismiss();
        }}
      />
    );
  }

  onStateStartModalDismiss() {
    this.setState({ stateStartModalDismissed: true });

    setTimeout(() => {
      this.setState({ stateStartModalGone: true });
    }, 750);
  }

  shouldShowVoteModal() {
    const { activePoll, ownVote } = this.props;
    const shouldShow =
      activePoll &&
      !ownVote &&
      activePoll.poll.state === "active" &&
      activePoll.poll.id !== this.state.hasSeenPoll;

    return !!shouldShow;
  }

  onVoteModalDismiss() {
    this.setState({
      hasSeenPoll: this.props.activePoll ? this.props.activePoll.poll.id : null,
    });
  }
}

PickChoiceView.propTypes = {
  activePoll: PropTypes.object,
  votes: PropTypes.array,
  ownVote: PropTypes.object,
  cards: PropTypes.array,
  candidates: PropTypes.array,
  selection: PropTypes.array,
  game: PropTypes.object,
  player: PropTypes.object,
  gameState: PropTypes.object,
  gameSubject: PropTypes.string,
  playerId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isVoting: PropTypes.bool,
  voteError: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { activePoll } = state.game.gameState.stateData;
  const { player } = state.player;
  const votes = (activePoll && activePoll.votes) || [];

  const ownVote = votes.find((v) => v.playerId === player.id);

  return {
    cards: state.deck.deck.cards,
    candidates: state.game.gameState.stateData.candidates,
    game: state.game.game,
    gameState: state.game.gameState,
    player,
    isVoting: state.playerVote.status.isVoting,
    voteError: state.playerVote.status.voteError,
    activePoll,
    votes,
    ownVote,
  };
};

export default compose(withTranslation("views"), connect(mapStateToProps))(PickChoiceView);
