import { select, put, take, call } from "redux-saga/effects";
import { shuffle, slice, uniqBy } from "lodash";

import { actions, api } from ".";
import { actions as deckActions } from "../deck";
import { actions as gameActions } from "../game";

export const getCards = (state) => {
  const allCards = state.deck.deck.cards;
  return allCards;
};

export const deck = {
  *deal() {
    try {
      const state = yield select();
      yield put(deckActions.get.request());
      yield put(actions.deal.begin());
      yield take(deckActions.get.success);
      const { gameMode } = state.game.game;
      const playedCards = state.hand.played.cards;
      let allCards = state.deck.deck.cards;

      if (gameMode === "one_on_one") {
        allCards = allCards.filter((c) => !playedCards.includes(c.id));
      }

      const uniqCards = uniqBy(allCards, (c) => c.id);
      const dealt = slice(shuffle(uniqCards), 0, 5);

      // In Topaasia game mode you will always be dealt the card that you have already played.
      // This is to correctly restore state after a browser refresh.
      if (gameMode === "topaasia") {
        const playedCard = playedCards[0];

        if (playedCard && !dealt.map((c) => c.id).includes(playedCard)) {
          dealt[0] = allCards.find((c) => c.id === playedCard);
        }
      }



      yield put(actions.deal.end({ all: allCards, dealt }));
    } catch (error) {
      yield put(actions.deal.error(error));
    }
  },
};

export const play = {
  *post(action) {
    try {
      const state = yield select();
      const roundId = state.game.gameState.currentRoundId;
      const cardId = action.payload;
      yield call(api.play, { cardId });
      yield put(actions.play.success({ cardId, roundId }));
    } catch (error) {
      yield put(actions.play.error(error));

      // Refresh state to avoid any inconsistencies if this was caused
      // by a bug etc.
      yield put(gameActions.state.get.request());
      yield put(actions.played.request());

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  },
  *undo(action) {
    try {
      const state = yield select();
      const roundId = state.game.gameState.currentRoundId;
      const { cardId } = action.payload;
      yield call(api.undo, { cardId, roundId });
      yield put(actions.play.undo.success({ cardId, roundId }));
    } catch (error) {
      yield put(actions.play.undo.error(error));

      // Refresh state to avoid any inconsistencies if this was caused
      // by a bug etc.
      yield put(gameActions.state.get.request());
      yield put(actions.played.request());

      if (process.env.NODE_ENV === "development") {
        throw error;
      }
    }
  },
};

export const played = {
  *get() {
    const response = yield call(api.playedCards);
    yield put(actions.played.success(response));
  },
};
