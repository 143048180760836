import { call, put } from "redux-saga/effects";

import { actions, api } from ".";

export const player = {
  * get() {
    try {
      const response = yield call(api.get);
      const payload = response ? response.data : {};
      yield put(actions.get.success(payload));
    } catch (error) {
      yield put(actions.get.error(error));
      throw error;
    }
  },
  * patch(action) {
    try {
      const { name } = action.payload;
      const response = yield call(api.patch, { name });
      const payload = response ? response.data : {};
      yield put(actions.patch.success(payload));
    } catch (error) {
      yield put(actions.patch.error(error));
      throw error;
    }
  },
};
