export const liftVerticalVariants = {
  from: () => ({
    y: 100,
    opacity: 0,
    rotateY: 20,
  }),
  enter: ({ index }) => ({
    y: 0,
    opacity: 1,
    transition: { type: "spring", delay: index * 0.2 },
    rotateY: 0.01, // Required to workaround a Firefox bug
  }),
  exit: () => ({
    y: -50,
    opacity: 0,
    rotateY: 0.01,
  }),
};

export const turnAnimationVariants = {
  from: () => ({
    rotateY: 90,
    opacity: 0,
    x: -50,
  }),
  enter: ({ index }) => ({
    opacity: 1,
    rotateY: 0.01, // Required to workaround a Firefox bug
    transition: { type: "spring", delay: index * 0.7 },
    x: 0,
  }),
  exit: () => ({
    rotateY: 90,
    opacity: 0,
    x: 0,
    y: 200,
  }),
};