import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { ToastContainer } from "react-toastify";

import { useViewportSize } from "../../../../../modules/shared/utils";

import "./pick-candidate-one-on-one-mode-content.scss";
import CardCollectionView from "../../../../../components/card/card-collection-view/CardCollectionView";
import ActionBar from "../../../../../components/action-bar/ActionBar";

// Images
import changeIcon from "../../../../../assets/images/change-cards-icon.svg?url";
import changeAllIcon from "../../../../../assets/images/change-all-cards-icon.svg?url";
import doneChangingCardsIcon from "../../../../../assets/images/done-changing-cards-icon.svg?url";
import ChosenCardDialog from "../chosen-card-dialog/ChosenCardDialog";
import PlayedCardsBar from "../played-cards-bar/PlayedCardsBar";
import StateStartModal from "../../../common/StateStartModal";
import { hand } from "../../../../../modules";
import { useCardCollectionTopMargin } from "../../../common/card-collection-utils";
import { useDeckCards } from "../../../../../modules/deck/selectors";

const PickCandidateOneOnOneModeContent = () => {
  const chosenCardCount = 3;

  const [vw, vh] = useViewportSize();
  const isPortrait = vw < vh;
  const isDesktop = vw >= 1180;
  const { t } = useTranslation(["views", "components"]);
  const cardCollectionRef = useRef();
  const actionBarRef = useRef();

  const cardCollectionTop = useCardCollectionTopMargin(cardCollectionRef, null, actionBarRef);

  const dispatch = useDispatch();

  const [focusedCardId, setFocusedCardId] = useState(null);
  const [uiMode, setUiMode] = useState("play"); // play, change-cards
  const [isFinished, setFinished] = useState(false);
  const [cardCollectionBottom, setCardCollectionBottomPadding] = useState();

  const deckCards = useDeckCards();
  const cards = useSelector((state) => state.hand.cards.dealt) || [];
  const playedCardIds = useSelector((state) => state.hand.played.cards);
  const isPlaying = useSelector((state) => state.hand.status.isPlaying);

  const maxSelectionReached = playedCardIds.length === chosenCardCount;
  const chosenCardModel = cards.find((c) => c.id === focusedCardId);

  useLayoutEffect(() => {
    if (actionBarRef.current) {
      const actionsTop = actionBarRef.current.getBoundingClientRect().top;
      const bottomPadding = vh - actionsTop;
      setCardCollectionBottomPadding(bottomPadding);
    }
  }, [vw, vh]);

  const composeButtons = () => {
    if (maxSelectionReached) {
      return [];
    }

    if (uiMode === "change-cards") {
      return [
        {
          key: "cancel",
          title: t("components:card-actions.change-done"),
          disabled: false,
          icon: doneChangingCardsIcon,
          onClick: () => {
            setUiMode("play");
          },
        },
        {
          key: "change-all-cards",
          title: t("components:card-actions.change-all"),
          disabled: false,
          icon: changeAllIcon,
          onClick: () => {
            dispatch(hand.actions.change.request(cards.map((c) => c.id)));
            setUiMode("play");
          },
        },
      ];
    }

    return [
      {
        key: "change-cards",
        title: t("components:card-actions.change-selected"),
        disabled: false,
        icon: changeIcon,
        onClick: () => {
          setUiMode("change-cards");
        },
      },
    ];
  };

  useEffect(() => {
    if (playedCardIds.length === chosenCardCount) {
      setFinished(true);
    }
  }, [playedCardIds]);

  return (
    <div className="pick-candidate-one-on-one-mode-content">
      <div
        className="card-collection-container"
        tabIndex={0}
        ref={cardCollectionRef}
        style={{
          marginTop: cardCollectionTop,
          marginBottom: cardCollectionBottom,
        }}>
        <PlayedCardsBar
          slotCount={3}
          cards={playedCardIds.map((id) => deckCards.find((c) => c.id === id))}
          onChangeCard={(card) => {
            dispatch(hand.actions.play.undo.request({ cardId: card.id }));
          }}
        />
        <CardCollectionView
          cards={cards}
          slotCount={5}
          isDisabled={maxSelectionReached}
          hideCardsWhenDisabled={maxSelectionReached}
          hideWhenNotPlayed={false}
          layoutDirection={isPortrait || !isDesktop ? "vertical" : "horizontal"}
          actionsForCard={(card) => {
            if (uiMode === "change-cards") {
              return [
                {
                  action: "change-card",
                  icon: <ChangeCircleIcon fontSize="large" color="orange" />,
                  label: t("components:card-actions.change-card"),
                  onClick: () => {
                    dispatch(hand.actions.change.request([card.id]));
                  },
                },
              ];
            }
            return [];
          }}
          onCardSelected={(cardId) => {
            setFocusedCardId(cardId);
          }}
          onCardDeselected={() => {}}
        />
      </div>

      <ActionBar
        buttons={composeButtons(uiMode)}
        contentRef={actionBarRef}
        isHidden={!!chosenCardModel}
      />

      <ToastContainer
        style={{
          transform: "translateZ(100px)",
        }}
      />

      <ChosenCardDialog
        card={chosenCardModel}
        onDismiss={() => {
          setFocusedCardId(null);
        }}
        actions={[
          {
            action: "confirm",
            icon: <PlayCircleIcon />,
            label: t("pick-candidate-one-on-one-mode-content.chosen-card-dialog.play-this"),
            callback: () => {
              if (isPlaying || !focusedCardId) {
                return;
              }

              dispatch(hand.actions.play.request(focusedCardId));
              setFocusedCardId(null);

              setTimeout(() => {
                dispatch(hand.actions.change.request([focusedCardId]));
              }, 200);
            },
          },
          {
            action: "cancel",
            label: t("pick-candidate-one-on-one-mode-content.chosen-card-dialog.choose-another"),
            buttonStyle: "subtle",
            callback: () => {
              setFocusedCardId(null);
            },
          },
        ]}
      />

      <StateStartModal
        visible={isFinished}
        title={t("pick-candidate-one-on-one-mode-content.finished-modal.title")}
        message={t("pick-candidate-one-on-one-mode-content.finished-modal.message")}
        dismissButton={process.env.NODE_ENV === "development" ? "DEV: Cancel" : null}
        onDismiss={
          process.env.NODE_ENV === "development"
            ? () => {
                setFinished(false);
              }
            : () => {}
        }
      />
    </div>
  );
};

PickCandidateOneOnOneModeContent.propTypes = {};

export default PickCandidateOneOnOneModeContent;
