import { call, put, cancelled, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import i18next from "i18next";
import { get, get as safeGet } from "lodash";

import { shared, hand } from "..";
import { actions, api } from ".";
import { api as playerApi, actions as playerActions } from "../player";

import { rememberEmailService } from "../../services";
import { isAuthorized } from "../../services/backendService";
import * as playerFeedbackActions from "../player-feedback/actions";

let gameExport = {}; // To be able to reference this.
gameExport = {
  * get() {
    try {
      const response = yield call(api.get);
      const payload = response ? response.data : {};
      yield put(actions.meta.restore());
      yield put(actions.get.success(payload));
      yield put(actions.state.refresh.request());
      yield put(hand.actions.played.clear());
      yield put(hand.actions.played.request());
      yield put(playerActions.get.request());
      yield put(playerFeedbackActions.get.request());

      i18next.changeLanguage(payload.deckLanguage);
    } catch (error) {
      const statusCode = safeGet(error, "response.data.status", 0);

      yield put(actions.get.error(error));

      if (!statusCode || statusCode >= 500) {
        throw error;
      } else {
        // Any 4xx error will be assumed to be caused by an invalid game code.
        // Redirect back to join view.
        yield put(push("/join"));
      }
    }
  },

  *restore() {
    try {
      const canTryRestoreGame = isAuthorized();

      if (!canTryRestoreGame) {
        yield put(actions.restore.success({ status: "not_authorized", game: null}));
        return;
      }

      const response = yield call(api.get);
      const payload = response ? response.data : {};

      const playerResponse = yield call(playerApi.get);
      const playerPayload = playerResponse ? playerResponse.data.player : {};

      yield put(actions.restore.success({
        status: "joined_game_available",
        game: payload,
        player: playerPayload,
      }));
    } catch (error) {
      const statusCode = safeGet(error, "response.status", 0);

      if (statusCode === 401) {
        yield put(actions.meta.clearAll());
        yield put(actions.restore.success({ status: "not_authorized", game: null, player: null }));
      } else {
        yield put(actions.restore.error({ status: "network_error", game: null, player: null }));
      }
    }
  },

  * join(action) {
    try {
      yield put(actions.meta.clearAll());
      yield put(actions.join.request());

      const response = yield call(api.join, action.payload);
      const payload = response ? response.data : {};
      const { player, loginToken } = payload;

      yield put(actions.meta.persist({ loginToken, playerId: player.id }));
      yield put(actions.join.success(payload));
      yield put(push("/play"));
    } catch (error) {
      const statusCode = safeGet(error, "response.status", 0);
      const data = safeGet(error, "response.data", {});

      if (statusCode === 400 && data.code === "invalid_game_code") {
        yield put(actions.join.error("invalid_game_code"));
      } else if (statusCode === 429) {
        yield put(actions.join.error("rate_limited"));
      }
    }
  },

  * finish(action) {
    try {
      const { emailAddress } = action.payload;

      yield call(api.finish, { emailAddress });
      yield put(actions.finish.success());
      yield put(actions.meta.clearAll());

      rememberEmailService.set(emailAddress);

      window.location.replace("/join");
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        throw error;
      }

      window.location.replace("/join");
    }
  },

  state: {
    * get() {
      try {
        const compareHash = yield select((state) => get(state, "game.gameState.compareHash", null));
        yield put(actions.state.get.request());
        const response = yield call(api.state.get, { compareHash });

        if (get(response.data, "status", null) === "not_modified") {
          return;
        }

        yield call(gameExport.state.change, response.data);
        yield put(actions.state.get.success(response));
      } catch (error) {
        yield put(actions.state.get.error(error));
      }
    },
    * poll(action) {
      try {
        while (true) {
          yield call(gameExport.state.get, action);
          yield call(shared.generators.generateDelay, 3000);
        }
      } finally {
        if (yield cancelled()) {
          yield put(actions.state.refresh.cancelled());
        }
      }
    },
    * change(data) {
      const currState = yield select();
      const oldState = currState.game.gameState || { state: "waitplayers" };
      const newState = data.state;
      const { playerId } = currState.game.meta;
      const roundId = newState.currentRound ? newState.currentRound.id : null;

      if (oldState.state !== newState.state) {
        if (newState.state === "rounds") {
          // First round began
          yield call(shared.generators.generateDelay, 1000);
          yield put(hand.actions.deal.request({ roundId, playerId }));
        }
      } else if (newState.state === "rounds") {
        if (oldState.currentRoundId !== roundId) {
          // Round changed
          yield call(shared.generators.generateDelay, 500);
          yield put(hand.actions.played.clear());
          yield put(hand.actions.played.request());
          yield put(hand.actions.deal.request({ roundId, playerId }));
        }
      }
    },
  },
};

export const game = gameExport;

export const pollDriver = shared.generators.generatePollDriver({
  startAction: actions.state.refresh.request,
  stopAction: actions.state.refresh.stop,
  pollSaga: game.state.poll,
});
