import React from "react";
import PropTypes from "prop-types";

import {
  getColoredAvatarForType,
} from "./avatars";

const getFillColor = (fillColor) => fillColor || "white";

const PlayerAvatar = ({
  type = "unknown",
  playerColor = "#555",
  fillColor = null,
  size = 200,
}) => (
  <div className="player-avatar" style={{
    width: size,
    maxWidth: size,
    height: size,
    maxHeight: size,
    backgroundColor: playerColor || "none",
  }}>
    {getColoredAvatarForType(type, getFillColor(fillColor))}
  </div>
);

PlayerAvatar.propTypes = {
  type: PropTypes.string,
  playerColor: PropTypes.string,
  fillColor: PropTypes.string,
  size: PropTypes.number,
}

export default PlayerAvatar;
