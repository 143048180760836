import React from "react";
import PropTypes from "prop-types";
import PlayerAvatar from "@topaasia/player-avatar";

import "./card-game-note-list-item.scss";

const CardGameNoteListItem = ({ gameNote }) => {
  const { player } = gameNote;

  return (
    <div className="card-game-note-list-item">
      {player ? (
        <PlayerAvatar size={40} type={player.avatar} playerColor={player.color} />
      ) : (
        <PlayerAvatar size={40} />
      )}
      <span className="game-note-message">{gameNote.text}</span>
    </div>
  );
};

CardGameNoteListItem.propTypes = {
  gameNote: PropTypes.shape({
    player: PropTypes.object,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default CardGameNoteListItem;
