import { uniq } from "lodash";

let isScrollLocked = false;
let scrollLocks = [];
let restoreStyles = {};

const enableDocumentScrolling = () => {
  if (!isScrollLocked) { return; }
  document.body.style.position = restoreStyles.position;
  document.body.style.top = restoreStyles.top;
  document.body.style.overflow = restoreStyles.overflow;
  window.scrollTo(0, restoreStyles.scrollY);
  isScrollLocked = false;
};

const disableDocumentScrolling = () => {
  if (isScrollLocked) { return; }
  const { scrollY } = window;
  const { overflow, position, top } = document.body.style;
  restoreStyles = { overflow, position, top, scrollY };

  document.body.style.position = "fixed";
  document.body.style.top = `${-scrollY}px`;
  document.body.style.overflow = "hidden";
  isScrollLocked = true;
};

const checkLocks = () => {
  if (scrollLocks.length === 0) {
    enableDocumentScrolling();
  } else {
    disableDocumentScrolling();
  }
};

export const setScrollLock = (id) => {
  scrollLocks = uniq([...scrollLocks, id]);
  checkLocks();
};

export const releaseScrollLock = (id) => {
  scrollLocks = scrollLocks.filter((lockId) => lockId !== id);
  checkLocks();
};

export const forceReleaseAllLocks = () => {
  scrollLocks = [];
  checkLocks();
};
