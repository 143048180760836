import { createAction } from "redux-actions";

const namespaces = {
  hand: "HAND",
};

export const deal = {
  request: createAction(`${namespaces.hand}/DEAL/REQUEST`),
  begin: createAction(`${namespaces.hand}/DEAL/START`),
  end: createAction(`${namespaces.hand}/DEAL/END`),
  error: createAction(`${namespaces.hand}/DEAL/ERROR`),
};

export const change = {
  request: createAction(`${namespaces.hand}/CHANGE/REQUEST`),
};

export const played = {
  request: createAction(`${namespaces.hand}/PLAYED/REQUEST`),
  success: createAction(`${namespaces.hand}/PLAYED/SUCCESS`),
  error: createAction(`${namespaces.hand}/PLAYED/ERROR`),
  clear: createAction(`${namespaces.hand}/PLAYED/CLEAR`),
};

export const play = {
  request: createAction(`${namespaces.hand}/PLAY/REQUEST`),
  success: createAction(`${namespaces.hand}/PLAY/SUCCESS`),
  error: createAction(`${namespaces.hand}/PLAY/ERROR`),

  undo: {
    request: createAction(`${namespaces.hand}/PLAY/UNDO/REQUEST`),
    success: createAction(`${namespaces.hand}/PLAY/UNDO/SUCCESS`),
    error: createAction(`${namespaces.hand}/PLAY/UNDO/ERROR`),
  },
};

export const selection = {
  select: createAction(`${namespaces.hand}/CARD/SELECT`),
  selectMultiple: createAction(`${namespaces.hand}/CARD/SELECT_MULTIPLE`),
  deselect: createAction(`${namespaces.hand}/CARD/DESELECT`),
  clear: createAction(`${namespaces.hand}/CARD/SELECT/CLEAR`),
};
