import { Route, Redirect, Switch } from "react-router-dom";
import React from "react";

import JoinView from "../views/join/JoinView";
import PlayView from "../views/play/PlayView";

export const renderRoutes = () => (
  <Switch>
    <Route path="/join" component={JoinView} />
    <Route path="/play" component={PlayView} />
    <Redirect from="*" to="/join" />
  </Switch>
);
