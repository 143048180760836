import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import PlayerAvatar from "@topaasia/player-avatar";
import { Button } from "../../../components/button/Button";

import "./join-existing-game-content.scss";

const JoinExistingGameContent = ({
  restoredGame,
  onJoinExistingGame,
  onJoinNewGame,
  onCancelJoinNewGame,
  isJoiningNewGame = false,
}) => {
  const { t } = useTranslation("views");
  const availableStatus = "joined_game_available";
  const notCheckedStatus = "not_checked";
  const shouldRender = restoredGame.status === availableStatus;

  useEffect(() => {
    if (restoredGame.status !== notCheckedStatus && restoredGame.status !== availableStatus) {
      onJoinNewGame();
    }
  }, [restoredGame]);

  if (!shouldRender) {
    return null;
  }

  const { game, player } = restoredGame;

  return (
    <motion.div
      className="join-existing-game-content"
      initial={{ opacity: 0, scaleY: 1 }}
      animate={{ opacity: 1, scaleY: 1 }}>
      {!isJoiningNewGame && (
        <motion.div className="existing-game-content" initial={{ x: -50 }} animate={{ x: 0 }}>
          <h2 className="game-found-title">{t("join-view.join-existing-game.title")}</h2>
          <p className="game-found-description">{t("join-view.join-existing-game.description")}</p>
          <div
            className="game-preview"
            style={{
              backgroundColor: player.color,
            }}>
            <div className="player-avatar-container">
              <PlayerAvatar size={60} type={player.avatar} playerColor={player.color} />
            </div>
            <div className="game-info">
              <h3 className="deck-title">{game.deckTitle}</h3>
              <h4 className="game-subject">{game.subject}</h4>
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        className="actions"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}>
        <div className="action-column join-back-action">
          {!isJoiningNewGame && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                onJoinExistingGame();
              }}>
              {t("join-view.join-existing-game.actions.rejoin")}
            </Button>
          )}
        </div>
        <div className="action-column join-new-action">
          <Button
            style="subtle"
            onClick={(e) => {
              e.preventDefault();

              if (isJoiningNewGame) {
                onCancelJoinNewGame();
              } else {
                onJoinNewGame();
              }
            }}>
            {isJoiningNewGame
              ? t("join-view.join-existing-game.actions.join-existing")
              : t("join-view.join-existing-game.actions.join-new")}
          </Button>
        </div>
      </motion.div>
    </motion.div>
  );
};

JoinExistingGameContent.propTypes = {
  restoredGame: PropTypes.shape({
    status: PropTypes.oneOf([
      "not_checked",
      "not_authorized",
      "joined_game_available",
      "network_error",
    ]),
    game: PropTypes.object,
    player: PropTypes.object,
  }).isRequired,
  onJoinExistingGame: PropTypes.func,
  onJoinNewGame: PropTypes.func,
  onCancelJoinNewGame: PropTypes.func,
  isJoiningNewGame: PropTypes.bool,
};

export default JoinExistingGameContent;
