import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import ChatIcon from "@mui/icons-material/Chat";

import TextAreaInput from "../../../../../components/form/TextAreaInput";

import "./comment-card-dialog.scss";
import { Button } from "../../../../../components/button/Button";
import { useCurrentRoundId, useRoundGameNotes } from "../../../../../modules/game/selectors";
import { useCurrentPlayer } from "../../../../../modules/player/selectors";
import CardGameNoteList from "../card-game-note-list/CardGameNoteList";

const useCommentDialogGameNotes = ({ displayMode, card = null }) => {
  const roundId = useCurrentRoundId();
  const player = useCurrentPlayer();
  const gameNotes = useRoundGameNotes({ roundId, onlyPlayerNotes: false });

  if (!card) {
    return [];
  }

  const cardNotes = gameNotes.filter((gameNote) => gameNote.card && gameNote.card.id === card.id);

  if (displayMode === "own") {
    return cardNotes.filter((gameNote) => gameNote.playerId === player.id);
  }

  return cardNotes;
};

const CommentCardDialog = ({
  card = null,
  allowDismiss = true,
  displayMode = "own",
  onDismiss = () => {},
  onSubmit = () => {},
}) => {
  const { t } = useTranslation("views");
  const isExiting = useRef(false); // useRef because useState is a useFootgun case

  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const gameNotes = useCommentDialogGameNotes({ displayMode, card });

  gameNotes.sort((a, b) => {
    if (a.playerId === null && b.playerId !== null) {
      return -1;
    }
    if (a.playerId !== null && b.playerId === null) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    isExiting.current = !card;
  }, [card]);

  return createPortal(
    <>
      {card && (
        <Helmet>
          <body className="blur-application-foreground scroll-lock"></body>
        </Helmet>
      )}
      <AnimatePresence>
        {card && (
          <motion.div
            className="comment-card-dialog"
            initial={{ opacity: 0, z: 200 }}
            exit={{ opacity: 0, z: 200 }}
            animate={{ opacity: 1, z: 200 }}
            transition={{ duration: 0.2 }}
            onClick={(e) => {
              e.preventDefault();
              if (allowDismiss) {
                setComment("");
                onDismiss();
              }
            }}>
            <motion.div
              className="comment-card-dialog-content"
              initial={{ y: 50, z: 250 }}
              animate={{ y: 0, z: 250 }}
              exit={{ y: 50, z: 250 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              <h2 className="comment-card-dialog-title">{t("comment-card-dialog.title")}</h2>

              <div className="card-game-note-list-wrapper">
                <CardGameNoteList gameNotes={gameNotes} />
              </div>

              <TextAreaInput
                minRows={1}
                maxRows={4}
                maxLength={200}
                disabled={isExiting.current || submitting}
                className="comment-textarea"
                placeholder={t("comment-card-dialog.comment-placeholder")}
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />

              <div className="actions">
                <Button
                  disabled={!comment.length || submitting}
                  onClick={() => {
                    if (!isExiting.current) {
                      setSubmitting(true);
                      onSubmit(comment, card, () => {
                        setComment("");
                        setSubmitting(false);
                      });
                    }
                  }}>
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "8px",
                    }}>
                    <ChatIcon />
                    <span>
                      {submitting
                        ? t("comment-card-dialog.submitting")
                        : t("comment-card-dialog.post-comment")}
                    </span>
                  </span>
                </Button>
                <Button
                  style="subtle"
                  onClick={() => {
                    setComment("");
                    onDismiss();
                  }}>
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                    }}>
                    <span>{t("comment-card-dialog.cancel")}</span>
                  </span>
                </Button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>,
    document.getElementById("overlay-container"),
  );
};

CommentCardDialog.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string,
  }),
  displayMode: PropTypes.oneOf(["own", "all"]),
  shouldDisable: PropTypes.bool,
};

export default CommentCardDialog;
