import React from "react";
import PropTypes from "prop-types";

import "./board-info.scss";
import PlayerBadge from "../player-badge/PlayerBadge";

const BoardInfo = ({
  gameSubject,
  player,
  boardInfoTitle,
  boardInfoMessage,
  containerRef = null,
}) => (
  <div className="board-info" ref={containerRef}>
    <div className={`game-info-container ${player ? "with-player" : ""}`}>
      {player && (
        <div className="player-badge-container">
          <PlayerBadge player={player} />
        </div>
      )}
      <h1 className="game-subject">{gameSubject}</h1>
      <h2 className="board-info-header">{boardInfoTitle}</h2>
      <p>{boardInfoMessage}</p>
    </div>
  </div>
);

BoardInfo.propTypes = {
  gameSubject: PropTypes.string.isRequired,
  player: PropTypes.object,
  boardInfoTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  boardInfoMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  containerRef: PropTypes.any,
};

export default BoardInfo;
