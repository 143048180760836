import { combineReducers } from "redux";
import { handleAction, handleActions } from "redux-actions";
import { flatMap } from "lodash";

import { actions } from ".";

const initialState = {
  deck: {
    cards: null,
    suits: null,
  },
  status: {
    loading: false,
  },
};

const deck = handleAction(actions.get.success, (state, action) => {
  const allCards = flatMap(action.payload.deck.suits.map((suit) => suit.cards));

  return {
    cards: allCards,
    suits: action.payload.suits,
  };
}, initialState.deck);

const status = handleActions({
  [actions.get.request]: () => ({ loading: true }),
  [actions.get.success]: () => ({ loading: false }),
  [actions.get.error]: () => ({ loading: false }),
}, initialState.status);

export const reducer = combineReducers({
  deck,
  status,
});
