import { en, fi, de, sv } from "./locales";

export const configuration = {
  languages: {
    decks: [
      "fi",
      "en",
      "de",
      "sv",
    ],

    ui: {
      cache: {
        key: "language",
        sources: ["localStorage", "cookie"],
      },
      debug: false,
      fallback: "en",
      resources: {
        en,
        fi,
        de,
        sv,
      },
    },
  },
};
