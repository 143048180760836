import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SuitIcon from "../../../../../../components/card/suit-icon/SuitIcon";

import "./played-card-bar-item.scss";
import { Button } from "../../../../../../components/button/Button";

const suitConfig = {
  diamonds: {
    color: "#59aa3b",
  },
  hearts: {
    color: "#e14970",
  },
  spades: {
    color: "#00587d",
  },
  clubs: {
    color: "#fbba00",
  },
};

const PlayedCardBarItem = ({ card, onChangeCard }) => {
  const suit = card.suit.kind;
  const { color } = suitConfig[suit];

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="played-card-bar-item">
      <div className="played-card-bar-item-content">
        <SuitIcon suit={suit} size={24} />
        <div
          className="card-title"
          style={{
            color,
          }}>
          {card.title}
        </div>
        <Button className="change-card-button" onClick={onChangeCard}>
          <ChangeCircleIcon fontSize="large" />
        </Button>
      </div>
    </motion.div>
  );
};

PlayedCardBarItem.propTypes = {
  card: PropTypes.object.isRequired,
  onChangeCard: PropTypes.func,
};

export default PlayedCardBarItem;
