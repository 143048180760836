import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const play = async ({ cardId }) => {
  const requestData = {
    card_id: cardId,
  };

  const response = await backendService.post({ url: `player-api/cards/play`, data: requestData });
  return response;
};

export const undo = async ({ cardId, roundId }) => {
  const url = "player-api/cards/undo";
  const response = await backendService.patch({
    url,
    data: { card_id: cardId, round_id: roundId },
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const playedCards = async () => {
  const url = `player-api/cards/played-cards`;
  const response = await backendService.get({ url });

  return fromSnakeToCamel(response.data);
};
