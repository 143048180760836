import { combineReducers } from "redux";
import { handleAction, handleActions } from "redux-actions";

import * as actions from "./actions";

const initialState = {
  topaasiaRound: null,
  status: {
    loading: false,
    error: true,
  },
};

const collection = handleAction(actions.rounds.topaasiaRound.get.success, (state, action) => (
  action.payload
), initialState.topaasiaRound);

const status = handleActions({
  [actions.rounds.topaasiaRound.get.request]: () => ({ loading: true, error: false }),
  [actions.rounds.topaasiaRound.get.success]: () => ({ loading: false, error: false }),
  [actions.rounds.topaasiaRound.get.error]: () => ({ loading: false, error: true }),
}, initialState.status);

export const reducer = combineReducers({
  collection,
  status,
});
