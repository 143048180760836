import React from "react";
import PropTypes from "prop-types";

import backFace from "../../../assets/images/back.png";
import topaasia from "../../../assets/images/topaasia.png";

const TopaasiaCardDecoration = ({ className, style }) => (
  <div className={`topaasia-card-decoration ${className}`} style={{ ...(style || {}),
    background: `url(${backFace}) #555`,
    backgroundSize: "contain",
    textAlign: "center",
    borderRadius: 8,
  }}>
    <div style={{
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "80%",
      margin: "auto",
      backgroundImage: `url(${topaasia})`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}>
    </div>
  </div>
);

TopaasiaCardDecoration.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  sizeScale: PropTypes.number,
};

export default TopaasiaCardDecoration;
