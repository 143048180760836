import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { get } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { rememberEmailService } from "../../../services";
import { Button } from "../../../components/button/Button";
import TopaasiaHexDecoration from "../../../components/decorations/topaasia-hex-decoration/TopaasiaHexDecoration";
import FeedbackInput from "./FeedbackInput";
import FullscreenPuffLoader from "../../../components/status/FullscreenPuffLoader";
import { actions as playerFeedbackActions } from "../../../modules/player-feedback";
import { actions as gameActions } from "../../../modules/game";
import { isValidEmailAddress } from "../../../modules/shared/email";
import LightContentContainer from "../../../components/layout/LightContentContainer";

import "./complete-view.scss";

const CompleteView = ({ playerFeedback, status, dispatch }) => {
  const { t } = useTranslation("views");
  const [emailAddress, setEmailAddress] = useState(rememberEmailService.get() || "");
  const [feedbackInputDirty, setFeedbackInputDirty] = useState(false);
  const [emailInputTouched, setEmailInputTouched] = useState(false);
  const [finishing, setFinishing] = useState(false);

  useEffect(() => {
    dispatch(playerFeedbackActions.get.request());
  }, []);

  const { loading } = status;

  if (loading && playerFeedback === null) {
    return <FullscreenPuffLoader />;
  }

  const onSaveAndQuitGame = () => {
    if (!finishing) {
      setFinishing(true);
      dispatch(gameActions.finish.request({ emailAddress }));
    }
  };

  const emailAddressValid = emailAddress === "" || isValidEmailAddress(emailAddress);

  return (
    <div role="main" className="complete-view">
      <Helmet>
        <html className="complete-view-html"></html>
      </Helmet>
      <div className="topaasia-decor-container">
        <TopaasiaHexDecoration />
      </div>
      <h1>{t("complete-view.title")}</h1>
      <LightContentContainer className="feedback-container">
        <FeedbackInput
          score={get(playerFeedback, "score", 0)}
          maxScore={get(playerFeedback, "maxScore", null)}
          text={get(playerFeedback, "text", "")}
          onDirty={(isDirty) => setFeedbackInputDirty(isDirty)}
          onChange={({ score, text }) => {
            dispatch(playerFeedbackActions.post.request({ score, text }));
          }}
        />
      </LightContentContainer>
      <LightContentContainer className="game-report-container">
        <h2>{t("complete-view.game-report.title")}</h2>
        <p>{t("complete-view.game-report.description")}</p>
        <div className="email-input-container">
          <input
            type={"email"}
            className={emailAddressValid ? "valid" : "invalid"}
            value={emailAddress}
            maxLength={127}
            onBlur={() => setEmailInputTouched(true)}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          {!emailAddressValid && emailInputTouched && (
            <div className="email-address-invalid-icon">
              <FontAwesomeIcon icon={faExclamationTriangle} color="orangered" />
            </div>
          )}
        </div>
      </LightContentContainer>
      <div className="actions">
        <Button
          disabled={finishing || feedbackInputDirty || loading || !emailAddressValid}
          onClick={() => onSaveAndQuitGame()}>
          {finishing ? t("complete-view.actions.saving") : t("complete-view.actions.save-and-quit")}
        </Button>
      </div>
    </div>
  );
};

CompleteView.propTypes = {
  playerFeedback: PropTypes.shape({
    score: PropTypes.number.isRequired,
    maxScore: PropTypes.number,
    text: PropTypes.string,
  }),
  status: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    posting: PropTypes.bool.isRequired,
  }).isRequired,
  finishing: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { playerFeedback, status } = state.playerFeedback;
  const { finishing } = state.game.status;
  return { playerFeedback, status, finishing };
};

export default compose(connect(mapStateToProps))(CompleteView);
