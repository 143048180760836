import { storeService } from ".";

const LOGIN_TOKEN_KEY = "login-token";
const PLAYER_ID_KEY = "player-id";

export const get = () => storeService.get(LOGIN_TOKEN_KEY);
export const remove = () => storeService.remove(LOGIN_TOKEN_KEY);
export const set = (value) => storeService.set(LOGIN_TOKEN_KEY, value);

export const playerId = {
  get: () => storeService.get(PLAYER_ID_KEY),
  remove: () => storeService.remove(PLAYER_ID_KEY),
  set: (value) => storeService.set(PLAYER_ID_KEY, value),
};

export const clearAllKeys = () => {
  storeService.remove(LOGIN_TOKEN_KEY);
  storeService.remove(PLAYER_ID_KEY);
};
