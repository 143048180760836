import React, { useState, useRef, useEffect } from "react";
import { debounce, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FeedbackRatingInput from "./FeedbackRatingInput";
import FeedbackTextInput from "./FeedbackTextInput";

const FeedbackInput = ({
  score,
  text,
  onDirty = () => {},
  onChange = () => {},
}) => {
  const { t } = useTranslation("views");
  const [inputScore, setInputScore] = useState(score);
  const [inputText, setInputText] = useState(text);

  const debouncedFeedbackChange = useRef(debounce((newScore, newText) => {
    const oldFeedback = { score, text };
    const newFeedback = { score: newScore, text: newText };

    if (!isEqual(oldFeedback, newFeedback)) {
      onChange({ score: newScore, text: newText });
    }
  }, 1000));

  useEffect(() => {
    onDirty(score !== inputScore || text !== inputText);
  }, [score, text]);

  useEffect(() => {
    onDirty(score !== inputScore || text !== inputText);
    debouncedFeedbackChange.current(inputScore, inputText);
  }, [inputScore, inputText]);

  return (
    <div className="feedback-input">
      <h2>{t("complete-view.feedback.title")}</h2>
      <p>{t("complete-view.feedback.description")}</p>
      <div className="feedback-rating-container">
        <FeedbackRatingInput
          score={inputScore}
          isDirty={inputScore !== score}
          onChange={(s) => setInputScore(s)} />
      </div>
      <div className="feedback-open-text-container">
        <FeedbackTextInput
          text={inputText}
          placeholder={t("complete-view.feedback.placeholder")}
          isDirty={inputText !== text}
          onChange={(newText) => setInputText(newText)} />
      </div>
    </div>
  );
};

FeedbackInput.propTypes = {
  score: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onDirty: PropTypes.func,
};

export default FeedbackInput;
