import React, { useEffect, useRef } from "react";

import Alligator from "./assets/images/player-avatars/alligator.svg";
import Bat from "./assets/images/player-avatars/bat.svg";
import Bear from "./assets/images/player-avatars/bear.svg";
import Butterfly from "./assets/images/player-avatars/butterfly.svg";
import Cat from "./assets/images/player-avatars/cat.svg";
import Dog from "./assets/images/player-avatars/dog.svg";
import Eagle from "./assets/images/player-avatars/eagle.svg";
import Elephant from "./assets/images/player-avatars/elephant.svg";
import Goose from "./assets/images/player-avatars/goose.svg";
import Hedgehog from "./assets/images/player-avatars/hedgehog.svg";
import Iguana from "./assets/images/player-avatars/iguana.svg";
import Lynx from "./assets/images/player-avatars/lynx.svg";
import Mouse from "./assets/images/player-avatars/mouse.svg";
import Ox from "./assets/images/player-avatars/ox.svg";
import Perch from "./assets/images/player-avatars/perch.svg";
import Rabbit from "./assets/images/player-avatars/rabbit.svg";
import Rhino from "./assets/images/player-avatars/rhino.svg";
import Rooster from "./assets/images/player-avatars/rooster.svg";
import Squirrel from "./assets/images/player-avatars/squirrel.svg";
import Unknown from "./assets/images/player-avatars/unknown.svg";
import Walrus from "./assets/images/player-avatars/walrus.svg";

export {
  Alligator,
  Bat,
  Bear,
  Butterfly,
  Cat,
  Dog,
  Eagle,
  Elephant,
  Goose,
  Hedgehog,
  Iguana,
  Lynx,
  Mouse,
  Ox,
  Perch,
  Rabbit,
  Rhino,
  Rooster,
  Squirrel,
  Walrus,
};

export const avatarNames = [
  "alligator",
  "bat",
  "bear",
  "butterfly",
  "cat",
  "dog",
  "eagle",
  "elephant",
  "goose",
  "hedgehog",
  "iguana",
  "lynx",
  "mouse",
  "ox",
  "perch",
  "rabbit",
  "rhino",
  "rooster",
  "squirrel",
  "walrus",
];

export const getAvatarForType = (type) => {
  switch (type) {
    case "butterfly": return <Butterfly />;
    case "cat": return <Cat />;
    case "dog": return <Dog />;
    case "eagle": return <Eagle />;
    case "goose": return <Goose />;
    case "hedgehog": return <Hedgehog />;
    case "rabbit": return <Rabbit />;
    case "rhino": return <Rhino />;
    case "rooster": return <Rooster />;
    case "squirrel": return <Squirrel />;
    case "alligator": return <Alligator />;
    case "bat": return <Bat />;
    case "bear": return <Bear />;
    case "elephant": return <Elephant />;
    case "iguana": return <Iguana />;
    case "lynx": return <Lynx />;
    case "mouse": return <Mouse />;
    case "ox": return <Ox />;
    case "perch": return <Perch />;
    case "walrus": return <Walrus />;
    default: {
      return <Unknown />;
    }
  };
};

export const ColorizedAvatar = ({ avatarComponent, fillColor = "#fff" }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = svgRef.current.getElementsByTagName("svg")[0];

    const paths = svg.getElementsByTagName("path");

    svg.setAttribute("style", `fill: ${fillColor}`);
    Array.from(paths).forEach((path) => path.setAttribute("style", null));
  }, [svgRef])

  return (
    <div ref={svgRef}>
      { avatarComponent }
    </div>
  );
};

export const getColoredAvatarForType = (type, fillColor = "#fff") => (
  <ColorizedAvatar avatarComponent={getAvatarForType(type)} fillColor={fillColor} />
);
